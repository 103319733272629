//Library Imports 
import React, {useState} from "react";
import { NavLink, useNavigate, useLocation  } from 'react-router-dom';
import { Helmet } from "react-helmet";
import useStore from './store';
import { shallow } from 'zustand/shallow';

//CSS and Images
import "../SCSS_Files/NavigationBarStyle.scss";
import logo from '../Icons/GeneralIcons/SenstrioAILogo.png';
import profile from '../Images/profile_sample.png';


// Navigation Bar to allow for profile opening as well as navigation 
const NavBar=()=> {
    
    const {isProfileActive, setProfileActive} = useStore(state => ({ 
        isProfileActive: state.isProfileActive, 
        setProfileActive: state.setProfileActive,
    }), shallow);

    const navigate = useNavigate();

    let profileClassCheck = isProfileActive ? 'active': '';

    const profileToggle = () => {
        setProfileActive(!isProfileActive);
    };

    const [navActive, setNavActive] = useState(false)
    
    // Logout of session 
    const logout = () => {
        sessionStorage.clear();
        navigate('/');
        profileToggle();
        
    };

    // Restriction of Nav and Header to certain links
    const { pathname } = useLocation();
    if (pathname === "/" || pathname == "/modelbuilder" || pathname == '/resetpassword') return null;
    
    return(
        <>
            <Helmet>
                <title>Page</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <meta http-equiv='cache-control' content='no-cache' />
=                <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet' />
                <script src='../nav_tools.js' type='text/babel'></script>
            </Helmet>

            {/* Navigational Bar */}
            <nav className={navActive?'navbar active':'navbar'} >
                <i className='bx bx-dots-vertical-rounded' onClick={() => setNavActive(!navActive)}></i>
                <img src={logo} alt="Logo" className='logo-img'/>
                <div className="nav-link-container">
                    <ul className="navbar-list">
                        {/* Link for AI Model Builder */}
                        {/* <li className="nav-group" id='first-group'>AI Services</li> */}
                        {/* <li className="navbar-listitem">
                            <NavLink className="nav-link" to='/models'>
                                <i className='bx bxs-network-chart link-icon'></i>
                                <span className="link-text">Models</span>
                            </NavLink>
                            <div className="active-icon"></div>
                        </li> */}
                        {/* Link for Model Testing */}
                        {/* <li className="navbar-listitem" onClick={() => setNavActive(false)}>
                            <NavLink className="nav-link" to='/modeltest'>
                                <i className='bx bx-line-chart link-icon'></i>
                                <span className="link-text">Model Testing</span>
                            </NavLink>
                        </li> */}
                        {/* Link for Model Deployment */}
                        {/* <li className="navbar-listitem">
                            <NavLink className="nav-link" to='/'>
                                <i className='bx bx-check-circle link-icon'></i>
                                <span className="link-text">Model Deployment</span>
                            </NavLink>
                            <div className="active-icon"></div>
                        </li> */}
                        <li className="nav-group">Data Management</li>
                        {/* Link for Chemical Encyclopedia Page */}
                        <li className="navbar-listitem" onClick={() => setNavActive(false)}>
                            <NavLink className="nav-link" to='/encyclopedia'>
                                <i className='bx bx-food-menu link-icon'></i>
                                <span className="link-text">Encyclopedia</span>
                            </NavLink>
                        </li>
                        {/* Link for Analytics Page */}
                        <li className="navbar-listitem" onClick={() => setNavActive(false)}>
                            <NavLink className="nav-link" to='/archivesummary'>
                                <i className='bx bx-analyse link-icon'></i>
                                <span className="link-text">Material Analysis</span>
                            </NavLink>
                        </li>
                        {/* Link for Download Page */}
                        <li className="navbar-listitem" onClick={() => setNavActive(false)}>
                            <NavLink className="nav-link" to='/download'>
                                <i className='bx bxs-cloud-download link-icon'></i>
                                <span className="link-text">File Exporter</span>
                            </NavLink>
                        </li>
                        
                        <li className="nav-group">User Management</li>
                        <li className="navbar-listitem" onClick={() => setNavActive(false)}>
                            <NavLink className="nav-link" to='/profileviewer'>
                                <i className='bx bx-user-circle link-icon' ></i>
                                <span className="link-text">Profile</span>
                            </NavLink>
                        </li>
                        {
                            ['admin', 'superadmin'].includes(sessionStorage.getItem('role')) &&
                            <li className="navbar-listitem" onClick={() => setNavActive(false)}>
                                <NavLink className="nav-link" to='/groupmanager'>
                                    <i className='bx bxs-group link-icon'></i>
                                    <span className="link-text">Manage Team</span>
                                </NavLink>
                            </li>
                        }
                        <li className="navbar-listitem" onClick={logout}>
                            <NavLink className="nav-link"  to='' >
                                <i className='bx bx-log-out-circle link-icon'></i>
                                <span className="link-text">Logout</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
                    <div className="profile" onClick={profileToggle} >
                    {sessionStorage.getItem('userImage')?<img src={sessionStorage.getItem('userImage')} alt="Profile Pic" className='profile-pic' onClick={profileToggle}/>:<img src={profile} alt="Profile Pic" className='profile-pic' onClick={profileToggle}/>}
                    <div className="information">
                        <div className="user-name">{sessionStorage.getItem('userName').charAt(0).toUpperCase() + sessionStorage.getItem('userName').slice(1)}</div>
                        <div className="email">{sessionStorage.getItem('email')}</div>
                    </div>
                </div>
            </nav>
        </>
        
    );
};

export default NavBar;

