//Library Imports
import React from 'react'
import useStore from "../store.js";
import {shallow} from 'zustand/shallow';

//Component Imports 
import { fileSize } from '../GeneralFunctions.js';
import { deviceIdReference } from './UploadPopup.js';

//CSS and Image Imports
import uploadImg from '../../Icons/UploadIcons/upload-icon-blue.png'
import csvIcon from '../../Icons/UploadIcons/csv-icon.png'
import xlsxIcon from '../../Icons/UploadIcons/xlsx-icon.png'
import txtIcon from '../../Icons/UploadIcons/txt-icon.png'
import othersIcon from '../../Icons/UploadIcons/others-icon.png'


//File box meant for file dropping
/* 
    Should allow multifile dropping
    Should store file details, file and append a file element to display
    Parameters-----
    props.devices ----> list of unique devices that the system should detect (array)
*/
let id = 0;
const getId = () => `file_${id++}`;
const FileBox = (props) => {

    const { dataFiles, setDataFiles ,setDataSubmissionFiles, setNotification, setDetectedDevices } = useStore(state => ({ 
        dataFiles: state.dataFiles, 
        setDataFiles: state.setDataFiles,
        setDataSubmissionFiles: state.setDataSubmissionFiles, 
        setNotification: state.setNotification, 
        setDetectedDevices: state.setDetectedDevices
    }), shallow);

    
    // Detect Device using synonyms
    /* 
        Parameters-----
        deviceList --> Object of Lists (Used to store list of synonyms)
        stringList --> Lists(Contains lines of information to check for synonyms)
    */
    const detectDevice = (deviceList, stringList) => {
        const deviceKeys = Object.keys(deviceList)

        for (let deviceIndex = 0; deviceIndex < deviceKeys.length; deviceIndex++) {
            for (let lineIndex = 0; lineIndex < stringList.length; lineIndex++) {
                for (let synonymIndex = 0; synonymIndex < deviceKeys[deviceIndex].length; synonymIndex++) {
                    if (stringList[lineIndex].includes(deviceList[deviceKeys[deviceIndex]][synonymIndex])) {
                        return deviceKeys[deviceIndex]
                    }
                }
            }
        }
        return 'undetected'
    }

    //File Upload Event
    /*
        Parameters-----
        event ----> event (onChange Event)
        props.devices ----> list of unique devices that the system should detect (array)
    */
    const uploadEvent = (event) => {
        const files = event.target.files

        //Used to store new components for <File/>
        let newAddedFiles = []
        
        //Map Each file that has been inputted
        Object.keys(files).map((fileObject) => {
            const fileId = getId()
            let currentFile = files[fileObject]

            //File Reader used for Analysing File for words related to specific device
            let reader = new FileReader();
            reader.onload = async (e) => { 

                //Slice and Trigger detectDevice function
                const text = reader.result
                const fileContent = text.split('\n').slice(0,9)
                const detectedDevice = detectDevice(props.devices,fileContent)

                if (detectedDevice == 'undetected') {
                    setDataFiles(dataFiles.filter(element => element.key !== fileId));
                    setNotification('Invalid File', 'Please only insert files that are supported by Anor Technologies','info')
                    return
                }
                    
                //Set Files for submission
                setDataSubmissionFiles(fileId, currentFile, detectedDevice)
                setDetectedDevices(fileId, String(deviceIdReference[detectedDevice]))

                // if (!detectedDevices.includes(String(deviceIdReference[detectedDevice]))) {
                //     setDetectedDevices(String(deviceIdReference[detectedDevice]))
                // }
                

            };
            reader.readAsText(currentFile)

            //Push Component per file to newAddedFiles
            newAddedFiles.push(<File 
                key={fileId} 
                fileName={currentFile.name} 
                fileSize={fileSize(currentFile.size)} 
                fileType={currentFile.type} 
                deleteKey={fileId}
               
            />)
        })          
        
        setDataFiles([...newAddedFiles, ...dataFiles])
        event.target.value = null;
    }    

    return (
        <div className='file-box-container'>
            <div className='file-box'>
                <img className='upload-icon' src={uploadImg} alt="Upload Logo" />
                <div className='file-box-subtitle'>
                    Drop files to upload or
                </div>
                {/* Browse button covers original input for design */}
                <input className="upload-button" type='file' multiple onChange={uploadEvent}/>
                <span className="file-box-trigger" >Browse</span>
            </div>
        </div>
    );
};


//File meant to hold uploaded details
/*
    Should allow users to view basic file details like name, size and file type
    Should allow users to view multiple files
    Should cater for unidentified files and display error
    Should allow for file deletion
    Parameters-----
    filename --> string
    filesize --> string
    filetype --> string 
*/ 
const File = (props) => {
    
    const fileIcons = {
        "text/csv": csvIcon,
        "text/xlsx": xlsxIcon,
        "text/plain": txtIcon,
    }

    const { dataFiles, setDataFiles, filterDataSubmissionFiles, filterDetectedDevices } = useStore(state => ({ 
        dataFiles: state.dataFiles, 
        setDataFiles: state.setDataFiles,
        filterDataSubmissionFiles: state.filterDataSubmissionFiles,
        filterDetectedDevices: state.filterDetectedDevices
    }), shallow);

    //Data file component delete function
    /*
        Parameters-----
        selectedKey --> string
    */
    const deleteDataFile = (selectedKey) => {
        setDataFiles(dataFiles.filter(element => element.key !== selectedKey));
        filterDataSubmissionFiles(selectedKey)
        filterDetectedDevices(selectedKey)
    };

    return (
        <div className="file">
            <div className="file-icon"><img src={props.fileType in fileIcons? (fileIcons[props.fileType]):(othersIcon)}/></div>
            <div className="file-name">{props.fileName}</div>
            <div className='file-bin-and-size'>
                <div className="file-size">{props.fileSize}</div>
                <div className="file-bin"><i className='bx bxs-trash' onClick={() => deleteDataFile(props.deleteKey)}></i></div>
            </div>
        </div>
    )
}


//FileHolder Wrapper to hold and display all data Files
/* 
    dataFiles: Array of components (to display 1 by 1)
*/
const FileHolder = () => {

    const { dataFiles } = useStore(state => ({ 
        dataFiles: state.dataFiles
    }), shallow);

    return (
        <div className="file-holder">
            {dataFiles.length?(
                dataFiles
            ):(
                <div className="center-message">
                    <div className="center-message-title">No Data Files Detected</div>
                    <div className="center-message-subtitle">Please upload 1 or more before proceeding</div>
                </div>
            )}
        </div>
    );
};


//Main slide for all data file collection
/* 
    Should catch and display errors such as file type

    -----File Box-----
    Should allow multifile dropping
    Should store file details, file and append a file element to display

    -----File-----
    Should allow users to view basic file details like name, size and file type
    Should allow users to view multiple files
    Should cater for unidentified files and display error
    Should allow for file deletion
    
    Parameters------
    props.stepCount ----> Integer to identify carousel step 
    props.header ----> String to display what this step does
    props.devices ----> list of unique devices that the system should detect (array)
*/
const DataFileSlide= (props) => {

    const { 
        setDataFiles, 
        clearDataSubmissionFiles, 
        clearOrganisedSubmissionFiles,
        clearReferenceSubmissionFiles,
        clearGlobalSubmissionForms,
        clearDetectedDevices
    } = useStore(state => ({ 
        setDataFiles: state.setDataFiles,
        clearDataSubmissionFiles: state.clearDataSubmissionFiles,
        clearOrganisedSubmissionFiles: state.clearOrganisedSubmissionFiles,
        clearReferenceSubmissionFiles: state.clearReferenceSubmissionFiles,
        clearGlobalSubmissionForms: state.clearGlobalSubmissionForms,
        clearDetectedDevices: state.clearDetectedDevices
    }), shallow);

    const deleteAllFiles = () => {
        setDataFiles([])
        clearDataSubmissionFiles()
        clearOrganisedSubmissionFiles()
        clearReferenceSubmissionFiles()
        clearGlobalSubmissionForms()
        clearDetectedDevices()
    }

    return (
        <>
            {/* File uploading */}
            <div className='data-file-slide'>
                <div className="slide-header">
                    <div className="slide-title"><span className="step-count">{props.stepCount}.</span> {props.header}</div>
                    <div className="slide-subtitle">Upload the files you want to be processed and stored</div>
                </div>
                <FileBox devices={props.devices}/>
            </div>
            {/* File display  */}
            <div className="data-files-collection">
                <div className="slide-subtitle">Files Accepted are .csv, .xlxs and origin files <button className='clear-all-button' onClick={deleteAllFiles}>Clear All</button></div>
                <FileHolder/>
            </div>
        </>
    );
};

export {FileBox, FileHolder, File, DataFileSlide, fileSize};