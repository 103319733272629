//Library Imports
import React, { useEffect, useState } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import useStore from "../store";
import { shallow } from "zustand/shallow";

//Component Imports
import { getArchiveFilters, filterDataArchive, reprocess } from '../AxiosList';
import { CheckboxDropdown } from '../EnclopediaComponents/EncyclopediaPage';
import { Upload } from '../UploadAndProcessComponents/UploadPopup';
import { stringBoolExtractor } from '../GeneralFunctions';
import PaginationBox from '../Pagination';
import { SwitchButton } from '../SwitchButton';

//CSS and Images
import '../../SCSS_Files/DataArchiveStyle.scss';
import drug from '../../Icons/DataArchiveIcons/drugs.png';
import explosive from '../../Icons/DataArchiveIcons/explosive.png';
import plastic from '../../Icons/DataArchiveIcons/plastic.png';
import defaultProfile from '../../Images/profile_sample.png'

//Filter bar meant for filtering Archive Entries.
/*
    Parameters ------
    setArchiveData --> State to set Data Archive
    setPageableResultsDTO --> State to manage pagination
    pageableResultsDTO --> pagination object state  
    batchSize --> Size of Items per Page
    setSorterType --> State to set the current sorter 
    sorterType --> Sort Object to manage sorting type
    setNotification --> Notification state management
    showActive --> State to display active and inactive results
    setShowActive --> State management for setting activeness
    setDataMetrics --> State management for data metrics
    setFilterChanged --> State to manage filter changing detection
    setFilterActive --> Manage Activeness of advanced filter bar
    filterActive ---> Activeness of filter bar
    filters --> Current Filters applied
    setFilters --> State to set the record of existing filters
*/
const Filter = (props) => {

    // State management for filter bar state 
    const [archiveFilters, setArchiveFilters] = useState({})
    const [archiveSorters, setArchiveSorters] = useState()

    // State to handle categories, subtypes and properties group checking
    const [dataNames, setDataNames] = useState([])
    const [dataPackaging, setDataPackaging] = useState([])
    const [dataProviders, setDataProviders] = useState([])
    const [dataMask, setDataMask] = useState([])
    const [dataForm, setDataForm] = useState([])
    const [dataMaterial, setDataMaterial] = useState([])
    const [dataProperties, setDataProperties] = useState([])
    const [dataProcessingStatus, setDataProcessingStatus] = useState([])

    // Resets the entire filter bar and relevant states 
    // const resetFilter = () => {
    //     setDataNames(new Array(archiveFilters.dataNames.length).fill(false))
    //     setDataPackaging(new Array(archiveFilters.dataPackaging.length).fill(false))
    //     setDataProviders(new Array(archiveFilters.dataProviders.length).fill(false))
    //     setDataMask(new Array(archiveFilters.dataMask.length).fill(false))
    //     setDataForm(new Array(archiveFilters.dataForm.length).fill(false))
    //     setDataMaterial(new Array(archiveFilters.dataMaterial.length).fill(false))
    //     setDataProperties(new Array(archiveFilters.dataProperties.length).fill(false))
    //     setDataProcessingStatus(new Array(archiveFilters.dataProcessingStatus.length).fill(false))
    // }

    // Submit filter values and render in changes 
    const submitFilter = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target)

        // Compile Filter Object
        const filterObject = {
            'dataFileName': formData.get('fileName'),
            "dataNames": stringBoolExtractor(archiveFilters.dataNames, dataNames).map((a) => a.drugId),
            "dataPackaging": stringBoolExtractor(archiveFilters.dataPackaging, dataPackaging),
            "dataProvider": stringBoolExtractor(archiveFilters.dataProviders, dataProviders).map((a) => a.userId),
            "dataMask": stringBoolExtractor(archiveFilters.dataMask, dataMask),
            "dataForm": stringBoolExtractor(archiveFilters.dataForm, dataForm),
            "dataMaterial": stringBoolExtractor(archiveFilters.dataMaterial, dataMaterial).map((a) => a.categoryId),
            "dataProperties": stringBoolExtractor(archiveFilters.dataProperties, dataProperties).map((a) => a.attributeId),
            "dataProcessingStatus": stringBoolExtractor(archiveFilters.dataProcessingStatus, dataProcessingStatus),
        }

        // Filter API
        // Send Request to Filter Archive
        filterDataArchive(
            props.setArchiveData,
            props.setPageableResultsDTO,
            props.pageableResultsDTO.existingPage,
            props.batchSize,
            props.sorterType.sortField,
            props.sorterType.direction,
            props.setNotification,
            sessionStorage.getItem('userId'),
            filterObject,
            props.showActive,
            props.setDataMetrics,
        )

        // Declare that the filter object has changed as well as change the new filters
        props.setFilters(filterObject)
        props.setFilterChanged(true)

    }

    // Encyclopedia filter information retrieval 
    useEffect(() => {
        getArchiveFilters(setArchiveFilters, sessionStorage.getItem('userId'), setArchiveSorters)
    }, [])


    // When the filters are retrieved from DB, render them to the declared state for multi search checkboxes
    useEffect(() => {
        if (Object.keys(archiveFilters).length !== 0) {

            setDataNames(new Array(archiveFilters.dataNames.length).fill(false))
            setDataPackaging(new Array(archiveFilters.dataPackaging.length).fill(false))
            setDataProviders(new Array(archiveFilters.dataProviders.length).fill(false))
            setDataMask(new Array(archiveFilters.dataMask.length).fill(false))
            setDataForm(new Array(archiveFilters.dataForm.length).fill(false))
            setDataMaterial(new Array(archiveFilters.dataMaterial.length).fill(false))
            setDataProperties(new Array(archiveFilters.dataProperties.length).fill(false))
            setDataProcessingStatus(new Array(archiveFilters.dataProcessingStatus.length).fill(false))
        }

    }, [archiveFilters])


    return (
        <div className='filter-wrapper'>
            {/* Enclosed in a form for easier submission */}
            <form onSubmit={submitFilter}>
                <div className="input-boxes">

                    {/* Search Bar, sort and inactiveness/activness toggle feature */}
                    <div className="search">
                        <button className="filter-button" type='submit'>
                            <i className='bx bx-search'></i>
                        </button>
                        <input type="text" placeholder='Search File Name...' className='name-filter' name='fileName' />
                    </div>
                    <div className="sort">
                        <div className="sort-label">
                            Sort By:
                        </div>
                        <select onChange={(e) => {
                            props.setSorterType({ ...props.sorterType, [`${'sortField'}`]: e.target.value })
                            filterDataArchive(
                                props.setArchiveData,
                                props.setPageableResultsDTO,
                                props.pageableResultsDTO.existingPage,
                                props.batchSize,
                                e.target.value,
                                props.sorterType.direction,
                                props.setNotification,
                                sessionStorage.getItem('userId'),
                                props.filters,
                                props.showActive,
                                props.setDataMetrics
                            )

                        }}>
                            {
                                archiveSorters && archiveSorters.map((sorter) => {
                                    return (
                                        <option value={sorter} key={sorter}>
                                            {sorter.split('_').map((a) => a.charAt(0).toUpperCase() + a.slice(1)).join(' ')}
                                        </option>

                                    )
                                })
                            }
                        </select>
                        <div className="order">
                            <i className={props.sorterType['direction'] == 'ascending' ? 'bx bxs-up-arrow active' : 'bx bxs-up-arrow'} onClick={() => {
                                props.setSorterType({ ...props.sorterType, [`${'direction'}`]: 'ascending' })
                                filterDataArchive(
                                    props.setArchiveData,
                                    props.setPageableResultsDTO,
                                    props.pageableResultsDTO.existingPage,
                                    props.batchSize,
                                    props.sorterType.sortField,
                                    'ascending',
                                    props.setNotification,
                                    sessionStorage.getItem('userId'),
                                    props.filters,
                                    props.showActive,
                                    props.setDataMetrics
                                )
                            }}></i>
                            <i className={props.sorterType['direction'] == 'descending' ? 'bx bxs-down-arrow active' : 'bx bxs-down-arrow'} onClick={() => {
                                props.setSorterType({ ...props.sorterType, [`${'direction'}`]: 'descending' })
                                filterDataArchive(
                                    props.setArchiveData,
                                    props.setPageableResultsDTO,
                                    props.pageableResultsDTO.existingPage,
                                    props.batchSize,
                                    props.sorterType.sortField,
                                    'descending',
                                    props.setNotification,
                                    sessionStorage.getItem('userId'),
                                    props.filters,
                                    props.showActive,
                                    props.setDataMetrics
                                )
                            }}></i>
                        </div>
                    </div>
                    {/* Only show for admins */}
                    {
                        ['admin', 'superadmin'].includes(sessionStorage.getItem('role')) &&
                        <div className="show-active">
                            <SwitchButton defaultValue="INACTIVE" label='Show Inactive' onChangeEvent={() => {
                                props.setShowActive(!props.showActive)
                                filterDataArchive(
                                    props.setArchiveData,
                                    props.setPageableResultsDTO,
                                    0,
                                    props.batchSize,
                                    props.sorterType.sortField,
                                    props.sorterType.direction,
                                    props.setNotification,
                                    sessionStorage.getItem('userId'),
                                    props.filters,
                                    !props.showActive,
                                    props.setDataMetrics
                                )
                                props.setActivenessChanged(true)
                            }} />
                        </div>
                    }

                </div>

                {/* Advanced filters for drop down checkboxes for each filter type*/}
                <div className={props.filterActive ? ("advanced-filters active") : ("advanced-filters")}>
                    <div className="opener" onClick={() => props.setFilterActive(!props.filterActive)}>
                        <div className="closed-label">Advanced Filters</div>
                        <i className='bx bxs-down-arrow' ></i>
                    </div>
                    <div className="advanced-filters-container">

                        <div className='flex-wrapper'>
                            <div className="filter-section  checkbox-filter">
                                <CheckboxDropdown options={archiveFilters.dataNames} title="Chemical Name" stateList={dataNames} setStateList={setDataNames} />
                            </div>
                            <div className="filter-section  checkbox-filter">
                                <CheckboxDropdown options={archiveFilters.dataMask} title="Mask Type" stateList={dataMask} setStateList={setDataMask} />
                            </div>
                            <div className="filter-section  checkbox-filter">
                                <CheckboxDropdown options={archiveFilters.dataMaterial} title="Material Type" stateList={dataMaterial} setStateList={setDataMaterial} />
                            </div>

                            <div className="filter-section  checkbox-filter">
                                <CheckboxDropdown options={archiveFilters.dataForm} title="Chemical Form" stateList={dataForm} setStateList={setDataForm} />
                            </div>

                            <div className="filter-section  checkbox-filter">
                                <CheckboxDropdown options={archiveFilters.dataPackaging} title="Package Type" stateList={dataPackaging} setStateList={setDataPackaging} />
                            </div>
                            <div className="filter-section  checkbox-filter">
                                <CheckboxDropdown options={archiveFilters.dataProcessingStatus} title="Processing Status" stateList={dataProcessingStatus} setStateList={setDataProcessingStatus} />
                            </div>
                            <div className="filter-section  checkbox-filter">
                                <CheckboxDropdown options={archiveFilters.dataProperties} title="Data Properties" stateList={dataProperties} setStateList={setDataProperties} />
                            </div>
                            <div className="filter-section  checkbox-filter">
                                <CheckboxDropdown options={archiveFilters.dataProviders} title="Providers" stateList={dataProviders} setStateList={setDataProviders} />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    )
}


// Analytical entry to display as rows for users to select
/*
    Parameters --------
    props.entryDetails ---> Contains all info for that entry
*/
const AnalyticalEntry = (props) => {

    const navigate = useNavigate()

    // Chemical Icons for different data
    const chemicalIcon = {
        'Drugs': <img className='chemical-icon' src={drug} alt='Drugs' />,
        'Explosives': <img className='chemical-icon' src={explosive} alt='Explosives' />,
        'Plastics': <img className='chemical-icon' src={plastic} alt='Plastics' />
    }

    return (
        <tr className={props.entryDetails.processStatus === 'PROCESSED'? 'archive-entry':'archive-entry inactive'} onClick={() => {
            if (props.entryDetails.processStatus === 'PROCESSING'){
                props.setNotification('Incomplete Processing', 'Entry has not completed processing', 'info')
                return
            }
            if (props.entryDetails.processStatus === 'FAILED'){
                reprocess(
                    props.entryDetails.entrySetId, 
                    sessionStorage.getItem('userId'),
                    props.setNotification, 
                    () => {
                        props.filterDataArchive()
                    }
                )
                return
            }
            sessionStorage.setItem('udeIndex', props.entryDetails.entrySetId)
            navigate('/UDE')
        }}>
            <td className='column column1'>
                {props.entryDetails.uploadedBy.imageUrl ? <img className='user-image' src={`${process.env.REACT_APP_AWS_BUCKET_URL}${props.entryDetails.uploadedBy.imageUrl}`} alt="" /> : <img className='user-image' src={defaultProfile} />}
                <div className="user-name">{props.entryDetails.uploadedBy.userName}</div>
            </td>
            <td className='column column2'>
                <h3>{props.entryDetails.fileName}</h3>
                <div>{props.entryDetails.experimentEntryCount} entries of {props.entryDetails.drugName} <span>({props.entryDetails.shape})</span></div>
            </td>
            <td className='column column3'>
                <div className="icons">{chemicalIcon[props.entryDetails.materialCategoryName]}</div>
                <div className="category">{props.entryDetails.materialCategoryName}</div>
            </td>
            <td className='column column4'>
                <p>{props.entryDetails.testDate}</p>
                <p>{props.entryDetails.uploadedBy.userName}</p>
            </td>
            <td className='column column5'>
                <p>Mask Size: {props.entryDetails.mask}</p>
                <p>{props.entryDetails.filePackage} Packaged</p>
            </td>
            <td className='column column6'>
                <div className={`chemical-status ${props.entryDetails.processStatus}`}                 
                >{props.entryDetails.processStatus}</div>
            </td>
        </tr>
    )
}


// Main Archive Page to display all chemical data from the database
/* 
    Should allow users to see all chemical data collected and processed 
    Should allow users to open the chemical entry for their data 
    Should allow users to filter the data they would like to see  
*/
const AnalyticsArchive = () => {

    //Page Component Management
    const [filterActive, setFilterActive] = useState(false)
    const [uploadPopup, setUploadPopup] = useState(false)
    const navigate = useNavigate();

    // Archive Data Management
    const [archiveData, setArchiveData] = useState([]);
    const [pageableResultsDTO, setPageableResultsDTO] = useState({})
    const [dataMetrics, setDataMetrics] = useState({})

    // Filter Bar State management --------------------------------------------------------------------
    // Sorter state management
    const [sorterType, setSorterType] = useState({ sortField: 'created date', direction: 'descending' })

    //Show Active
    const [showActive, setShowActive] = useState(false)

    //Archive Filter Object
    const [filters, setFilters] = useState({
        "dataFileName": "",
        "dataNames": [],
        "dataPackaging": [],
        "dataProvider": [],
        "dataMask": [],
        "dataForm": [],
        "dataMaterial": [],
        "dataProperties": [],
        "dataProcessingStatus": [],
    })

    // Filter Change detection
    const [filterChanged, setFilterChanged] = useState(false)
    const [activenessChanged, setActivenessChanged] = useState(false)

    // Pagingation batch size 
    const pageOptions = [10, 15, 20, 30]
    const [batchSize, setBatchSize] = useState(pageOptions[0])


    //Access Store for Notification and Loading Page
    const { setNotification, setLoadingActiveness } = useStore(
        (state) => ({
            setNotification: state.setNotification,
            setLoadingActiveness: state.setLoadingActiveness
        }),
        shallow
    );


    // Upon Page load get filters of available as well as data entries
    useEffect(() => {
        if (sessionStorage.getItem('userId') == 'undefined') {
            navigate('/');
        }

        // Open Loading Page
        setLoadingActiveness(true)

        filterDataArchive(
            setArchiveData,
            setPageableResultsDTO,
            0,
            batchSize,
            sorterType.sortField,
            sorterType.direction,
            setNotification,
            sessionStorage.getItem('userId'),
            filters,
            showActive,
            setDataMetrics
        )

    }, []);

    // Loading Page Toggle
    useEffect(() => {
        setTimeout(() => {
            setLoadingActiveness(false)
        }, 1000)

    }, [archiveData])

    return (
        <>
            <main className={'main-content data-archive'}>
                <div className='material-archive-header'>
                    <div className="title">Data Entry Archive</div>
                    <div className="subtitle">Upload new spectroscopic data to process, as well as view the results for analysis and inference</div>
                </div>

                {/* Filter Bar */}
                <section className="filter-section">
                    <Filter
                        filterActive={filterActive}
                        setFilterActive={setFilterActive}

                        archiveData={archiveData}
                        setArchiveData={setArchiveData}

                        pageableResultsDTO={pageableResultsDTO}
                        setPageableResultsDTO={setPageableResultsDTO}

                        batchSize={batchSize}

                        setSorterType={setSorterType}
                        sorterType={sorterType}

                        setNotification={setNotification}

                        filters={filters}
                        setFilters={setFilters}

                        setFilterChanged={setFilterChanged}

                        setDataMetrics={setDataMetrics}

                        showActive={showActive}
                        setShowActive={setShowActive}

                        setActivenessChanged = {setActivenessChanged}

                    />

                </section>

                {/* Metric Display */}
                <section className='information-section'>
                    <div className="information-block">
                        <div className="title">Total Entries</div>
                        <div className="value">{dataMetrics && dataMetrics.metrics && dataMetrics.metrics[0].metricName &&  dataMetrics.totalEntries}</div>
                    </div>
                    {
                        dataMetrics && dataMetrics.metrics && dataMetrics.metrics.map((metric) => {
                            return (
                                <div className="information-block" key={metric.metricName}>
                                    <div className="title">{metric.metricName}</div>
                                    <div className="value">{metric.metricCount}</div>
                                </div>
                            )
                        })
                    }
                </section>

                {/* Archive Display */}
                <section className='display-section'>
                    <section className='data-entry-section archive-section'>
                        <div className="data-entry-group">
                            <div className="group-header">
                                <div className="title">Active Items</div>
                                <button className="upload-button" onClick={() => setUploadPopup(true)}>Upload</button>
                            </div>
                            <div className="scroll-prompt">
                                <span>Scroll Here For More Info</span>
                                <i className='bx bx-right-arrow-alt'></i>
                            </div>
                            <div className="table-wrapper">

                                <table className='data-table'>
                                    <thead>
                                        <tr className="list-headers">
                                            <th className="list-header header1">User</th>
                                            <th className="list-header header2">File Information</th>
                                            <th className="list-header header3">Category</th>
                                            <th className="list-header header4">Collection</th>
                                            <th className="list-header header5">Experiment</th>
                                            <th className="list-header header6">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            archiveData && archiveData.map((entry, key) => {
                                                return (
                                                    <AnalyticalEntry key={key} entryDetails={entry} setNotification={setNotification} filterDataArchive={() => {
                                                        filterDataArchive(
                                                            setArchiveData,
                                                            setPageableResultsDTO,
                                                            0,
                                                            batchSize,
                                                            sorterType.sortField,
                                                            sorterType.direction,
                                                            setNotification,
                                                            sessionStorage.getItem('userId'),
                                                            filters,
                                                            showActive,
                                                            setDataMetrics
                                                        )
                                                    }}/>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </section>

                    {/* Reusable Pagination Component */}
                    <section className='pagination-section archive-section'>
                        {pageableResultsDTO && pageableResultsDTO.totalPages > 0 && <PaginationBox
                            retrievalFunction={filterDataArchive}
                            setStateList={setArchiveData}
                            setPageableResultsDTO={setPageableResultsDTO}
                            pageableResultsDTO={pageableResultsDTO}
                            sorterType={sorterType}
                            batchSize={batchSize}
                            setNotification={setNotification}
                            maxPageBoxes={5}
                            filters={filters}
                            setBatchSize={setBatchSize}
                            pageOptions={pageOptions}
                            filterChanged={filterChanged}
                            setFilterChanged={setFilterChanged}
                            showActive={showActive}
                            setDataMetrics={setDataMetrics}
                            activenessChanged ={activenessChanged}
                            setActivenessChanged ={setActivenessChanged}
                        />}

                    </section>
                </section>
            </main>

            {/* Upload Page */}
            <Upload 
                uploadPopup={uploadPopup} 
                setUploadPopup={setUploadPopup} 
                setArchiveData={setArchiveData} 
                filterDataArchive = { () =>
                    filterDataArchive(
                        setArchiveData,
                        setPageableResultsDTO,
                        0,
                        batchSize,
                        sorterType.sortField,
                        sorterType.direction,
                        setNotification,
                        sessionStorage.getItem('userId'),
                        filters,
                        showActive,
                        setDataMetrics
                    )
                }
            />
        </>
    )
};

export default AnalyticsArchive;


