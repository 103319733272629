//Library Imports
import React from "react";
import { useState, useRef } from "react";

//CSS and Images Imports
import '../../Deprecated_CSS/CSS_Files/ModelSelectPopupStyle.css';

const DragAndDrop = ({list, setList}) => {
    const [dragging, setDragging] = useState(false);

    const dragItem = useRef();
    const dragNode = useRef();

    let newList = "";

    const handleDragStart = (e, params) => {
        dragItem.current = params;
        dragNode.current = e.target;
        dragNode.current.addEventListener('dragend', handleDragEnd);
        setTimeout(() => {
            setDragging(true);
        }, 0);
    };

    const handleDragEnter = (e, params) => {
        const currentItem = dragItem.current;
        if (e.target !== dragNode.current) {
            setList(oldList => {
                newList = JSON.parse(JSON.stringify(oldList));
                newList[params.grpI].items.splice(params.itemI, 0, newList[currentItem.grpI].items.splice(currentItem.itemI, 1)[0]);
                dragItem.current = params;
                return newList;
            })

        }
    }


    const handleDragEnd = () => {
        setDragging(false);
        dragNode.current.removeEventListener('dragend', handleDragEnd);
        dragItem.current = null;
        dragNode.current = null;
    }

    const getStyles = (params) => {
        const currentItem = dragItem.current;
        if (currentItem.grpI === params.grpI && currentItem.itemI === params.itemI) {
            return 'current dnd-item';
        };
        return 'dnd-item';
    };

    return (
        <div className="drag-n-drop">
            
            {list.map((grp, grpI) => (

                <div
                    key={grp.title}
                    onDragEnter={dragging && !grp.items.length ? (e) => handleDragEnter(e, { grpI, itemI: 0 }) : null}
                    className="dnd-group"
                >

                    <div className='dnd-group-items'>
                        {grp.items.map((item, itemI) => (
                            <div
                                draggable
                                onDragStart={(e) => { handleDragStart(e, { grpI, itemI }) }}
                                onDragEnter={dragging ? (e) => { handleDragEnter(e, { grpI, itemI, item }) } : null}
                                key={item.entrySetId}
                                className={dragging ? getStyles({ grpI, itemI }) : "dnd-item"}
                            >
                                <span id='dnd-filename'>{item.fileName}</span>
                                <span id='dnd-drugname'>{item.drugName}</span>
                                <span id='dnd-date'>{item.uploadedDate}</span>
                            </div>
                        ))}
                    </div>
                </div>

            ))}
        </div>
    );
};

export { DragAndDrop };