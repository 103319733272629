//Library Imports 
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import useStore from "../store";
import { shallow } from "zustand/shallow";

//Component Imports
import { stringBoolExtractor } from '../GeneralFunctions';
import { downloadExportSet, filterExportSets, getExportFilters, deleteExportSet } from '../AxiosList';
import { CheckboxDropdown } from '../EnclopediaComponents/EncyclopediaPage';
import PaginationBox from '../Pagination';
import { DataSelectionPopup } from '../DataSelectionComponents/DataSelectionPopup';
import { ZipDetailInput } from '../DataSelectionComponents/DetailInput';


//CSS and Images
import '../../SCSS_Files/DownloadStyle.scss';
import profileSample from '../../Images/profile_sample.png'
import { folder } from 'jszip';


//Filter bar meant for filtering Encyclopedia Information, Can be used for other things 
/*
    Parameters ------------------------
    filterActive --> Activeness of filter bae
    setFilterActive --> Manage Activeness of advanced filter bar
    setFolderData --> State to set Folder Archive
    setPageableResultsDTO --> State to manage pagination
    pageableResultsDTO --> pagination object state  
    batchSize --> Size of Items per Page
    setSorterType --> State to set the current sorter 
    sorterType --> Sort Object to manage sorting type
    setNotification --> Notification state management
    filters --> Current Filters applied
    setFilters --> State to set the record of existing filters
    setFilterChanged --> State to manage filter changing detection
*/
const Filter = (props) => {

    // State for filter bar state and sorters
    const [exportFilters, setExportFilters] = useState({})
    const [exportSorters, setExportSorters] = useState()

    // State to handle multi group filtering
    const [dataNames, setDataNames] = useState([])
    const [dataExportStatus, setDataExportStatus] = useState([])
    const [dataProviders, setDataProviders] = useState([])


    // Submit filter values and render in changes 
    const submitFilter = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target)

        // Compile Filter Object
        const filterObject = {
            "dataExportFileName": formData.get('fileName'),
            "dataExportMaterialNamesId": stringBoolExtractor(exportFilters.dataNames, dataNames).map((a) => a.drugId),
            "dataProvider": stringBoolExtractor(exportFilters.dataProviders, dataProviders).map((a) => a.userId),
            "dataProcessingStatus": stringBoolExtractor(exportFilters.dataExportStatus, dataExportStatus),
        }


        // Filter API
        // Send Request to Filter Folders
        filterExportSets(
            props.setFolderData,
            props.setPageableResultsDTO,
            props.pageableResultsDTO.existingPage,
            props.batchSize,
            props.sorterType.sortField,
            props.sorterType.direction,
            props.setNotification,
            sessionStorage.getItem('userId'),
            filterObject,
        )

        // Declare that the filter object has changed as well as change the new filters
        props.setFilters(filterObject)
        props.setFilterChanged(true)

    }

    // Export filter information retrieval 
    useEffect(() => {
        // Retrieve Possible filters
        getExportFilters(setExportFilters, setExportSorters, sessionStorage.getItem('userId'))
    }, [])


    // When the filters are retrieved from DB, render them to the declared state for multi search checkboxes
    useEffect(() => {

        if (Object.keys(exportFilters).length !== 0) {

            setDataNames(new Array(exportFilters.dataNames.length).fill(false))
            setDataExportStatus(new Array(exportFilters.dataExportStatus.length).fill(false))
            setDataProviders(new Array(exportFilters.dataProviders.length).fill(false))
        }

    }, [exportFilters])


    return (
        <div className='filter-wrapper'>
            {/* Enclosed in a form for easier submission */}
            <form onSubmit={submitFilter}>
                <div className="input-boxes">

                    {/* Search Bar, sort feature */}
                    <div className="search">
                        <button className="filter-button" type='submit'>
                            <i className='bx bx-search'></i>
                        </button>
                        <input type="text" placeholder='Search File Name...' className='name-filter' name='fileName' />
                    </div>

                    <div className="sort">
                        <div className="sort-label">
                            Sort By:
                        </div>
                        <select onChange={(e) => {
                            props.setSorterType({ ...props.sorterType, [`${'sortField'}`]: e.target.value })

                            filterExportSets(
                                props.setFolderData,
                                props.setPageableResultsDTO,
                                props.pageableResultsDTO.existingPage,
                                props.batchSize,
                                e.target.value,
                                props.sorterType.direction,
                                props.setNotification,
                                sessionStorage.getItem('userId'),
                                props.filters
                            )
                        }}>
                            {
                                exportSorters && exportSorters.map((sorter) => {
                                    return (
                                        <option value={sorter} key={sorter}>
                                            {sorter.split('_').map((a) => a.charAt(0).toUpperCase() + a.slice(1)).join(' ')}
                                        </option>

                                    )
                                })
                            }
                        </select>
                        <div className="order">
                            <i className={props.sorterType['direction'] == 'ascending' ? 'bx bxs-up-arrow active' : 'bx bxs-up-arrow'} onClick={() => {
                                props.setSorterType({ ...props.sorterType, [`${'direction'}`]: 'ascending' })

                                filterExportSets(
                                    props.setFolderData,
                                    props.setPageableResultsDTO,
                                    props.pageableResultsDTO.existingPage,
                                    props.batchSize,
                                    props.sorterType.sortField,
                                    'ascending',
                                    props.setNotification,
                                    sessionStorage.getItem('userId'),
                                    props.filters
                                )
                            }}></i>
                            <i className={props.sorterType['direction'] == 'descending' ? 'bx bxs-down-arrow active' : 'bx bxs-down-arrow'} onClick={() => {
                                props.setSorterType({ ...props.sorterType, [`${'direction'}`]: 'descending' })

                                filterExportSets(
                                    props.setFolderData,
                                    props.setPageableResultsDTO,
                                    props.pageableResultsDTO.existingPage,
                                    props.batchSize,
                                    props.sorterType.sortField,
                                    'descending',
                                    props.setNotification,
                                    sessionStorage.getItem('userId'),
                                    props.filters
                                )
                            }}></i>
                        </div>
                    </div>
                </div>

                {/* Advanced filters for drop down checkboxes for each filter type */}
                <div className={props.filterActive ? ("advanced-filters active") : ("advanced-filters")}>
                    <div className="opener" onClick={() => props.setFilterActive(!props.filterActive)}>
                        <div className="closed-label">Advanced Filters</div>
                        <i className='bx bxs-down-arrow' ></i>
                    </div>
                    <div className="advanced-filters-container">

                        <div className='flex-wrapper'>
                            <div className="filter-section  checkbox-filter">
                                <CheckboxDropdown options={exportFilters.dataNames} title="Chemical Name" stateList={dataNames} setStateList={setDataNames} />
                            </div>

                            <div className="filter-section  checkbox-filter">
                                <CheckboxDropdown options={exportFilters.dataProviders} title="Provider" stateList={dataProviders} setStateList={setDataProviders} />
                            </div>

                            <div className="filter-section  checkbox-filter">
                                <CheckboxDropdown options={exportFilters.dataExportStatus} title="Export Status" stateList={dataExportStatus} setStateList={setDataExportStatus} />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    )
}


// Folder Entry 
/* 
    Paraemeters --------
    entryDetails --> Details of the folder
    setNotification --> state management for notifications
*/
const FolderEntry = (props) => {
    return (
        <tr className='archive-entry'>
            <td className='column column1'>
                {props.entryDetails.exportBy.imageUrl?<img className='user-image' src={`${process.env.REACT_APP_AWS_BUCKET_URL}${props.entryDetails.exportBy.imageUrl}`} alt="" />:<img className='user-image' src={profileSample} alt="" />}
                <div className="user-name">{props.entryDetails.exportBy.userName}</div>
            </td>
            <td className='column column2'>
                <h3>{props.entryDetails.fileName}</h3>
                <div>{props.entryDetails.exportDataType.split('_').map((a) => a.charAt(0).toUpperCase() + a.slice(1)).join(' ')} (Data Entry Amount: {props.entryDetails.dataEntryAmount})</div>
            </td>
            <td className='column column3'>
                <p>{props.entryDetails.dataSpace}MB</p>
            </td>
            <td className='column column4'>
                <p>{props.entryDetails.exportDate}</p>
            </td>
            <td className='column column5'>
                <i className='bx bx-cloud-download' onClick={() => {
                    downloadExportSet(
                        props.entryDetails.exportId,
                        props.entryDetails.fileName,
                        props.setNotification
                    )
                }}></i>
                <i className='bx bx-x' onClick={()=>{
                    deleteExportSet(
                        props.entryDetails.exportId,
                        sessionStorage.getItem('userId'), 
                        props.setNotification,
                        props.filterExportSets,
                        props.afterEffect
                    )
                } }></i>
            </td>
        </tr>
    )
}


//Main Download Page Component
/*
    Should allow users the ability to select datasets, compress them into folders and download them 
    Should allow users the ability to delete these compressed datasets (TO BE DONE)
    Users should be able to see details of each folder such as chemical proportion, content and size
    Users of the same organisation should be able to view the compressed folders
*/
const Download = () => {

    //Download Page Component Management
    const [filterActive, setFilterActive] = useState(false)
    const [folderData, setFolderData] = useState([]);
    const [pageableResultsDTO, setPageableResultsDTO] = useState({})
    const [currentSlide, setCurrentSlide] = useState()

    // Final Submission Data State to be parsed in creating Export sets
    const [finalSubmissionData, setFinalSubmissionData] = useState({})

    // Sorter state management
    const [sorterType, setSorterType] = useState({ sortField: 'export_date', direction: 'descending' })

    //Archive Filter Object
    const [filters, setFilters] = useState({
        "dataExportFileName": '',
        "dataExportMaterialNamesId": [],
        "dataProvider": [],
        "dataProcessingStatus":[]
    })

    // Filter change detection
    const [filterChanged, setFilterChanged] = useState(false)
    
    // Pagination Sizing
    const pageOptions = [5, 10, 15, 20]
    const [batchSize, setBatchSize] = useState(pageOptions[0])

    const navigate = useNavigate();

    //Access Store for notification and loading page
    const { setNotification, setLoadingActiveness } = useStore(
        (state) => ({
            setNotification: state.setNotification,
            setLoadingActiveness: state.setLoadingActiveness
        }),
        shallow
    );

    // Useeffect to retrieve the export sets of the user
    useEffect(() => {
        if (sessionStorage.getItem('userId') === 'undefined') {
            navigate('/');
        }

        setLoadingActiveness(true)
        //Export Set Retrieval
        filterExportSets(
            setFolderData,
            setPageableResultsDTO,
            0,
            batchSize,
            sorterType.sortField,
            sorterType.direction,
            setNotification,
            sessionStorage.getItem('userId'),
            filters
        );
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setLoadingActiveness(false)
        }, 1000)
        
    }, [folderData])


    return (
        <>
            <main className='main-content download-page'>
                <div className='material-archive-header'>
                    <div className="title">Data Export Library</div>
                    <div className="subtitle">Export any type of data you have accumulated on our platform for your own use</div>
                </div>

                <section className="filter-section main-section">
                    {/* Filter section */}
                    <Filter
                        filterActive={filterActive}
                        setFilterActive={setFilterActive}

                        setFolderData={setFolderData}

                        pageableResultsDTO={pageableResultsDTO}
                        setPageableResultsDTO={setPageableResultsDTO}

                        batchSize={batchSize}

                        setSorterType={setSorterType}
                        sorterType={sorterType}

                        setNotification={setNotification}

                        filters={filters}
                        setFilters={setFilters}

                        setFilterChanged={setFilterChanged}

                    />
                </section>

                {/* Export display section */}
                <section className="display-section">
                    <section className='data-entry-section archive-section'>
                        <div className="data-entry-group">
                            <div className="group-header">
                                <div className="title">Active Items</div>
                                <button className="create-button" onClick={() => setCurrentSlide(1)}>+ Create</button>
                            </div>
                            <div className="scroll-prompt">
                                <span>Scroll Here For More Info</span>
                                <i className='bx bx-right-arrow-alt'></i>
                            </div>
                            <div className="table-wrapper">
                            <table className='data-table'>
                                <thead>
                                    <tr className="list-headers">
                                        <th className="list-header header1">User</th>
                                        <th className="list-header header2">File Information</th>
                                        <th className="list-header header3">Size</th>
                                        <th className="list-header header4">Creation</th>
                                        <th className="list-header header5">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        folderData && folderData.map((values, key) => {

                                            return (
                                                <FolderEntry key={key} entryDetails={values} setNotification={setNotification} filterExportSets={
                                                    () => {
                                                        filterExportSets(
                                                            setFolderData,
                                                            setPageableResultsDTO,
                                                            0,
                                                            batchSize,
                                                            sorterType.sortField,
                                                            sorterType.direction,
                                                            setNotification,
                                                            sessionStorage.getItem('userId'),
                                                            filters
                                                        )

                                                        setFilterChanged(true)
                                                        setLoadingActiveness(false)
                                                    }
                                                } afterEffect={ ()=> setLoadingActiveness(false)}/>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </section>
                </section>

                {/* Pagination Component*/}
                <section className='pagination-section archive-section'>
                    {pageableResultsDTO && pageableResultsDTO.totalPages > 0 && <PaginationBox
                        retrievalFunction={filterExportSets} //Change
                        setStateList={setFolderData}
                        setPageableResultsDTO={setPageableResultsDTO}
                        pageableResultsDTO={pageableResultsDTO}
                        sorterType={sorterType}
                        batchSize={batchSize}
                        setNotification={setNotification}
                        maxPageBoxes={5}
                        filters={filters}
                        setBatchSize={setBatchSize}
                        pageOptions={pageOptions}
                        filterChanged={filterChanged}
                        setFilterChanged={setFilterChanged}
                    />}

                </section>
            </main>
            {/* Pop up slides for data selection to export */}
            <div className="popup-wrapper">
                <div className={currentSlide == 1? 'slide': 'slide inactive'}>
                    <DataSelectionPopup setNotification={setNotification} changeNextFunction={() => setCurrentSlide(2)} data={finalSubmissionData} setData={setFinalSubmissionData} closeSlide={() => {
                        setCurrentSlide(0)
                        setFinalSubmissionData({})
                    }}/>
                </div>
                <div className={currentSlide == 2? 'slide': 'slide inactive'}>
                    <ZipDetailInput setLoadingActiveness={setLoadingActiveness} setNotification={setNotification} changePrevFunction={() => setCurrentSlide(1)} data={Object.values(finalSubmissionData).map(a => Object.keys(a)).flat()} setData={setFinalSubmissionData} closeSlide={() => {
                        setCurrentSlide(0)
                        setFinalSubmissionData({})
                        filterExportSets(
                            setFolderData,
                            setPageableResultsDTO,
                            0,
                            batchSize,
                            sorterType.sortField,
                            sorterType.direction,
                            setNotification,
                            sessionStorage.getItem('userId'),
                            filters
                        );

                        setLoadingActiveness(false)

                    }}/>
                </div>
            </div>
        </>
    )
}

export default Download;