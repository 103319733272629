//Library Imports
import React, { useEffect } from "react";
import useStore from "./store.js";
import {shallow} from 'zustand/shallow';

//CSS and Image Imports
import "../SCSS_Files/NotificationStyle.scss"
import errorIcon from '../Icons/GeneralIcons/error.png'
import successIcon from '../Icons/GeneralIcons/success.png'
import infoIcon from '../Icons/GeneralIcons/info.png'

// Types of messages
const messageType = {
    'error': {icon: errorIcon, css: 'error-notification'},
    'success': {icon: successIcon, css: 'success-notification'},
    'info': {icon: infoIcon, css: 'info-notification'}
}

// Notification System to support 3 types of notification
/*
    Should allow for easy usage everywhere
    Only supports 1 notification at a time currently
    Parameters-----------
    title --> Main title
    subtitle --> Message 
    notificationType --> "success"/"error"/"info" to change notification style
*/
const Notification = (props) => {

    //Notification State usage for closing notification
    const { setNotification,  notification} = useStore(state => ({ 
        setNotification: state.setNotification, 
        notification: state.notification
    }), shallow);

    useEffect(() => {
        console.log(notification)
        if (notification.title !== null && notification.subtitle !== null && notification.notificationType !== null) {
            setTimeout(() => {
                setNotification(null, null, null)
            }, 8000)
        }
    }, [notification])

    return(
        <div className={`notification ${messageType[props.notificationType].css}`}>
            {/* Icon */}
            <img src={messageType[props.notificationType].icon} alt="Error Icon" className='notification-icon'/>
            {/* Message */}
            <div className="message">
                <div className="notification-title">
                    {props.title}
                </div>
                <div className="notification-subtitle">
                    {props.subtitle}
                </div>
            </div>
            <button className="notification-close" onClick={() => setNotification(null, null, null)}>
                Close
            </button>
        </div>
    )
};

export {Notification};