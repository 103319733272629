//Library Imports
import React, { useState } from "react";
import useStore from "../store.js";
import {shallow} from 'zustand/shallow';

//Component Imports
import { Carousel } from "./Carousel.js";
import { ProgressSteps } from "./StepTracker.js";
import { DataFileSlide } from "./DataFilePage.js";
import { ReferenceFileSlide } from "./ReferenceFilePage.js";
import { GlobalFormSlide } from "./GlobalFormPage.js";
import { ObjectSwapKey } from "../GeneralFunctions.js";
import { SpecificFormSlide } from "./SpecificFormPage.js";
import { SuccessfulSubmissionSlide } from "./FinalSubmissionSlide.js";
import { emptyFile, subListing } from "../ConditionalChecks.js";

//CSS and Image Imports 
import '../../SCSS_Files/UploadPageStyle.scss';
import { css } from "@emotion/react";


//Device Dictionary for Synonyms
const devices = {
    raman: ["Raman","raman","RAMAN","IV","Infrared Vision"],
    menlo: ["Menlo", "menlo"],
    "xs-tds-01": ["XS-TDS-01","XS-TDS","xs-tds-01","xs-tds","XS_TDS_01"]
}

//Device to ID Reference
const deviceIdReference = {
    'xs-tds-01': 1,
    'menlo': 2,
    'raman': 3
}

//ID to Device Reference 
const idDeviceReference = ObjectSwapKey(deviceIdReference)

//Main Upload Popup
const  Upload = (props) => {

    const [activeStep, setActiveStep] = useState(1)
    const [deactivatePrevButton, setDeactivatePrevButton] = useState(false)
    const [deactivateNextButton, setDeactivateNextButton] = useState(false)

    const { dataSubmissionFiles, referenceSubmissionFiles, detectedDevices} = useStore(state => ({
        dataSubmissionFiles: state.dataSubmissionFiles,
        referenceSubmissionFiles: state.referenceSubmissionFiles,
        detectedDevices: state.detectedDevices
    }), shallow);
    
    //Steps for user to conduct  with content and css for step tracker
    /*
        Object Attributes and Properties
        label --> Name of label used for step tracker 
        slide --> Contents of Slide (Can be custom components)
        container_css --> Special direct CSS injector for step tracker maneuvering 
        disableNext --> Integer to signify disabling of certain pages
    */
    const steps = [
        {
            //Slide 1 for request of data files
            label: 'Data Files',
            slide: <DataFileSlide stepCount='1' devices={devices}  header='Upload Data Files'/>,
            container_css: css`
                width: 50%;
                @media only screen and (max-width: 900px) {
                    width: 100%;    
                }
            `,
            disableNext: 1,
            conditionalCheck: emptyFile,
            conditionalCheckParameters: [dataSubmissionFiles, activeStep, 1],
            conditionalCheckError: ['No Files Found', 'Please upload at least 1 data file before proceeding', 'error']
        },
        {
            //Detection of file types and request of reference files
            label: 'References',
            slide: <ReferenceFileSlide stepCount='2' header='Reference Preparation' activeStep={activeStep} setDeactivateNextButton={setDeactivateNextButton} setDeactivatePrevButton={setDeactivatePrevButton}/>,
            container_css: css`width: 100%;`,
            disableNext: 0,
            conditionalCheck: subListing,
            conditionalCheckParameters: [Object.keys(referenceSubmissionFiles), [...(new Set(Object.values(detectedDevices)))], activeStep, 2],
            conditionalCheckError: ['Missing References', 'Please select a reference for each device', 'error']
        },
        {
            //Keying of shared information
            label: 'General Info',
            slide: <GlobalFormSlide stepCount='3' header='General Information' activeStep={activeStep} idDeviceReference={idDeviceReference} setActiveStep={setActiveStep} setArchiveData={props.setArchiveData} filterDataArchive = {props.filterDataArchive}/>,
            container_css: css`
                width: 100%;
            `,
            disableNext: 0
        },
        // {
        //     //Keying of individual information
        //     label: 'Specifics',
        //     slide: <SpecificFormSlide stepCount='4' header='File Based Adjustments'activeStep={activeStep}/>,
        //     container_css: css`
        //         width: 50%;
        //         float: right;
        //         @media only screen and (max-width: 900px) {
        //             width: 100%;  
        //             float: none;  
        //         }
        //     `,
        //     disableNext: 0
        // },
    ]

    //State used to disable buttons if needed
    let [disableNextList, setDisableNextList] = useState(
        steps.map((step) => {
            return step['disableNext'];
    }))

    return (
        <div className={props.uploadPopup?('popup active'):('popup')}>
            <div className="popup-content">
                <ProgressSteps 
                    activeStep={activeStep} 
                    setActiveStep={setActiveStep}
                    steps={steps} 
                    deactivatePrevButton={deactivatePrevButton} 
                    deactivateNextButton={deactivateNextButton} 
                    setDeactivatePrevButton={setDeactivatePrevButton} 
                    setDeactivateNextButton={setDeactivateNextButton}
                    disableNextList={disableNextList}
                    setDisableNextList={setDisableNextList}
                    setUploadPopup={props.setUploadPopup}
                />
                <Carousel slideContent={steps.map(step => step.slide)} activeStep={activeStep} />
                <SuccessfulSubmissionSlide stepsLength = {steps.length} activeStep = {activeStep} setUploadPopup={props.setUploadPopup} setActiveStep={setActiveStep}/>
            </div>
        </div>
    );

};

export {Upload, deviceIdReference, idDeviceReference};