import React, { memo }  from 'react';
import { Handle, useReactFlow, useStoreApi } from 'reactflow';
import useStore from '../store';
import { shallow } from 'zustand/shallow'
import { useForm } from 'react-hook-form'

//Handle Deligator Based on node ID
const HandleAmt = ({id}) => {
    switch(id) {
        case 'start':
            return(
                <>
                    <Handle type="source" position="right" />
                    <Handle type="target" position="bottom" />
                </>
            )
        case 'end':
            return(
                <>
                    <Handle type="target" position="left" />
                </>
            )
        default:
            return(
                <>
                    <Handle type="source" position="right" />
                    <Handle type="target" position="left" />
                </>
            )
    }
}


// Function to allow for saving data but not submitting data (may be used in the future for model diagram saving
const saveNotSubmit = (new_data, id, nodeInternals) => {

    //Compile info to save into formdata and object 
    let data_object = {};
    for (var p of new_data) {
        data_object[p[0]] = p[1];
    }    

    // Take node information from the reactflow store
    const nodes = Array.from(nodeInternals).map(([, node]) => node);

    //Edits the node values in the store so that it can be saved and placed back later on
    let counter = 0;
    nodes.map((node) => {
        
        if(node.id == id) {

            // IF the node is for hypertuning, its mapping pattern is different
            if (id.includes('hypertune')){

                node.data.modelBuildingNodeParameterModelList.map((parameter) => {
                    if ( parameter.parameterName == 'Hyperparameter Tuning method') {
                            parameter.modelBuildingNodeStringParameterModel.defaultValue = data_object['Hyperparameter Tuning method'];
                    };
                });
                Object.keys(data_object).slice(1, ).forEach((key, index) => {
                    node.data.modelBuildingNodeParameterModelList[1].modelBuildingAlgoHyperParameterOptionModel.map((parameter) => {
                        if (key === parameter.algorithmParameterName) {
                            Object.keys(parameter).forEach((paramKey,paramIndex) => {
                                if(parameter[paramKey] !== null && paramKey.includes('modelBuilding')) {
                                    parameter[paramKey].defaultValue = data_object[key];
                                };
                            });
                        };
                    });
                });
            } else {
            // Else standard procedure with the process and other nodes
                Object.keys(data_object).forEach((key, index) => {
                    node.data.modelBuildingNodeParameterModelList.map((parameter) => {
                        if (key === parameter.parameterName) {
                            Object.keys(parameter).forEach((paramKey,paramIndex) => {
                                if(parameter[paramKey] !== null && paramKey.includes('modelBuildingNode')) {
                                    parameter[paramKey].defaultValue = data_object[key];
                                };
                            });
                        };
    
                    });
                });
            };

        };
        counter++;
    });
};



// Node Layouts and Formatting ------------------------------------------------------------------------------------------------------
//Node Explanation Component
const NodeExplanation = ({nodeId, explanation}) => {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const onActivate = () => {
        const { nodeInternals } = store.getState();
        //Set An Explanation for each node as well as a trigger button
        setNodes(
            Array.from(nodeInternals.values()).map((node) => {
                if (node.id === nodeId) {
                    if (node.className.includes('open')) {
                        node.className = node.className.replace('open', '')
                        return node
                    };
                    node.className += ' open'

                }
                return node
            })
        );
    }

    return(
        <>
            <div className="custom-node__info-button" onClick={onActivate}>
                <i className='bx bx-book-reader'></i>
            </div>
            <div className='node-explanation'>
                <div className='explanation-label'>Explanation</div>
                {explanation}
            </div>
        </>
    );
};



// Node layout for template nodes-------------------------------------------------------------------------------------------
const CustomLayoutNode = ({id, data}) => {

    //Access Parameter Bar Opened State
    const { parameterBarOpened, setParameterBarOpened } = useStore(
        (state) => ({ 
            parameterBarOpened: state.parameterBarOpened,
            setParameterBarOpened: state.setParameterBarOpened
        }),
        shallow
    );

    //Access Nodes 
    const reactFlowStore = useStoreApi();
    const { nodeInternals } = reactFlowStore.getState();  

    return(
        <>
            <div onClick={(e) => {
                //Save Not Submit mechanics
                if(parameterBarOpened === id) {
                    if (document.getElementById(id+'_form') !== null){
                        saveNotSubmit(new FormData(document.forms[id+'_form']), id, nodeInternals);
                    }
                    setParameterBarOpened(null);
                }else{
                    setParameterBarOpened(id);
                };
            }} className="custom-node__content">
                <i className='bx bx-expand-horizontal'></i>
                <div className="custom-node__header">
                    {data.nodeName}
                </div>
                <div className="custom-node__body">
                    {data.description}
                </div>
                <HandleAmt id={id}/>                
            </div>


            <NodeExplanation  nodeId={id} explanation={data.explanation} />
        </>
    );
};

//Node Layout for DnD process nodes----------------------------------------------------------------------------------
const ProcessNodeLayout = ({id, data}) => {
    const { parameterBarOpened, setParameterBarOpened, } = useStore(
        (state) => ({ 
            parameterBarOpened: state.parameterBarOpened,
            setParameterBarOpened: state.setParameterBarOpened,
        }),
        shallow
    )

    const reactFlowStore = useStoreApi();
    const { nodeInternals } = reactFlowStore.getState();    

    return(
        <>
            <div onClick={(e) => {
                if(parameterBarOpened === id) {
                    if (document.getElementById(id+'_form') !== null){
                        saveNotSubmit(new FormData(document.forms[id+'_form']), id, nodeInternals)
                    }

                    setParameterBarOpened(null)

                }else{
                    setParameterBarOpened(id)
                }
            }} className="custom-node__content">
                <i className='bx bx-expand-horizontal'></i>
                <div className="custom-node__header">
                    {data.nodeName}
                </div>
                <HandleAmt id=''/>                
            </div>

            <NodeExplanation  nodeId={id} explanation={data.explanation} />
        </>
    );
};



// Node of models----------------------------------------------------------------------------------------------
const CustomModelNode = ({id, data}) => {
    const { 
        modelAlgOpened, setModelAlgOpened, setNodeBarSection, setNodeBarButton, setHeaderOpened, setParameterBarOpened, algorithmActive} = useStore(
        (state) => ({ 
            modelAlgOpened: state.modelAlgOpened,
            setModelAlgOpened: state.setModelAlgOpened,
            setNodeBarSection: state.setNodeBarSection,
            setNodeBarButton: state.setNodeBarButton,
            setHeaderOpened: state.setHeaderOpened,
            setParameterBarOpened: state.setParameterBarOpened,
            algorithmActive: state.algorithmActive,
        }),
        shallow
    );
    
    return(
        <>
            <div onClick={(e) => {
                if(modelAlgOpened === id) {
                    setModelAlgOpened (null);
                }else{
                    setParameterBarOpened(null);
                    setNodeBarSection(false);
                    setNodeBarButton(false);
                    setHeaderOpened(false);
                    setTimeout(() => {
                        setModelAlgOpened(id);
                    }, 300);
                };
            }} className='custom-node__content'>
                <i className='bx bx-expand-horizontal'></i>
                <div className="custom-node__header">
                    {algorithmActive}
                </div>
                <div className="custom-node__body">
                    {(algorithmActive == 'No Model Selected')?(data.description):(`You have created a ${algorithmActive}  for your model. Now select your tuning method inside Hyperparameter Tuning`)}
                </div>
                <HandleAmt id={id}/>                
            </div>

            <NodeExplanation  nodeId={id} explanation={data.explanation} />
        </>
    );
};


//Administrative Node layout-------------------------------------------------------------------------------
const AdminNode = ({id, data}) => {

    const { register, handleSubmit, watch, formState: { errors }} = useForm({
        shouldFocusError:false,   
        mode: "onSubmit",
        reValidateMode: "onChange",
    });

    
    const { submissionStack} = useStore(
        (state) => ({
            submissionStack: state.submissionStack,
        }),
        shallow
    );
    
    const reactFlowStore = useStoreApi();
    const { setCenter } = useReactFlow();

    const adminSubmit = (admin_data) => {
        submissionStack.modelName = admin_data['Model Name'];
        submissionStack.modelDescription = admin_data['Model Description'];
        submissionStack.createdBy = parseInt(sessionStorage.getItem('userId'));
        submissionStack.projectId = 1;
        submissionStack.modelBucket = "anor-senstrio-model";
        submissionStack.matrixBucket = "anor-senstrio-matrix";
    };
    
    //Collects find node and node info, then centralise it on the screen
    const focusNode = (id) => {
        const { nodeInternals } = reactFlowStore.getState();
        const nodes = Array.from(nodeInternals).map(([, node]) => node);

        if (nodes.length > 0) {
            nodes.map((node) => {
                if(node.id == id) {

                    const x = node.position.x + node.width / 2;
                    const y = node.position.y + node.height / 2;
                    const zoom = 1.85;
        
                    setCenter(x, y, { zoom, duration: 1000 });
                };
            });
        };
    };

    return(
        <div className='custom-node__content'>
            <div className="custom-node__header">
                {data.nodeName}
            </div>
            <div className="custom-node__body">
                {data.description}
                <form id='admin_form' onSubmit={handleSubmit(adminSubmit)}>
                    <div className='admin-node-form'>
                        <label>Model Name</label>
                        <input type='text' defaultValue={data.inputs.modelName} placeholder={'Key in the Model Name'} {...register('Model Name', {required:true})} />
                        {errors['Model Name']?.type === 'required' && <p className='form-error'>This value is required</p>}
                        {errors['Model Name'] && focusNode('admin')}

                    </div>
                    <div className='admin-node-form'>
                        <label>ModelDescription</label>
                        <textarea placeholder={'Key in the Model Description'} {...register('Model Description', {required:true})}></textarea>
                        {errors['Model Description']?.type === 'required' && <p className='form-error'>This value is required</p>}
                        {errors['Model Description'] && focusNode('admin')}
                    </div> 
                </form>
            </div>
            <Handle type="source" position='top' />
        </div>
    );
};

const ProcessNodeFormat = memo(ProcessNodeLayout);
const CustomLayoutNodeFormat = memo(CustomLayoutNode);
const CustomModelNodeFormat = memo(CustomModelNode);
const AdminNodeFormat = memo(AdminNode);


//Template Nodes to be used
export {CustomLayoutNodeFormat, CustomModelNodeFormat, AdminNodeFormat, ProcessNodeFormat};


