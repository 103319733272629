//Library Imports
import React from "react";

// Slide Wrapper used to show correct slide based on slide number and active slide number 
/* 
    Parameters-----
    slideNumber --> Integer
    content --> HTML/React Component
    activeStep --> State (contains String)
*/
const Slide = (props) => {
    return(
        <>
            <div
                className={props.slideNumber === props.activeStep ? "slides active" : "slides"}
            >
                {props.content}
            </div>
        </>
    )
}

// Main Carousel to Map all slides and associate a slide number using index + 1
/*
    Parameters-----
    slideContent --> Array of HTML/React Components
    activeStep --> State (contains String)
*/
const Carousel = (props) => {
    return (
        <>
            <div className='carousel'>
                {props.slideContent.map((element, slideNumber) => {
                    return(
                        <Slide slideNumber={slideNumber+1} content={element} activeStep={props.activeStep} key={slideNumber+1}/>
                    )
                })}
            </div>
        </>
    );
};

export {Carousel}