import { create } from 'zustand';
import { removeAttribute } from './GeneralFunctions';

// define the store
const useStore = create(set => ({

    // Notification System
    notification: {title: null, subtitle: null, notificationType: null},
    setNotification: (title, subtitle, notificationType) => set({
        notification: {
            title: title, 
            subtitle: subtitle, 
            notificationType: notificationType
        }
    }),

    loadingActiveness: false,
    setLoadingActiveness: (value) => set({
        loadingActiveness: value
    }),


    //Upload Page Global States and Functions
    // Data Files for display----------------------------------------------------------------------
    dataFiles: [],
    setDataFiles: (files) => set({
        dataFiles: files
    }),
    clearDataFiles: (files) => set({
        dataFiles: []
    }),

    // Compiled Submission Files for reference step------------------------------------------------
    dataSubmissionFiles: [],
    setDataSubmissionFiles: (fileId, currentFile, detectedDevice) => set(state => ({
        dataSubmissionFiles: [...state.dataSubmissionFiles, {"fileId": fileId ,"file": currentFile, 'device': detectedDevice}]
    })),
    filterDataSubmissionFiles:(selectedKey)=> set(state => ({
        dataSubmissionFiles: state.dataSubmissionFiles.filter(element => element.fileId !== selectedKey)
    })),

    clearDataSubmissionFiles: () => set({
        dataSubmissionFiles: []
    }),

    // Detected Devices for verification ----------------------------------------------------------
    detectedDevices: {},
    setDetectedDevices: (fildeId ,deviceId) => set(state => ({
        detectedDevices: {...state.detectedDevices, [`${fildeId}`]:deviceId}
    })), 
    clearDetectedDevices: () => set({
        detectedDevices: {}
    }),
    filterDetectedDevices: (fileId) => set(state => ({
        detectedDevices: removeAttribute(state.detectedDevices, fileId)
    })), 

    // Organised Submission Files for easier organisation------------------------------------------
    organisedSubmissionFiles: [],
    setOrganisedSubmissionFiles: (deviceId, fileList) => set(state => ({
        organisedSubmissionFiles: {...state.organisedSubmissionFiles, [`${deviceId}`]: fileList}
    })),
    clearOrganisedSubmissionFiles: () => set({
        organisedSubmissionFiles: []
    }),

    // Reference files selected for each device type------------------------------------------------
    referenceSubmissionFiles: {},
    setReferenceSubmissionFiles: (deviceId, referenceFileId, referenceFileName) => set(state => ({
        referenceSubmissionFiles: {...state.referenceSubmissionFiles, [`${deviceId}`]:{'referenceFileId':referenceFileId, 'referenceFileName':referenceFileName}}
    })),

    filterSetReferenceSubmissionFiles: (list) => set({
        referenceSubmissionFiles: list
    }),
    
    clearReferenceSubmissionFiles: () => set({
        referenceSubmissionFiles: {}
    }),

    // State used to add form ----------------------------------------------------------------------
    globalSubmissionForms: {},
    setGlobalSubmissionForms: (deviceId, formAndFiles) => set(state => ({
        globalSubmissionForms: {...state.globalSubmissionForms, [`${deviceId}`]: formAndFiles}
    })),
    removeGlobalSubmissionForms: (deviceId) =>  set(state => ({
        globalSubmissionForms: removeAttribute(state.globalSubmissionForms, deviceId)
    })),
    clearGlobalSubmissionForms:  () => set({
        globalSubmissionForms: {}
    }),

    finalSubmissonData: {},
    setFinalSubmissionData: (deviceId, fileId, specificFormDetails) => set(state => ({
        finalSubmissonData: {...state.finalSubmissonData, [`${deviceId}`]: {[`${fileId}`]: specificFormDetails}}
    })),



    


    //Data Selection for Model Builder-------------------------------------------------------------------------------------------------
    dataType: null,
    setDataType: (value) => set({
        dataType: value
    }),

    entryList: [],
    setEntryList: (value) => set({
        entryList: value
    }),

    entryProportions: '',
    setEntryProportions: (value) => set({
        entryProportions: value
    }),

    modelName: null, 
    setModelName: (value) => set({
        modelName: value
    }),

    totalData: null,
    setTotalData: (value) => set({
        totalData: value
    }),

    //Model Builder ---------------------------------------------------------------------------------------------------------------------

    headerOpened: true,
    setHeaderOpened: (value) => set(
        () => ({headerOpened: value})
    ),

    nodeBarTab: 'atomic',
    setNodeBarTab: (tab) => set(
        () => ({nodeBarTab: tab})
    ),

    nodeBarSection: true,
    setNodeBarSection: (value) => set(
        () => ({nodeBarSection: value})
    ),

    nodeBarButton: false,
    setNodeBarButton: (value) => set(
        () => ({nodeBarButton: value})
    ),

    parameterBarOpened: null,
    setParameterBarOpened: (number) => set(
        () => ({parameterBarOpened: number})
    ),

    rangeVal: 0,
    setRangeVal: (value) => set(
        () => ({rangeVal: value})
    ),

    valPercent: 0,
    setValPercent: (value) => set(
        () => ({valPercent: value})
    ),

    modelAlgOpened: null,
    setModelAlgOpened: (number) => set(
        () => ({modelAlgOpened: number})
    ), 
    navBar: true, 
    setNavBar: (value) => set(
        () => ({navBar: value})
    ),
    isActive: false, 
    setActive: (value) => set(
        () => ({isActive: value})
    ),
    isProfileActive: false, 
    setProfileActive: (value) => set(
        () => ({isProfileActive: value})
    ),

    //Pop Up Page
    currentPopUp: 'ModelChooser',
    setCurrentPopUp: (page) => set(
        () => ({currentPopUp: page}) 
    ),
    //Active Alg----------------------------------------
    algorithmActive: 'No Model Selected', 
    setAlgorithmActive: (value) => set(
        () => ({algorithmActive: value}) 
    ),

    //Pending Alg Selection-----------------------------
    algorithmSelect: null, 
    setAlgorithmSelect: (value) => set(
        () => ({algorithmSelect: value}) 
    ),

    algorithmSelectId: null,
    setAlgorithmSelectId: (value) => set(
        () => ({algorithmSelectId: value}) 
    ),

    //Element In Pop Up for alg select
    algorithmDetailElement: null,
    setDetailElement: (value) => set(
        () => ({algorithmDetailElement: value}) 
    ),


    algorithmHyperparameterElements: 'No Algorithm Selected',
    setAlgorithmHyperparameterElements: (value) => set(
        () => ({algorithmHyperparameterElements: value}) 
    ),
    
    //Selecting Model, sets hyperparams
    hypertuneParams: null,
    setHypertuneParams: (value) => set(
        () => ({hypertuneParams: value})
    ),

    hypertuneRegister: null,
    setHypertuneRegister: (value) => set(
        () => ({hypertuneRegister: value})
    ),
    
    secondarySubmit: false,
    setSecondarySubmit: (value) => set(
        ()=> ({secondarySubmit: value})
    ),

    submissionStack: {
        "modelName": null,
        "modelDescription": null,
        "algorithmId": null,
        'projectId': null,
        "dataTypeTrained": null,
        "entryList": null,
        "createdBy": null,
        "trainProportion": null,
        "testProportion": null,
        "hyperparameterTuneMethod":null,
        "modelTrainingNodeInputDetailsDTOList":[],
        "modelTrainingAlgorithmInputDetailsDTOList":[],
    },
    setSubmissionStack: (value) => set(
        ()=> ({submissionStack: value})
    )
}))

export default useStore