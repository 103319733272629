//Library Imports
import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import useStore from "../store.js";
import { shallow } from 'zustand/shallow';
import { useNavigate } from "react-router-dom";

//Component Imports 
import { getAllDataSelectionEntries } from "../AxiosList";
import { DragAndDrop } from "../DragAndDrops/DragAndDrop.js";

//CSS and Image Imports
import '../../Deprecated_CSS/CSS_Files/ModelSelectPopupStyle.css';


//Data Selections for model development
const DataSelectInput = (props) => {

    //Set these states to use in next page
    const{setDataType, setEntryList, setEntryProportions, setModelName, setTotalData} = useStore(state => ({ 
        setDataType: state.setDataType,
        setEntryList: state.setEntryList,
        setEntryProportions: state.setEntryProportions,
        setModelName: state.setModelName,
        setTotalData: state.setTotalData
    }), shallow);

    //For Navigation to next page 
    const navigate = useNavigate();
    
    //React Hook Form Essentials
    const { register, handleSubmit, watch, formState: { errors }} = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
    });


    //On Submit Keep Information and move to model builder
    const submitDataSelect = (data) => {
        let total = 0;
        let indexList = [];
        let chemProportions = {};
        let chemProportionsString = '';

        //Check if there are entry sets selected
        if (props.list[1].items.length === 0) {
            alert('No files Selected');
            return;
        };

        //Create a Chemical Proportion Object
        props.list[1].items.map((set) => {
            indexList.push(set.entrySetId);
            if (set.drugName in chemProportions) {
                chemProportions[set.drugName] = chemProportions[set.drugName] + set.experimentEntryCount;
            }else{
                chemProportions[set.drugName] = set.experimentEntryCount;
            };
            total += set.experimentEntryCount;
        });
        
        //Use Chemical Proportion Object to create a string 
        Object.keys(chemProportions).forEach((key) => {
            chemProportionsString += `${key} ${((chemProportions[key]/total) * 100 ).toFixed(2)}%,`;
        });
        
        // This time make values global rather than post (No need axios)
        setDataType(data.dtype);
        setEntryList(indexList);
        setEntryProportions(chemProportionsString.slice(0,-1));
        setModelName(data.modelName);
        setTotalData(total);

        //Close pop up and reset data list and move to model builder
        props.setTrigger(false);
        props.setList( data = [
            {
                title: 'Drag and drop files that you wish to examine your model with.',
                items: props.resetItems
            },
            {
                title: 'Selected Files',
                items: []
            }
        ]);
        navigate('/modelbuilder');
    };

    return (
            <div className={props.trigger? ('zipdetails-input-popup'):('zipdetails-input-popup inactive')}>
            <div className='zipdetails-input-popup-inner'>
                <button className='zipdetails-input-close-btn' onClick={() => props.setTrigger(false)}><i className='bx bx-x'></i></button>
                <form onSubmit={handleSubmit(submitDataSelect)}>
                    <div className='row zipdetails-form'>

                        <div className="detail">
                            <label id="package-name-label" htmlFor="pzname">Model Name</label><br/>
                            <input type="text" name="pzname" id="package-name-input"  {...register( 'modelName', {required: true})}/>
                            {errors.modelName && (errors.modelName.type == 'required' && <p className="form-error">Model Name is Required</p>)}
                            
                        </div>
                        
                        <div className="detail">
                        <label id="datatype-label" htmlFor="dtype">Datatype</label> <br/>

                            <select name="dtype" id="datatype-input" {...register( 'dtype', {required: true})}>
                                <option value="peaks">Peaks</option>
                                <option value="smooth">Smooth</option>
                                <option value="power_law">Power Law</option>
                                <option value="power_coef">Power Coefficient</option>
                            </select>
                        </div>
                        
                        <button id="create-btn" onClick={handleSubmit(submitDataSelect)}>Create</button>
                        {props.children}
                    </div>
                </form>
            </div>
        </div>
    );
};


const DataSelectPopup = (props) =>{
    const userId = sessionStorage.getItem('userId');
    const [dataSelectDetailsPopup, setDataSelectDetailsPopup] = useState(false);
    const [resetItems, setResetItems] = useState([]);
    const [list, setList] = useState([]);
    
    //Collect all entry sets that can be use
    useEffect(() => {
        getAllDataSelectionEntries( setResetItems, setList, userId);
    }, []);

    //Main Data Select Pop up 
    return (
        <>
            <div className={props.trigger ? ('folder-input-popup') : ('folder-input-popup inactive')}>
                <div className='folder-input-popup-inner'>
                    <button className='folder-input-close-btn' onClick={() => props.setTrigger(false)}><i className='bx bx-x'></i></button>
                    <div className='row'>
                        <div className='column dnd-column'>
                            <div className="drag-title">Drag and drop files that you wish compile and use</div>
                            <input id="folder-input-search" type="text" placeholder="search" ></input>
                            <button id='export-dnd-filter-btn'><i className='bx bx-filter'></i></button>
                        </div>
                        <div className="verticalLine"></div>
                        <div className='column dnd-column'>
                            <div className="select-title">Selected Files</div>
                        </div>
                    </div>
                    {/* Close Data Select Popup and move to details pop up */}
                    <button id="details-done-btn" onClick={() => {
                        props.setTrigger(false);
                        setDataSelectDetailsPopup(true);
                    }}>Done</button>

                    {/* Drag and Drop Component */}
                    <DragAndDrop list={list} setList={setList} />
                </div>
            </div>
            <DataSelectInput trigger={dataSelectDetailsPopup} setTrigger={setDataSelectDetailsPopup} resetItems={resetItems} list={list} setList={setList} setFolderData={props.setFolderData} folderData={props.folderData}/>
        </>
    );
};

export default DataSelectPopup;