//Library imports
import React, { useEffect } from "react";
import useStore from "./store";
import { shallow } from "zustand/shallow";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

//Component Imports
import { postCrendentials } from "./AxiosList";

//CSS and Image Imports
import "../SCSS_Files/LoginStyle.scss";
import senstrioLogoBw from "../Icons/GeneralIcons/senstrioAILogoBw.png";
import senstrioLogoBwTest from "../Icons/GeneralIcons/senstrioAILogoBwTest.png";

const LOGO_SRC =
  process.env.REACT_APP_PREVIEW === "True"
    ? senstrioLogoBwTest
    : senstrioLogoBw;

//Login Page for users to login to senstrio AI
/* 
    Should allow users to login into senstrio AI
    Should allow users to to verify username and passord issued by company 
    Should inform users if page login unsuccessful
*/
const Login = () => {
  //Access Store for notification
  const { setNotification } = useStore(
    (state) => ({
      setNotification: state.setNotification,
    }),
    shallow
  );

  //Use Form State for verification
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //React Router State for navigation
  const navigate = useNavigate();

  //In the case of logged in user, navigate to model selection page
  useEffect(() => {
    if (
      sessionStorage.getItem("userId") != undefined &&
      sessionStorage.getItem("userId") != "undefined"
    ) {
      navigate("/dataarchive");
    }
  }, []);

  //Upon Submit Change Page and Open Navbar/Header
  const onSubmit = (credentials) => {
    postCrendentials(credentials, "/dataarchive", navigate, setNotification);
  };

  return (
    <>
      <div className="login-page">
        <div className="overlay"></div>
        <div className="login-content">
          <section className="slogan-section">
            <h1 className="main-header">
              <span> Start Sensing </span>
              <span>Beyond the</span>
              <span> Limit</span>
            </h1>
            <h3 className="stretch">Upload. Process. Discover</h3>
            <h3>Gain insightful results from your Spectroscopy Data</h3>
            <img src={LOGO_SRC} alt="" />
          </section>
          <section className="login-card">
            {/* Login Card */}
            <form className="login-card-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="login-header">
                <h1 className="title">Welcome Back</h1>
                <div className="subtitle">
                  Welcome back to SenstrioAI. Please login in with your personal
                  account credentials
                </div>
              </div>
              <div className="username particulars">
                <label htmlFor="">Username</label>
                <input
                  type="text"
                  name="useNname"
                  placeholder="Type Your Username"
                  autoComplete="off"
                  {...register("userName", { required: true })}
                />
                {errors.userName && errors.userName.type == "required" && (
                  <p className="form-error">Please key in your username</p>
                )}
              </div>
              <div className="password particulars">
                <label htmlFor="">Password</label>
                <input
                  type="password"
                  name="password"
                  placeholder="Type Your Password"
                  autoComplete="off"
                  {...register("password", { required: true })}
                />
                {errors.password && errors.password.type == "required" && (
                  <p className="form-error">Please key in your password</p>
                )}
              </div>
              {/* <div className="password-management">
                                <div className="remember-me">
                                    <input type="checkbox" name='remember'/>
                                    <label htmlFor="remember">Remember Me</label>
                                </div>
                                <div className="forgot">Forgot Password?</div>
                            </div> */}
              <button className="login-btn">Login</button>
            </form>
          </section>
        </div>
      </div>
    </>
  );
};

export default Login;
