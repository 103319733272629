// Empty file check
const emptyFile = (file, activeStep, selectedActiveStep) => {
    if (activeStep == selectedActiveStep && file.length == 0) {
        return true
    }
    return false
}

// Sub listing for list comparison
const subListing =  (list1, list2, activeStep, selectedActiveStep) => {
    // console.log(list1, list2)
    const isEqual = (list1.length === list2.length) && (list1.every(val => list2.includes(val)))
    // console.log(list1, list2, activeStep, isEqual)
    if (activeStep == selectedActiveStep && !isEqual) {
        return true
    }
    return false
}

export {emptyFile, subListing}