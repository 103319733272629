//Library Imports
import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import useStore from "../store.js";
import { shallow } from 'zustand/shallow'
import { useNavigate } from "react-router-dom";

//Component Imports
import DataSelectPopup from './dataSelectPopup';
import DeploymentPopup from './dataDeployPopup';
import { getAllProjects, getAllModels } from '../AxiosList';

//CSS and Image Imports 
import loadingGif from '../../Icons/ModelSelectionIcons/model-training-loader.gif'
import projectIcon from '../../Icons/ModelSelectionIcons/project-icon.png'
import '../../SCSS_Files/ModelList.scss'

//Model Selections, Model Entry Component
const ModelEntry = (props) => {
    return (
        <div className="model-entry">
            {/* Model Component */}
            <Model 
                modelName={props.modelName} 
                modelType={props.modelType} 
                createdDate={props.createdDate} 
                creator={props.creator} 
                modelStatus={props.modelStatus} 
                modelAccuracy={props.modelAccuracy} 
                entryCount={props.entryCount}
                confusionMatrixTrainPath = {props.confusionMatrixTrainPath}
                confusionMatrixTestPath = {props.confusionMatrixTestPath}  
                setHeatmapPopup={props.setHeatmapPopup}
                setHeatmapPaths={props.setHeatmapPaths}
            />
            {/* Update Details */}
            <div className='model-update-details'>
                <div className="recent-update">
                    Recent Update <br />
                    <span>{props.updatedDate == null ? ("No update") : (props.updatedDate.split("T")[0])}</span>
                </div>
                <div className="updater-details">
                    By: <br />
                    <span>{props.updater}</span>
                </div>
                <div className={"model-status " + props.modelStatus}>
                    Model: {props.modelStatus} <i className='bx bx-trash-alt'></i>
                </div>
            </div>
        </div>
    );
};

//Model Component
const Model = (props) => {
    let pageNumber = 0;
    let selectionList = [];
    const [accuracyPage, setAccuracyPage] = useState(0);
    const keys = Object.keys(props.modelAccuracy);

    //Create Model Performance Pages for each performance metric (Accuracy/Precision/Recall/F1)
    //Attatch Heatmap and entry count as well
    for (let i = 0; i < Object.keys(props.modelAccuracy).length; i += 2) {
        selectionList.push(<ModelPerformance 
            accuracyPage={accuracyPage} 
            accuracydataitem={[props.modelAccuracy[keys[i]], 
            props.modelAccuracy[keys[i + 1]]]} 
            number={pageNumber} 
            entryCount={props.entryCount} 
            confusionMatrixTrainPath={props.confusionMatrixTrainPath}
            confusionMatrixTestPath={props.confusionMatrixTestPath}
            setHeatmapPopup={props.setHeatmapPopup}
            setHeatmapPaths={props.setHeatmapPaths}
        />);
        pageNumber += 1;
    }

    return (
        <div className={'model ' + props.modelStatus}>
            <div className="model-information model-details">
                <div className="model-information-name">
                    {props.modelName}
                </div>
                <div className="model-information-type">
                    {props.modelType}
                </div>
                <div className="model-information-created-date">
                    Created Date: <span>{props.createdDate.split("T")[0]}</span>
                </div>
                <div className="model-information-creator">
                    By: <span>{props.creator}</span>
                </div>
            </div>
            <div className="overall-performance model-details">
                {/* Depending on the Model status, the element returned is different. */}
                {(() => {
                    if (props.modelStatus === "TRAINING") {
                        return (
                            <>
                                <div className='no-results'>
                                    <div className='no-results-title'>Model Still Training...</div><img src={loadingGif} className='model-loader-gif' alt="" />
                                </div>
                            </>
                        );
                    }else if (props.modelStatus === "UNTRAINED") {
                        return (
                            <>
                                <div className='no-results'>
                                    <div className='no-results-title'>Model Not Trained</div>
                                </div>
                            </>
                        );
                    }else {
                        //In the case of Trained, set up a page for metric tabs
                        return (
                            <>
                                <div className="performance-types">
                                    <button onClick={() => setAccuracyPage(0)}>Accuracy</button>
                                    <button onClick={() => setAccuracyPage(1)}>Precision</button>
                                    <button onClick={() => setAccuracyPage(2)}>Recall</button>
                                    <button onClick={() => setAccuracyPage(3)}>F1</button>
                                </div>
                                {selectionList}
                            </>
                        );
                    };
                })()}
            </div>
            {/* Status Box for model status color coding */}
            <div className={'status-box ' + props.modelStatus}>
            </div>
        </div>
    );
};

//Model Performance Tab
const ModelPerformance = ({ accuracyPage, accuracydataitem, number, entryCount,  confusionMatrixTrainPath, confusionMatrixTestPath, setHeatmapPopup, setHeatmapPaths}) => {
    let train = accuracydataitem[0] + '%';
    let test = accuracydataitem[1] + '%';
    return (
        <div className={accuracyPage === number ? ('performance-details') : ('performance-details inactive')}>
            <div className="accuracy-bars">
                <div className="accuracy-bar">
                    <div className="datapart">Train</div>
                    <div className="bar">
                        <div className="bar-fill" style={{ width: train }}></div>
                    </div>
                    <div className="accuracy-value">{train}</div>
                </div>
                <div className="accuracy-bar">
                    <div className="datapart">Test</div>
                    <div className="bar">
                        <div className="bar-fill" style={{ width: test }}></div>
                    </div>
                    <div className="accuracy-value">{test}</div>
                </div>
            </div>
            <div className='model-performance-viewing'>
                <div className='entry-size'>Data Entries: <span>{entryCount}</span></div>           
                {/* Heat Map Paths to show performance */}
                <button className="show-heatmap" onClick={() => {
                    setHeatmapPopup(true);
                    setHeatmapPaths([confusionMatrixTrainPath, confusionMatrixTestPath]);
                }}>Heatmap</button>
            </div>
        </div>
    );
};

//Main Model Selection Page 
const ModelSelect = () => {

    const {setNavBar, setHeaderOpened,} = useStore(
        (state) => ({
            setNavBar: state.setNavBar,
            setHeaderOpened: state.setHeaderOpened
        }),
        shallow
    );
   
    const navigate = useNavigate();

    //Model Selection Section
    const [models, setModels] = useState([]);
    
    //Project SideBar
    const [projectBar, setProjectBar] = useState(false);
    const [project_data, setProjectData] = useState([{
        "projectName": "",
        "count": "",
        "description": "",
        "dateOfCreation": "",
        "creator": ""
    }]);
    const userId = sessionStorage.getItem('userId');

    //States for Data Select and Deployment Popup
    const [dataSelectPopup, setDataSelectPopup] = useState(false);
    const [deploymentPopup, setDeploymentPopup] = useState(false);

    //States for Heatmaps and heatmap paths
    const [heatmapPaths, setHeatmapPaths] = useState([null, null]);
    const [heatmapPopup, setHeatmapPopup] = useState(false);

    //Get project details, get all models, open the nav and header since this is landing page right after login page
    useEffect(() => {
        if (userId == 'undefined') {
            navigate('/');
        }
        getAllProjects(setProjectData, userId);
        getAllModels(setModels, userId);
        setNavBar(true);
        setHeaderOpened(true);
    }, []);

    return (
        <>
            <Helmet>
                <title>Model Selector</title>
                <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet' />
            </Helmet>
            <main className={projectBar?('main-content model-storage-page project-bar'):('main-content model-storage-page')}>
                {/* Model Selection */}
                <div className="model-selection">
                    <div className='model-selection-title'>
                        <div className='camel'>
                            Project <i className='bx bx-chevron-right'></i>{project_data[0].projectName}
                        </div>
                        <div className='project-title'>
                            Project: {project_data[0].projectName}                       
                            <button className="deploy-btn" onClick={() => setDeploymentPopup(true)}>Deploy Model</button>
                        </div>
                    </div>
                    <div className="model-display">
                        <div className="model-display-header">
                            <div className="model-display-header-columns">
                                <div className="model-display-header-column">Model Information</div>
                                <div className="model-display-header-column">Overall Performance</div>
                                <div className="model-display-header-column">Process Status</div>
                            </div>
                            <hr className="model-display-header-divider" />
                        </div>
                    </div>
                    <div className='model-storage'>
                        {/* Map out each model with their corresponding details */}
                        {models.map((model) => {
                            return (
                                <ModelEntry
                                    entryCount={model.entryCount}
                                    modelName={model.modelName}
                                    modelType={model.modelType}
                                    createdDate={model.createdDate}
                                    creator={model.creator}
                                    updatedDate={model.updatedDate}
                                    updater={model.updater}
                                    modelStatus={model.modelStatus}
                                    modelAccuracy={model.modelAccuracy} 
                                    confusionMatrixTestPath = {model.confusionMatrixTestPath}
                                    confusionMatrixTrainPath = {model.confusionMatrixTrainPath}
                                    setHeatmapPopup={setHeatmapPopup}
                                    setHeatmapPaths={setHeatmapPaths}
                                    
                                />
                            )
                        })}
                    </div>
                </div>

                {/* Project Bar Button */}
                <div className="project-bar-btn" onClick={()=> setProjectBar(true)}>
                    <img src={projectIcon} alt="" />
                    Project Details
                </div>

                {/* Project SideBar */}
                <div className="project-sidebar">
                    <div className="project-sidebar-title" onClick={() => setProjectBar(false)}>
                        <i className='bx bx-chevron-right'></i>
                        <span>Project Details </span>
                    </div>
                    <div className="count">
                        Model Count <span>{project_data[0].count} Models</span>
                    </div>
                    <div className="description">
                        <div className="description-title">
                            Project Description
                        </div>
                        <div className="description-content">
                            {project_data[0].description}
                        </div>
                        <div className='other-details'>
                            <div className="project-date-of-creation">
                                Date of Creation <br /><span>{project_data[0].dateOfCreation.split('T')[0]}</span>
                            </div>
                            <div className="project-creator">
                                by: <span>{project_data[0].creator}</span>
                            </div>
                        </div>

                    </div>
                </div>
    
                {/* Button to create Model */}
                <button className='create-btn' onClick={() => setDataSelectPopup(true)}>
                    <i className='bx bx-plus'></i>
                    <div className="tooltip">
                        Create a Model
                    </div>
                </button>
            </main>
            {/* Data Select Popup */}
            <DataSelectPopup trigger={dataSelectPopup} setTrigger={setDataSelectPopup}/>

            {/* Deployment Pop up */}
            <DeploymentPopup trigger={deploymentPopup} setTrigger={setDeploymentPopup} models={models}/>
            
            {/* Heatmap Pop up */}
            <div className={(heatmapPopup)?('heatmap-popup'):('heatmap-popup inactive')}>
                {/* Open the Pop Up */}
                <div onClick={() => setHeatmapPopup(false)}><i className='bx bx-x'></i></div>

                {/* Heat Map Path is set Differently Based on model clicked */}
                <div className='heatmaps'>
                    <img className='heatmap' src={heatmapPaths[1]} alt="" />
                    <img className='heatmap' src={heatmapPaths[0]} alt="" />
                </div>
            </div>
        </>
    );
};

export default ModelSelect;