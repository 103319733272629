//Library Imports
import React from "react";

//Required for @emotion/react to work on ES6 functional JSX
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import useStore from "../store.js";
import {shallow} from 'zustand/shallow';


//Progressive Step Tracker
/* 
    Should display steps that the user can conduct
    Should help user navigate things like a carousel
    Should map the options user has given the step tracker
    Parameters -----
    steps --> Array of objects {label: String, slide: Element, container_css: css statement from emotion/react}
    activeStep --> State (contains String)
    setActiveStep --> State Action for activeStep
*/
const ProgressSteps = (props) => {

    const {  setNotification } = useStore(state => ({
        setNotification: state.setNotification,
    }), shallow);

    const totalSteps = props.steps.length
    
    //Measures width of the progress Bar
    const width = `${(100 / (totalSteps - 1)) * (props.activeStep - 1)}%`

    //Next Step Button
    const nextStep = () => {

        //Generalised Conditioning
        if (typeof props.steps[props.activeStep-1].conditionalCheck == 'function' && props.steps[props.activeStep-1].conditionalCheck(...props.steps[props.activeStep-1].conditionalCheckParameters)) {
            setNotification(...props.steps[props.activeStep-1].conditionalCheckError)
            return
        }
    

        props.setActiveStep(props.activeStep + 1)
    }
  
    //Previous Step Button
    const prevStep = () => {
        props.setActiveStep(props.activeStep - 1)
    }

    // console.log(props.activeStep, totalSteps)
    return (
        <div className={props.activeStep > totalSteps?("main-step-container"):("main-step-container active")} css={props.steps[props.activeStep - 1]?(props.steps[props.activeStep - 1].container_css):('')}>
            <i className='bx bx-x' onClick={() => props.setUploadPopup(false)}></i>

            {/* emotion css direct editing with variables */}
            <div className="step-container"
                css={css`&::after{
                    width: ${ width };
                }`}
            >   
                {/* Steps are mapped with a tick and step count as well as label and respective design classes */}
                {props.steps.map((step, stepIndex) => (
                    <div className='step-wrapper' key={stepIndex}>
                        <div className={props.activeStep >= stepIndex + 1 ? 'step-style completed' : 'step-style'}>
                            {props.activeStep > stepIndex + 1 ? (
                                <div className="checkmark">L</div>
                            ):(
                                <div className="step-count">{stepIndex + 1}</div>
                            )}
                        </div>
                        <div className="step-label-container">
                            <div className="step-label">{step.label}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="buttons-container">
                <button className="buttons-style" onClick={prevStep} disabled={props.activeStep === 1 || props.deactivatePrevButton}><i className='bx bx-chevron-left' ></i></button>
                <button className="buttons-style" onClick={nextStep} disabled={props.activeStep === totalSteps || props.deactivateNextButton}><i className='bx bx-chevron-right'></i></button>
            </div>
        </div>
    );
};

export {ProgressSteps}