//Library Imports
import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form"
import useStore from "../store";
import { shallow } from "zustand/shallow";

//Component Imports
import { useOutsideAlerter, specialCharCheck, passwordCheck, getBase64 } from "../GeneralFunctions";
import { createExportSet, checkCredentials, updatePassword, addUser } from "../AxiosList";
import { ProfilePictureEditor } from "../UserManagementComponents/ProfileViewer";

//CSS and Images
import '../../SCSS_Files/DetailInputStyle.scss'


// Pop up to handler zipping requests
/* 
    Parameters---------------------
    setNotification --> State management to send notifictaions
    changePrevFunction --> Function that happens to click next slide (optional)
    changeNextFunction --> Function that happens to click prev slide (optional)
    data --> Main data handling
    closeSlide --> Function that happens when closing the slide
*/
const ZipDetailInput = (props) => {

    // State for selected data types
    const [selectedDataType, setSelectedDataType] = useState('power_law')

    // State for selection box activeness
    const [selectDataTypeBoxActive, setSelectDataTypeBoxActive] = useState(false)
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setSelectDataTypeBoxActive(false))

    // Current options 
    const dataOptions = ['power_law', 'smooth', 'power_coefficient', 'peaks']

    //  Use Form State for verification
    const { register, reset, handleSubmit, formState: { errors } } = useForm();

    // Zip Submission Event
    const submitZip = (data) => {

        // props.setLoadingActiveness(true)
        // Zip API
        createExportSet(
            data.fileName,
            selectedDataType,
            '',
            props.data,
            props.setNotification,
            props.closeSlide,
            props.setLoadingActiveness
        )

        // Reset form 
        setSelectedDataType('power_law')
        reset()
    }


    
    return (
        <div className="detail-popup">
            {/* Step Button that exists in the case of a next slide */}
            {
                props.changePrevFunction &&      
                <div className="prev-button step-button" onClick={() => props.changePrevFunction()}>
                <i className='bx bxs-chevron-left' ></i>                
                </div>
            }
            
            {/* Form to key in zip details and submit */}
            <form className="detail-popup-content" onSubmit={handleSubmit(submitZip)}>
                <i className='bx bx-x close-popup' onClick={() => {
                    props.closeSlide()
                    setSelectedDataType('power_law')
                    reset()

                }}></i>
                <h1 className="detail-title">Zip File Details</h1>
                <h3 className="detail-subtitle">Please provide the following details</h3>
                <label htmlFor="name">
                    Zip File Name
                </label>
                <input type="text" className="name" name='fileName' placeholder="Key In Zip Name..."  {...register("fileName", { required: true, validate: specialCharCheck})}/>
                {errors.fileName && (errors.fileName.type == 'required' && <p className='form-error'>Value is Requried</p>)}
                {errors.fileName && (errors.fileName.type == 'validate' && <p className='form-error'>No Special Charcters Except for ',' and '.' Allowed</p>)}
                <label htmlFor="data-type">
                    Data Type
                </label>
                <div className="select-data-type-wrapper" ref={wrapperRef}>
                    <button className="select-data-type-button" onClick={() => setSelectDataTypeBoxActive(!selectDataTypeBoxActive)} type="button">
                        {selectedDataType} <i className='bx bxs-chevron-down' ></i>
                    </button>
                    {/* Maps all possibkle selectable data type */}
                    <ul className={selectDataTypeBoxActive?"select-data-type-container":"select-data-type-container inactive"}>
                        {
                            dataOptions.map((option) => {
                                return (
                                    <li onClick={() => {
                                        setSelectedDataType(option)
                                        setSelectDataTypeBoxActive(false)
                                    }}><i className={selectedDataType == option? 'bx bx-check' : 'bx bx-check inactive'}></i> {option}</li>
                                )
                            })
                        }
                    </ul>
                </div>
                <button className="submit" type="submit">
                    + Create Zip
                </button>
            </form>
            {/* Step Button that exists in the case of a prev slide */}
            {
                props.changeNextFunction &&      
                <div className="next-button step-button" onClick={() => props.changeNextFunction()}>
                <i className='bx bxs-chevron-right' ></i>
                </div>
            }

        </div>
    )
}


// Pop up to handler handle password verification
/* 
    Parameters---------------------
    setNotification --> State management that lauches notifications
    axiosAfterEvent --> Event after axios submission 
    closeSlide --> Event when closing the slide
*/
const PasswordVerificationInput = (props) => {
    //  Use Form State for verification
    const { register, reset, handleSubmit, getValues, formState: { errors } } = useForm();
    
    // Verification handler
    const verify = (data) => {
        checkCredentials(
            sessionStorage.getItem('userName'),
            data.oldPassword,
            props.setNotification,
            props.axiosAfterEvent
        )

        reset()
    }

    return (
        <div className="detail-popup">
            {/* Step Button that exists in the case of a next slide */}
            {
                props.changePrevFunction &&      
                <div className="prev-button step-button" onClick={() => props.changePrevFunction()}>
                <i className='bx bxs-chevron-left' ></i>                
                </div>
            }
            {/* Form to key in password */}
            <form className="detail-popup-content" onSubmit={handleSubmit(verify)}>
                <i className='bx bx-x close-popup' onClick={() => {
                    props.closeSlide()
                    reset()
                }}></i>
                <h1 className="detail-title">Password Validation</h1>
                <h3 className="detail-subtitle">Please key in your old password for validation</h3>
                <label htmlFor="name">
                    Current Password
                </label>
                <input type="password" className="name" name='' placeholder="Key In old Password..."  {...register("oldPassword", { required: true})}/>
                {errors.oldPassword && (errors.oldPassword.type == 'required' && <p className='form-error'>Value is Requried</p>)}
           
                <button className="submit" type="submit">
                    Validate
                </button>
            </form>

            {/* Step Button that exists in the case of a prev slide */}
            {
                props.changeNextFunction &&      
                <div className="next-button step-button" onClick={() => props.changeNextFunction()}>
                <i className='bx bxs-chevron-right' ></i>
                </div>
            }

        </div>
    )
}


// Pop up to handler handle password changing
/* 
    Parameters---------------------
    setNotification --> State management that lauches notifications
    axiosAfterEvent --> Event after axios submission 
    closeSlide --> Event when closing the slide
*/
const PasswordChangeInput = (props) => {

    //  Use Form State for verification
    const { register, reset, handleSubmit, getValues, formState: { errors } } = useForm();
    
    // Password update submission
    const setPassword = (data) => {
        updatePassword(
            sessionStorage.getItem('userId'),
            props.oldPassword,
            data.newPassword,
            props.setNotification
        )

        reset()
        props.closeSlide()

    }

    return (
        <div className="detail-popup">
            {/* Step Button that exists in the case of a next slide */}
            {
                props.changePrevFunction &&      
                <div className="prev-button step-button" onClick={() => props.changePrevFunction()}>
                <i className='bx bxs-chevron-left' ></i>                
                </div>
            }

            {/* Form Requesing for new and confirm password */}
            <form className="detail-popup-content" onSubmit={handleSubmit(setPassword)}>
                <i className='bx bx-x close-popup' onClick={() => {
                    props.closeSlide()
                    reset()
                }}></i>
                <h1 className="detail-title">Password Change</h1>
                <h3 className="detail-subtitle">Please key in your new password and confirm it</h3>
                <label htmlFor="name">
                    New Password
                </label>
                <input type="password" className="name" name='' placeholder="Key In New Password..."  {...register("newPassword", { required: true, validate: passwordCheck, maxLength: 20, minLength: 8})}/>
                {errors.newPassword && (errors.newPassword.type == 'required' && <p className='form-error'>Value is Requried</p>)}
                {errors.newPassword && (errors.newPassword.type == 'validate' && <p className='form-error'>Must have at least 1 special char, 1 digit, 1 capital and 1 lower letter</p>)}
                {errors.newPassword && (errors.newPassword.type == 'maxLength' && <p className='form-error'>Password must be between 8 to 20 characters</p>)}
                {errors.newPassword && (errors.newPassword.type == 'minLength' && <p className='form-error'>Password must be between 8 to 20 characters</p>)}

                <label htmlFor="name">
                    Confirm Password
                </label>
                <input type="password" className="name" name='confirmPassword' placeholder="Confirm Password..."  {...register("confirmPassword", { required: true,  validate: (value) => {
                    const { newPassword } = getValues();
                    return newPassword == value;
                }})}/>
                {errors.confirmPassword && (errors.confirmPassword.type == 'required' && <p className='form-error'>Value is Requried</p>)}
                {errors.confirmPassword && (errors.confirmPassword.type == 'validate' && <p className='form-error'>Passwords should match!</p>)}

                <button className="submit" type="submit">
                    Save
                </button>
            </form>

            {/* Step Button that exists in the case of a prev slide */}
            {
                props.changeNextFunction &&      
                <div className="next-button step-button" onClick={() => props.changeNextFunction()}>
                <i className='bx bxs-chevron-right' ></i>
                </div>
            }

        </div>
    )
}



//Pop up handler to create a new user
/*
    Parameters --------------------
    setNotification --> State management that lauches notifications
    axiosAfterEvent --> Event after axios submission 
    closeSlide --> Event when closing the slide
*/
const CreateUser = (props) => {

    //  Use Form State for verification
    const { register, reset, handleSubmit, getValues, formState: { errors } } = useForm();

    // Image Change
    const [newImage, setNewImage] = useState(undefined)
    
    //Passord response message
    const [passwordResponse, setPasswordResponse] = useState(false)

    //Access Store for loading page
    const { setLoadingActiveness } = useStore(
        (state) => ({
            setLoadingActiveness: state.setLoadingActiveness
        }),
        shallow
    );
    
    // Create new user event
    const CreateUser = async (data) => {

        if (newImage instanceof File) {
            setLoadingActiveness(true)

            // Convert to base 64
            let convertedImage = await getBase64(newImage)
            
            // Add user API
            addUser(
                sessionStorage.getItem('userId'),
                data,
                convertedImage,
                props.setNotification,
                () => {
                    props.axiosAfterEffect()
                    setNewImage(undefined)
                    reset()
                    setLoadingActiveness(false)
                },
                setPasswordResponse,
                setLoadingActiveness,
            )

            return
        }

        props.setNotification('Profile Pic Required', 'Profile Picture is required', 'error')
    }

    return (
        <div className="detail-popup">
            {/* Step Button that exists in the case of a next slide */}
            {
                props.changePrevFunction &&      
                <div className="prev-button step-button" onClick={() => props.changePrevFunction()}>
                <i className='bx bxs-chevron-left' ></i>                
                </div>
            }

            {/* User Creation form */}
            <form className={passwordResponse?"detail-popup-content inactive":"detail-popup-content" }onSubmit={handleSubmit(CreateUser)}>
                <i className='bx bx-x close-popup' onClick={() => {
                    setPasswordResponse(false)
                    props.closeSlide()
                    reset()
                }}></i>
                <h1 className="detail-title">Create New User</h1>
                <h3 className="detail-subtitle">Please Add in the following details to create a new user</h3>
                <label htmlFor="image" className='image-label'>
                    Add User Image
                </label>
                {/* Profile Pic Editor */}
                <ProfilePictureEditor setNewImage={setNewImage}/>
                <label htmlFor="name">
                    Username
                </label>
                <input type="text" className="name" placeholder="Username"  {...register("userName", { required: true, maxLength: 20, minLength: 8})}/>
                {errors.userName && (errors.userName.type == 'required' && <p className='form-error'>Value is Requried</p>)}
                {errors.userName && (errors.userName.type == 'maxLength' && <p className='form-error'>Username must be between 8 to 20 characters</p>)}
                {errors.userName && (errors.userName.type == 'minLength' && <p className='form-error'>Username must be between 8 to 20 characters</p>)}

                <label htmlFor="name">
                    Email
                </label>
                <input type="email" className="name" name='email' placeholder="Email"  {...register("email", { required: true})}/>
                {errors.email && (errors.email.type == 'required' && <p className='form-error'>Value is Requried</p>)}

                <label htmlFor="name">
                    First Name
                </label>
                <input type="text" className="name" name='firstName' placeholder="First Name"  {...register("firstName", { required: true})}/>
                {errors.firstName && (errors.firstName.type == 'required' && <p className='form-error'>Value is Requried</p>)}

                <label htmlFor="name">
                    Last Name
                </label>
                <input type="text" className="name" name='lastName' placeholder="Last Name"  {...register("lastName", { required: true})}/>
                {errors.lastName && (errors.lastName.type == 'required' && <p className='form-error'>Value is Requried</p>)}

                <button className="submit" type="submit">
                    Create New User
                </button>
            </form>
            <div className={passwordResponse?"detail-popup-content":"detail-popup-content inactive" }>
                <i className='bx bx-x close-popup' onClick={() => {
                    setPasswordResponse(false)
                    props.closeSlide()
                    reset()
                }}></i>
                <h1 className="detail-title">Account has been created </h1>
                <h3 className="detail-subtitle">{passwordResponse}</h3>
            </div>

            {/* Step Button that exists in the case of a prev slide */}
            {
                props.changeNextFunction &&      
                <div className="next-button step-button" onClick={() => props.changeNextFunction()}>
                <i className='bx bxs-chevron-right' ></i>
                </div>
            }

        </div>
    )
}


export { ZipDetailInput, PasswordChangeInput, PasswordVerificationInput, CreateUser }