import React from 'react';
import useStore from '../store';
import { shallow } from 'zustand/shallow'
import {model_builder_loading_info } from './ToolsAndInformation';


const NodeBarItems = ({idProperty, data, nodeType, classNameProperty, nodeBarSection, setNodeBarSection, nodeBarButton, setNodeBarButton}) => {

    //Toggles the Node Bar
    const OpenNodeTab = (e) => {
        setNodeBarSection(!nodeBarSection)
        setNodeBarButton(!nodeBarButton)
    }   

    //Drag event launch
    const onDragStart = (event, node, nodeType) => {
        event.dataTransfer.setData('text', JSON.stringify(node));
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move'
    }

    let node_group = 0
    let current_group = ''

    return(
        <div className={classNameProperty} id={idProperty} onClick={(e)=>OpenNodeTab(e)}> 
            <div className='node-selection-label'>
                <i className='bx bx-chevron-left'></i>
                <span>Data Process Nodes</span>
            </div>

            {data.map((node) => {
                if  (node.modelBuildingNodeCategoryModel.nodeCategoryName !== current_group) {
                    current_group = node.modelBuildingNodeCategoryModel.nodeCategoryName 
                    node_group = 0
                } 
                if(node_group == 0) {
                    node_group ++
                
                    return(
                        <div className='node-group'>
                            <div className="node-group-label">
                                {current_group}
                            </div>
                            <div className={"dndnode " + nodeType} onDragStart={(event) => onDragStart(event, node, 'processnode')} draggable>
                                <div className="content" >
                                    <div className="dndhandles-left"> </div>
                                    <div className="dndhandles-right"> </div>
                                    <div className="label">{node.nodeName}</div>            
                                </div>
                            </div>
                        </div>

                    )
                }else{
                    return(
                        <div className='node-group'>
                            <div className={"dndnode " + nodeType} onDragStart={(event) => onDragStart(event, node, 'processnode')} draggable>
                                <div className="content" >
                                    <div className="dndhandles-left"> </div>
                                    <div className="dndhandles-right"> </div>
                                    <div className="label">{node.nodeName}</div>            
                                </div>
                            </div>
                        </div>
                    )
                }

            })}
        </div>
    );
}




// Node Bar --------------------------------------------------------------------------------------------------------------
const NodeBar = ({nodeInformation}) => {

    const { nodeBarTab, nodeBarSection, setNodeBarTab, setNodeBarSection, setNodeBarButton, nodeBarButton } = useStore(
        (state) => ({ 
            nodeBarTab: state.nodeBarTab, 
            nodeBarSection: state.nodeBarSection,
            setNodeBarTab: state.setNodeBarTab, 
            setNodeBarSection: state.setNodeBarSection, 
            setNodeBarButton: state.setNodeBarButton,
            nodeBarButton: state.nodeBarButton
        }),
        shallow
    )

    //Swap Tabs for Node Bar
    const swapTab = (e) => {
        var radioValue = e.target.value;
        if (nodeBarTab === radioValue) {
            return
        }

        if(radioValue === 'atomic') {
            setNodeBarTab('atomic')
            return
        }
        if(radioValue === 'preset') {
            setNodeBarTab('preset')
            return
        }
    };

    //Toggles the Node Bar
    const OpenNodeTab = (e) => {
        setNodeBarSection(!nodeBarSection)
        setNodeBarButton(!nodeBarButton)
    }
    


    return (
        <>
            <button className={nodeBarButton?('node-bar-button open'):('node-bar-button closed')} onClick={(e)=>OpenNodeTab(e)}>
                <i className='bx bx-shape-circle' ></i>
                <div className='tooltip'>Open Node Bar</div>
            </button>
            <aside className={nodeBarSection?'node-bar open':'node-bar closed'}>
                <label id="atomic-process" className={nodeBarTab==='atomic'? 'process-tabs active' : 'process-tabs'}>
                    <div className="node-bar-background"></div>
                    <input type="radio" value='atomic' name='radio' className='node-bar-checkbox' onClick={(e) => swapTab(e)}/>
                    <span className="checkmark"></span>
                    <span className='node-bar-label'>Atomic</span>
                </ label >
                
                <label id="preset-process" className={nodeBarTab==='preset'? 'process-tabs active' : 'process-tabs'}>
                    <div className="node-bar-background"></div>
                    <input type="radio" value='preset' name='radio' className='node-bar-checkbox' onClick={(e) => swapTab(e)}/>
                    <span className="checkmark"></span>
                    <span className='node-bar-label'>Preset</span>
                </ label >


                <NodeBarItems 
                    idProperty='atomic-bar-items' 
                    data={nodeInformation} 
                    nodeType='atomic-node' 
                    classNameProperty={nodeBarTab ==='atomic'? 'node-selection active' : 'node-selection'}
                    nodeBarSection = {nodeBarSection}
                    setNodeBarSection = {setNodeBarSection}
                    nodeBarButton = {nodeBarButton}
                    setNodeBarButton = {setNodeBarButton}
                />
                <NodeBarItems 
                    idProperty='preset-bar-items' 
                    data={[]} 
                    nodeType='preset-node' 
                    classNameProperty={nodeBarTab==='preset'? 'node-selection active' : 'node-selection'}
                    nodeBarSection = {nodeBarSection}
                    setNodeBarSection = {setNodeBarSection}
                    nodeBarButton = {nodeBarButton}
                    setNodeBarButton = {setNodeBarButton}
                />
            </aside>
        </>
    )
}


const DLBarItems = ({idProperty, data, nodeType, classNameProperty}) => {
    //Drag event launch
    const onDragStart = (event, node, nodeType) => {
        event.dataTransfer.setData('text', JSON.stringify(node));
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move'
    }

    let node_group = 0
    let current_group = ''

    return(
        <div className={classNameProperty} id={idProperty}> 

            {data.map((node) => {
                if  (node.modelBuildingNodeCategoryModel.nodeCategoryName !== current_group) {
                    current_group = node.modelBuildingNodeCategoryModel.nodeCategoryName 
                    node_group = 0
                } 
                if(node_group == 0) {
                    node_group ++
                
                    return(
                        <div className='node-group'>
                            <div className="node-group-label">
                                {current_group}
                            </div>
                            <div className={"dndnode " + nodeType} onDragStart={(event) => onDragStart(event, node, 'processnode')} draggable>
                                <div className="content" >
                                    <div className="dndhandles-left"> </div>
                                    <div className="dndhandles-right"> </div>
                                    <div className="label">{node.nodeName}</div>            
                                </div>
                            </div>
                        </div>

                    )
                }else{
                    return(
                        <div className='node-group'>
                            <div className={"dndnode " + nodeType} onDragStart={(event) => onDragStart(event, node, 'processnode')} draggable>
                                <div className="content" >
                                    <div className="dndhandles-left"> </div>
                                    <div className="dndhandles-right"> </div>
                                    <div className="label">{node.nodeName}</div>            
                                </div>
                            </div>
                        </div>
                    )
                }

            })}
        </div>
    );
}





// DL Bar --------------------------------------------------------------------------------------------------------------
const DLBar = ({nodeInformation}) => {

    const { nodeBarTab, nodeBarSection, setNodeBarTab, setNodeBarSection, setNodeBarButton, nodeBarButton } = useStore(
        (state) => ({ 
            nodeBarTab: state.nodeBarTab, 
            nodeBarSection: state.nodeBarSection,
            setNodeBarTab: state.setNodeBarTab, 
            setNodeBarSection: state.setNodeBarSection, 
            setNodeBarButton: state.setNodeBarButton,
            nodeBarButton: state.nodeBarButton
        }),
        shallow
    );

    //Swap Tabs for Node Bar
    const swapTab = (e) => {
        var radioValue = e.target.value;
        if (nodeBarTab === radioValue) {
            return
        };

        if(radioValue === 'atomic') {
            setNodeBarTab('atomic')
            return
        };
        if(radioValue === 'preset') {
            setNodeBarTab('preset')
            return
        };
    };

    //Toggles the Node Bar
    const OpenNodeTab = (e) => {
        setNodeBarSection(!nodeBarSection);
        setNodeBarButton(!nodeBarButton);
    };

    return (
        <>
            <aside className='node-bar open'>
                <label id="atomic-process" className={nodeBarTab==='atomic'? 'process-tabs active' : 'process-tabs'}>
                    <div className="node-bar-background"></div>
                    <input type="radio" value='atomic' name='radio' className='node-bar-checkbox' onClick={(e) => swapTab(e)}/>
                    <span className="checkmark"></span>
                    <span className='node-bar-label'>Atomic</span>
                </ label >
                
                <label id="preset-process" className={nodeBarTab==='preset'? 'process-tabs active' : 'process-tabs'}>
                    <div className="node-bar-background"></div>
                    <input type="radio" value='preset' name='radio' className='node-bar-checkbox' onClick={(e) => swapTab(e)}/>
                    <span className="checkmark"></span>
                    <span className='node-bar-label'>Preset</span>
                </ label >


                <DLBarItems 
                    idProperty='atomic-bar-items' 
                    data={nodeInformation} 
                    nodeType='atomic-node' 
                    classNameProperty={nodeBarTab ==='atomic'? 'node-selection active' : 'node-selection'}
                />
                <DLBarItems idProperty='preset-bar-items' data={[]} nodeType='preset-node' classNameProperty={nodeBarTab==='preset'? 'node-selection active' : 'node-selection'}/>
            </aside>
        </>
    );
};

export {NodeBar, DLBar};