import React from 'react';
import '../SCSS_Files/SwitchButton.scss'

//Boolean Switches-----------------------------------------------------------------------------------------------------------------------
/* 
    Parameters -------
    label --> Label to display on the switch
    defaultValue ---> default starting value (ACTIVE --> true else false)
    onChangeEvent --> What to do when the switch is flipped
*/
const SwitchButton = (props) => {

    return (
        <div className='switch-container'>
            <label>{props.label}</label>
            <label className="switch">
                <input type="hidden" value="false" name={props.label} />
                <input type="checkbox" defaultChecked={props.defaultValue == 'ACTIVE' ? (true) : (false)} name={props.label} onChange={props.onChangeEvent} />
                <span className="slider round"></span>
            </label>
        </div>
    );
};



export { SwitchButton }
