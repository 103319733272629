//Library Imports
import React, { useEffect, useState } from "react";
import useStore from "../store.js";
import {shallow} from 'zustand/shallow';
import Calendar from 'moedim';
import moment from "moment/moment.js";
import { useForm } from "react-hook-form";

//Component Imports
import { nullChecker, removeAttribute, getBase64 } from "../GeneralFunctions.js";
import { getDevicesBySystem, getFilteredReferenceFiles, getFilteredUsersByCompany, uploadNewReferenceFile } from '../AxiosList';
import { fileSize } from "./DataFilePage.js";
import { deviceIdReference } from "./UploadPopup.js"
import { idDeviceReference } from "./UploadPopup.js";

//CSS and Image Imports
import loadingGif from "../../Icons/UploadIcons/loading-gif-white.gif"
import uploadImg from '../../Icons/UploadIcons/upload-icon-blue.png'

//Reference file box for uploading of reference files 
/* 
    Should allow users to upload a reference file from their device
    Should display the new uploaded file details
    When user upload a new one there should be an overide, if no file 
    Parameters -----
    setRefFileElement ---> state to set reference element
    refFileElement ----> reference element
*/
const ReferenceFileBox = (props) => {

    // Reference Upload Event to display possible upload 
    const refUploadEvent = (event) => {
        props.setRefFileElement(event.target.files)

    }

    return (
        <div className="ref-upload-file">
            <div className='file-box-container'>
                <div className='file-box'>
                    <img className='upload-icon' src={uploadImg} alt="Upload Logo" />
                    <div className='file-box-subtitle'>
                        Drop files to upload or
                    </div>
                
                    {/* Browse button covers original input for design */}
                    <input className="upload-button" type='file' onChange={refUploadEvent} onClick={e => (e.target.value = null)}/>
                    <span className="file-box-trigger" >Browse</span>
                </div>
            </div>    
            <div className="new-ref-file-details">
                {props.refFileElement?(
                    <div className="ref-file">
                        {props.refFileElement[0].name}, {fileSize(props.refFileElement[0].size)}
                    </div>
                ):(
                    <div className="no-file">
                        No File Uploaded
                    </div>
                )}
            </div>    
        </div>

    );

}


//Reference Upload Box to display reference files available for selection 
/* 
    Should allow users to upload a reference file
    Should allow users to key in valuable details of the reference file 
    Should conduct validation for proper file submission
    Parameters -----
    setReferenceUploadPopup ----> State to trigger reference upload popup
    referenceUploadPopup ---> reference pop up state
    system ----> Particular system for that reference upload
*/
const ReferenceFileUpload = (props) => {

    //Notification state usage 
    const { setNotification } = useStore(state => ({ 
        setNotification: state.setNotification, 
    }), shallow);
    
    const { register, reset, handleSubmit, formState: { errors } } = useForm();
    
    // Collection Date to be used for submission
    const [collectionDate, setCollectionDate] = useState(new Date())  
    
    // Reference Elemnet and device serial otions
    const [refFileElement, setRefFileElement] = useState(null)
    const [deviceSerialOptions, setDeviceSerialOptions] = useState([])
    
    //Reference File Upload Event
    const submitReferenceFile = async(data) => {

        //If there is no reference file detected set notifcation for error display
        if (!refFileElement) {
            setNotification('Reference File Missing', 'No reference file was detected, Please upload one to proceed', 'error')
            return
        }

        //Upload New Reference file once all details are collected and compiled
        uploadNewReferenceFile(
            refFileElement[0]['name'],  
            moment(collectionDate).format('YYYY-MM-DD'), 
            sessionStorage.getItem('userId'), 
            data.deviceSerial, 
            deviceIdReference[props.system], 
            data.window, 
            data.humidity, 
            data.temperature, 
            data.mask, 
            data.packageType, 
            await getBase64(refFileElement[0]), 
            refFileElement[0]['size'], 
            sessionStorage.getItem('companyName')
        ) 

        //Add Close page event as well as reference file submission reaction-----
        setRefFileElement(null)
        reset()

        setNotification('Reference File Successfully Submitted', 'You have succesfully uploaded a reference file', 'success')
        props.setReferenceUploadPopup(false)
        //-----------------------------------------------------------------
    }

    //Retrieves the type of devices available based on the device id and the company the user is from
    useEffect(() => {
        getDevicesBySystem(setDeviceSerialOptions,  sessionStorage.getItem('companyId'), deviceIdReference[props.system])
    }, [props.referenceUploadPopup])

    return(
        <>
            <div className={props.referenceUploadPopup?("ref-selection-popup"):("ref-selection-popup inactive")}>
                <i className='bx bx-x' onClick={() => props.setReferenceUploadPopup(false)}></i>
                <div className="ref-upload-container">
                    <div className="upload-file">
                        <div className="slide-header">
                            <div className="slide-subtitle">Upload a New Reference File</div>
                        </div>
                        {/* Reference file Box to allow users for reference file upload */}
                        <ReferenceFileBox refFileElement={refFileElement} setRefFileElement={setRefFileElement}/>
                    </div>

                    {/* Details and inpiut form for new values */}
                    <form className="new-ref-details" onSubmit={handleSubmit(submitReferenceFile)}>
                        <div className="ref-collection-input-container">
                            <div className="ref-collection-date">
                                <div className="ref-date-of-collection">Date of Collection</div>
                                <Calendar value={collectionDate} onChange={(d) => setCollectionDate(d)} />
                            </div>
                            <div className="ref-collection-details">
                                <div className="ref-input-labels" id="device-serial-input-label">
                                    Device Serial Number
                                </div>

                                {/* Device selection to display all serials the user can choose from (Supported devices) NA for unsupported devices */}
                                <select name="device-serial" id="device-serial" defaultValue={deviceSerialOptions[0]} className="device-serial-selection ref-inputs" {...register("deviceSerial")}>
                                    {deviceSerialOptions.map((device) => {
                                        return(
                                            <option key={device.deviceSerial} value={device.deviceSerial}>{device.deviceName}</option>
                                        )
                                    })}
                                </select>
                                <div className="ref-input-labels">
                                    Type of Package
                                </div>
                                <input type="text" name='package-type' id='package-type' className="package-type ref-inputs" placeholder="Plastic" {...register("packageType", { required: true, pattern: {
                                    value: /^[A-z]+$/i,
                                    message: "No Special Characters"
                                }})}/>
                                {errors.packageType && (errors.packageType.type == 'required' && <p className="form-error">Required*</p>)}
                                {errors.packageType && (errors.packageType.type == 'pattern' && <p className="form-error">{errors.packageType.message}</p>)}

                                <div className="ref-environment-details">
                                    <div className="ref-environment-details-container">
                                        <div className="ref-input-labels">
                                            Temp(&#8451;)
                                        </div>
                                        <input type="number" id="temperature" name="temperature" min="0" max="100" className="ref-inputs ref-integer-inputs"  placeholder="25" {...register("temperature", { required: true })}/>
                                        {errors.temperature && (errors.temperature.type == 'required' && <p className="form-error">Required*</p>)}
                                    </div>
                                    <div className="ref-environment-details-container">
                                        <div className="ref-input-labels">
                                            Humidity(%)
                                        </div>
                                        <input type="number" id="humidity" name="humidity" min="0" max="100" className="ref-inputs ref-integer-inputs"  placeholder="29" {...register("humidity", { required: true })}/>
                                        {errors.humidity && (errors.humidity.type == 'required' && <p className="form-error">Required*</p>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ref-collection-other-details">
                            <div className="ref-input-labels">
                                Mask
                            </div>
                            <input name='mask' id='mask' className="mask ref-inputs" placeholder="8" type='number' min="0" max="100" {...register("mask", { required: true })}/>
                            {errors.mask && (errors.mask.type == 'required' && <p className="form-error">Required*</p>)}
                            <div className="ref-input-labels">
                                Window
                            </div>
                            <input type="text" name='window' id='window' className="window ref-inputs"  placeholder="Tukey" {...register("window", { required: true , pattern: {
                                value: /^[A-z]+$/i,
                                message: "No Special Characters"
                            }})}/>
                            {errors.window && (errors.window.type == 'required' && <p className="form-error">Required*</p>)}
                            {errors.window && (errors.window.type == 'pattern' && <p className="form-error">{errors.window.message}</p>)}
                            <button className="ref-upload-button" type="submit" >Upload Reference</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}



//Reference Selection Item to display detils of references the user can choose from
/*
    Should display reference details for each reference file
    Should allow for an activation event when the user selects the reference file

    Parameters ------
    fileName --> String
    fileId ---> Integer
    provider --> String
    deviceName ---> String
    imageURL ---> String for user image url to be retrieved from bucket
    uploadDate -->Date time string
    setSelectedReferneceFile ---> State for sactivating a reference file before clicking confirm

*/ 
const ReferenceSelectionItem = (props) => {

    // Activate the file for selection in file selection list
    const activateFile = () => {
        props.setSelectedReferenceFile({'referenceFileId':props.fileId, 'referenceFileName': props.fileName})
    }

    return(
        <>
            {/* Reference File Details */}
            <div className={props.selectedReferenceFile.referenceFileId === props.fileId?("ref-selection-item active"):("ref-selection-item")} onClick={activateFile}>
                <div className="ref-selection-item__system-name">
                    {props.deviceName}
                </div>
                <div className="ref-main-details">
                    <div className="ref-selection-item__file-name">
                        {props.fileName}
                    </div>
                    <div className="ref-selection-item__user-details">
                        {/* Bucket user image */}
                        <img src={`${process.env.REACT_APP_AWS_BUCKET_URL}${props.imageUrl}`} alt=""  className="portfolio-icon"/>
                        <div className="user-name">{props.userName}</div>
                    </div>
                </div>
                <div className="ref-selection-item__collection-details">
                    <div className="ref-selection-item__upload-date">
                        {/* Formatting of date */}
                        {moment(props.uploadDate).format("DD MMMM YYYY, h:mm:ss a")}
                    </div>
                    <div className="ref-selection-item__provide-details">
                        {props.provider}
                    </div>
                </div>
            </div>
        </>
    )
}


//Reference Selection Box to display reference files available for selection 
/* 
    Should allow user to see the available reference files he can select for that particular device
    Should allow users to filter the reference files
    Reference Files should display
    Parameters -----
    setReferenceSelectionPopup ---> Triggers state for selection popup
    referenceSelectionPopup ----> State for selection popup
    refAxiosFiles ----> Reference Files state from the backend (contains all reference files)
    setRefAxiosFiles ----> Event to set Reference File state ^
    system ----> System of that respective element \
    
*/
const ReferenceFileSelection = (props) => {
    
    const [collectionDate, setCollectionDate] = useState(new Date())   
    const [selectedCompanyUsers, setSelectedCompanyUsers] = useState([])
    const [selectedReferenceFile, setSelectedReferenceFile] = useState({referenceFileId:null, referenceFileName:null})

    //Global state for all reference files selected as well as notitfication  
    const { setReferenceSubmissionFiles, setNotification, referenceSubmissionFiles } = useStore(state => ({ 
        setReferenceSubmissionFiles: state.setReferenceSubmissionFiles, 
        setNotification: state.setNotification,
        referenceSubmissionFiles: state.referenceSubmissionFiles
    }), shallow);
        

    //React useForm
    const { register, handleSubmit } = useForm();

    //Event handler to apply filter to the company
    const companyUserFilter = (e) => {
        getFilteredUsersByCompany(setSelectedCompanyUsers, e.target.value)
    }

    //Reference File Filter form submission
    const filterMyReferences = (data) => {
        //Null data and add in date format
        let nullifiedData = nullChecker(data)
        nullifiedData.dateOfCollection = moment(collectionDate).format("YYYY-MM-DD")

        getFilteredReferenceFiles(
            props.setRefAxiosFiles, 
            sessionStorage.getItem('companyId'), 
            deviceIdReference[props.system],
            nullifiedData.fileName,
            nullifiedData.dateOfCollection,
            nullifiedData.uploaderId
        )
    }

    const clearFilter = () => {
        getFilteredReferenceFiles(
            props.setRefAxiosFiles, 
            sessionStorage.getItem('companyId'), 
            deviceIdReference[props.system],
            null,
            null,
        )
    }

    //Set Reference File into list of reference files
    const selectReferenceFile = () => {
        
        // If there is no activated file, send a notifcation that there is no selected file
        if (Object.values(selectedReferenceFile).every(x => x === null || x === '')) {
            setNotification('No Reference File', 'No reference file selected please choose 1 before continuing', 'error')
            return
        }
        //If there is select the file and close the pop up
        setReferenceSubmissionFiles(deviceIdReference[props.system], selectedReferenceFile.referenceFileId, selectedReferenceFile.referenceFileName)
        props.setReferenceSelectionPopup(false)
    }
    
    useEffect(() => {
        getFilteredUsersByCompany(setSelectedCompanyUsers, sessionStorage.getItem('companyId'))
    },[])

    return(
        <>
            <div className={props.referenceSelectionPopup?("ref-selection-popup"):("ref-selection-popup inactive")}>
                <i className='bx bx-x' onClick={() => props.setReferenceSelectionPopup(false)}></i>
                <div className="ref-selection-container">
                    {/* Allow users to select a reference file */}
                    <div className="ref-selection">
                        <div className="ref-selection-title">
                            Select a Reference File Below <i className='bx bxs-chevrons-down'></i>
                        </div>
                        {/* All reference Files */}
                        <div className="ref-selection-box">
                            {
                                props.refAxiosFiles.map((refFile,) => {
                                    return(
                                        <ReferenceSelectionItem
                                            fileId={refFile['fileId']}
                                            deviceName={refFile['deviceName']}
                                            fileName={refFile['fileName']}
                                            userName={refFile['uploaderInfo']['userName']}
                                            uploadDate={moment(refFile['uploadedDate']).format("DD MMMM YYYY")}
                                            provider={refFile['providerName']}
                                            imageUrl={refFile['uploaderInfo']['imageUrl']}
                                            key={refFile['fileId']}
                                            setSelectedReferenceFile={setSelectedReferenceFile}
                                            selectedReferenceFile={selectedReferenceFile}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                    {/* Allow users to conduct filtering of reference files */}
                    <form className="ref-selection-filter" onSubmit={handleSubmit(filterMyReferences)}>
                        <div className="ref-selection-filter-title">Reference Filters</div>
                        <div className="search-bar-filter">
                            <input type="text" className="search-input" name="name-search" placeholder="Search Reference File Name"  {...register("fileName")} />
                            <button className="search-button"><i className='bx bx-search-alt' ></i></button>
                        </div>
                        <div className="collection-details-filter">
                            <div className="collection-date-filter">
                                <div className="date-of-collection">Date of Collection</div>
                                <Calendar value={collectionDate} onChange={(d) => setCollectionDate(d)} />
                            </div>
                            <div className="collection-provider-user">
                                <div className="provider">Provider:</div>
                                <select name="provider" id="provider" className="provider-input"  {...register("companyId", {onChange: companyUserFilter})}>
                                    <option value="1">ANOR Technologies</option>
                                    <option value="2">Own Company</option>
                                </select>
                                <div className="user">Uploader:</div>
                                <select name="user" id="user" className="user-input" {...register("uploaderId")}>
                                    {/* Map out the users based on company ID */}
                                    {selectedCompanyUsers.map((user) => {
                                        return(
                                            <option value={user.userId} key={user.userId}>{user.userName}</option>
                                        )
                                    })}
                                    <option value="null">All Users</option>
                                </select>
                                <button className="clear-filter" onClick={clearFilter}>Clear Search</button>
                            </div>
                        </div>
                        <button className="ref-selection-button" type="button" onClick={selectReferenceFile}>
                            Select Reference
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}


//Device Box to display the type of device and data detected as well as file count
/*
    Should allow users to see the type of device detected
    Shoud allow users to see the number of files associated with this device data type
    Should be mass produced based on unique device found
    Parameters ----> 
    device ----> string for system detected
    fileCount ----> integer for number of files detected for this particular system
*/ 
const DeviceBox = (props) => {
    return(
        <>
            <div className="device-box">
                <div className="device-box-header">
                    <div className="device-box-title"><span>{props.device}</span> Data Detected</div>
                    <div className="device-box-subtitle">{props.fileCount} Files Uploaded</div>
                </div>
            </div>
        </>
    )
}

//Device Entry to contain and display the entire Device entry with the device box as well as the 
//option to choose or upload a reference file
/*
    Holds Components to view reference needed (Device Box)
    Holds components to upload and select the references
    Allows user to open pop up for reference selection
    Parameters ----
    device ----> string for system detected
    fileCount ----> integer for number of files detected for this particular system
*/ 
const DeviceEntry = (props) => {

    const [ referenceSelectionPopup, setReferenceSelectionPopup] = useState(false)
    const [ referenceUploadPopup, setReferenceUploadPopup] = useState(false)
    
    //State for all reference Files in DB for that device
    const [refAxiosFiles, setRefAxiosFiles] = useState([]);
    
    //Global state for all reference files selected  
    const { referenceSubmissionFiles } = useStore(state => ({ 
        referenceSubmissionFiles: state.referenceSubmissionFiles, 
    }), shallow);
        

    //Get all refernece files
    useEffect(()=> {
        getFilteredReferenceFiles(
            setRefAxiosFiles,
            sessionStorage.getItem('companyId'),
            deviceIdReference[props.device],
            null,
            null,
        );

        getFilteredUsersByCompany()

    }, [referenceSelectionPopup]);

    return(
        <>
            <div className="device-entry">
                <div className="line"></div>
                <div className="device-container">
                    <DeviceBox device={props.device.charAt(0).toUpperCase() + props.device.slice(1)} fileCount={props.fileCount}/>
                    <div className="ref-input">
                        <div className="ref-header">
                           <div className="ref-title">Reference File</div>
                            <div className="ref-subtitle">{referenceSubmissionFiles[deviceIdReference[props.device]]?(referenceSubmissionFiles[deviceIdReference[props.device]].referenceFileName):('No Reference File Selected')}</div>
                        </div>
                        <div className="ref-buttons">
                            <button onClick={() => setReferenceSelectionPopup(true)}>Select Reference File</button>
                            <button className="ref-add-button" onClick={() => setReferenceUploadPopup(true)}><i className='bx bxs-file-plus' ></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <ReferenceFileSelection referenceSelectionPopup={referenceSelectionPopup} setReferenceSelectionPopup={setReferenceSelectionPopup} refAxiosFiles={refAxiosFiles} setRefAxiosFiles={setRefAxiosFiles} system={props.device}/>
            <ReferenceFileUpload referenceUploadPopup={referenceUploadPopup} setReferenceUploadPopup={setReferenceUploadPopup} system={props.device}/>
        </>
    )
}


//Main slide for all reference data selection or collection
/* 
    Should allow users to select or upload reference file 
    Should detect data file device types and provide the neccessary number of upload opportunities for references
    Should display references after upload.
    Parameters-----
    activeStep --> Integer for carousel active step
    setDeactivatePrevButton() --> Function to set Previous Buttons Activation State (Enable/Disable)
    setDeactivateNextButton() --> Function to set Next Buttons Activation State (Enable/Disable)
*/
const ReferenceFileSlide = (props) => {


    const { dataSubmissionFiles, setOrganisedSubmissionFiles, referenceSubmissionFiles , filterSetReferenceSubmissionFiles} = useStore(state => ({ 
        dataSubmissionFiles: state.dataSubmissionFiles, 
        setOrganisedSubmissionFiles: state.setOrganisedSubmissionFiles,
        referenceSubmissionFiles: state.referenceSubmissionFiles,
        filterSetReferenceSubmissionFiles: state.filterSetReferenceSubmissionFiles,

    }), shallow);
    

    // States are used to change the screen type
    const [ loadingScreen, setLoadingScreen ] = useState(true)
    const [ preparingReferences, setPreparingReferences] = useState(false)
    const [ deviceBlocks,  setDeviceBlocks ] = useState([])

    //Triggers a device check event that occurs when the user is onthe second slide
    useEffect(() => {

        if (props.activeStep == 1) {
            setLoadingScreen(true)
        }

        if (props.activeStep == 2) {
            let deviceBlockArray = []

            //Extract detected device types of dataSubmissionFiles Files and convert to a unique set
            let deviceSet = new Set(dataSubmissionFiles.map((file) => {
                return [file['device'], []];
            }))
              

            //Add an additional set for undetected device types (array is multidimensional for object array pairing purposes)
            deviceSet.add(['undetected', []])

            //Create an object using the deviceSet to form {device: []} device array pairing
            //Then push files of relevance to the device types
            let deviceAllocationObject = Object.fromEntries(deviceSet)
            dataSubmissionFiles.map((file) => {
                deviceAllocationObject[file.device].push(file)
            })

            //If there arre no undetected files, remove the  completely
            if (deviceAllocationObject['undetected'].length == 0) {
                delete deviceAllocationObject['undetected']
            }

            //This creates a state that organises the device files into arrays based on device and associates the array to a device Id
            //Used in GlobalForm to compile properly
            Object.keys(deviceAllocationObject).map((device) => {
                let fileIdCounter = 0 
                let fileList = []
                
                deviceAllocationObject[device].map((file) => {
                    let fileTest = file['file']
                    fileTest['fileId'] = fileIdCounter
                    fileList.push(file['file'])
                    fileIdCounter ++
                })
                setOrganisedSubmissionFiles(deviceIdReference[device] ,fileList)
            })

            Object.keys(referenceSubmissionFiles).map((system) => {
                if (!Object.keys(deviceAllocationObject).includes(idDeviceReference[system])) {
                    filterSetReferenceSubmissionFiles(removeAttribute(referenceSubmissionFiles, system))
                    // filterDetectedDevices(system)
                }
            })

            setPreparingReferences(true)

            setTimeout(() => {
                Object.keys(deviceAllocationObject).map((device) => {
                    deviceBlockArray.push(<DeviceEntry device={device} fileCount={deviceAllocationObject[device].length} key={'device_'+device}/>)
                })

                setDeviceBlocks(deviceBlockArray)
                setLoadingScreen(false)
                props.setDeactivatePrevButton(false)
                props.setDeactivateNextButton(false)

            }, 1500)     
        }
    },[props.activeStep])

    return (
        <>
            {loadingScreen?(
                // Displays loading screen for various events
                <div className="loading-screen">
                    <div className="loading-header">
                        <div className="loading-title"><img src={loadingGif} alt="Loading Gif" className="loading-icon"></img></div>
                        {(() => {
                                if (preparingReferences) {
                                    return <div className="loading-subtitle">Preparing References</div>
                                }
                                return <div className="loading-subtitle">Preparing Files</div>
                            }
                        )()
                        }
                    </div>
                </div>
            ):(
                <div className="ref-file-slide">
                    <div className="slide-header">
                        <div className="slide-title"><span className="step-count">{props.stepCount}.</span> {props.header}</div>
                        <div className="slide-subtitle">Select or Upload a Reference File for each data type</div>
                    </div>
                    <div className="ref-detection-list">
                        {deviceBlocks}
                    </div>
                </div>
            )}
        </>
    );
};

export {ReferenceFileSlide}