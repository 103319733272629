import React, { useEffect } from "react";
import useStore from "../store";
import { shallow } from "zustand/shallow";

// Final Submission Slide for the entire upload process, loading screen
/*
    Parameters-----
    activeStep --> State of current carousel step (contains String)
*/
const SuccessfulSubmissionSlide = (props) => {

    // Store retrieval for set notification and data clearance states
    const { setNotification, clearDataFiles, clearDataSubmissionFiles, clearDetectedDevices, clearOrganisedSubmissionFiles,clearReferenceSubmissionFiles, clearGlobalSubmissionForms } = useStore(
        (state) => ({
            setNotification: state.setNotification,
            clearDataFiles: state.clearDataFiles,
            clearDataSubmissionFiles: state.clearDataSubmissionFiles,
            clearDetectedDevices: state.clearDetectedDevices,
            clearOrganisedSubmissionFiles: state.clearOrganisedSubmissionFiles,
            clearReferenceSubmissionFiles: state.clearReferenceSubmissionFiles,
            clearGlobalSubmissionForms: state.clearGlobalSubmissionForms
        }),
        shallow
    );
 
    // use effect for loading screen as well as data clearence
    useEffect(()=> {
        if (props.activeStep > props.stepsLength) {

            setTimeout(() => {
                props.setUploadPopup(false)
                setNotification('Submission Successful', 'Your Data Submission was a Success. Check the Archive Page For Review', 'success')
                props.setActiveStep(1)
                clearDataFiles()
                clearDataSubmissionFiles()
                clearDetectedDevices()
                clearOrganisedSubmissionFiles()
                clearReferenceSubmissionFiles()
                clearGlobalSubmissionForms()
            },3000)
        }

    }, [props.activeStep])
    return (
        <>
            {/* Loading Symbol */}
            <div className={props.activeStep > props.stepsLength?('successful-submission-slide active'):('successful-submission-slide')}>
                <div className="center">
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                </div>
                <div className="loading-title">Loading.... Please Wait!</div>
            </div>
        </>
    );
};

export {SuccessfulSubmissionSlide}