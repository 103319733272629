import React, { useState } from 'react';
import useStore from '../store';
import { shallow } from 'zustand/shallow';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStoreApi, useReactFlow } from 'reactflow';
import DataSelectPopup from './dataModelSelectPopup.js';

import '../../Deprecated_CSS/CSS_Files/ModelInputDesigns.css';


//Input Types that the Params Bar can have
//Sliders-----------------------------------------------------------------------------------------------------------------------------------------
const Slider = ({max, restrict, defaultValue, label, description, register}) => {

    const [rangeVal, setRangeVal] = useState(defaultValue);
    const [valPercent, setValPercent] = useState(defaultValue);

    const slideSlider = (e, max) => {
        setValPercent((rangeVal/max)* 100);
        e.target.style.background = `linear-gradient(to right, #0586ff ${valPercent}%, #d5d5d5 ${valPercent}%)`;
        if(e.target.value > restrict) {
            setRangeVal(e.target.value);
        };  
    };

    return( 
        <div className='slider-input parameter-bar-items'>  
            <label>{label}</label>
            <div className="description">
                {description}
            </div>
            <div className='slider-container parameter-bar-items'>
                <div className='slider-labels'>
                    <div className='slider-label'>Train</div>
                    <div className='slider-label'>Test</div>
                </div>
                <input type="range" min="1" max={max} value={rangeVal}  className="slider" onChange={(e) => {slideSlider(e, max)}}/>
                <div className='slider-labels'>
                    <div className='slider-label'>{Math.round(valPercent)}%</div>
                    <div className='slider-label'>{max-Math.round(valPercent)}%</div>
                </div>
            </div>
        </div>
    );
}



//Fixed Texts----------------------------------------------------------------------------------------------------------------------------
const FixedText = ({label, value}) => {
    return(
        <div className='fixed-text parameter-bar-items'>
            <label>{label+':'}</label>
            <span>{value}</span>
        </div>
    );
};



//Boolean Switches-----------------------------------------------------------------------------------------------------------------------
const SwitchButton = ({label, defaultValue, register}) => {
    return(
        <div className='switch-container'>
            <label>{label}</label>
            <label className="switch">
                <input type="hidden" value="false" name={label}/>
                <input type="checkbox" defaultChecked={defaultValue} name={label} value='true' {...register(label)}/>
                <span className="slider round"></span>
            </label>
        </div>
    );
};



//Text Boxes-------------------------------------------------------------------------------------------------------------------------------
const TextBox = ({label, value}) =>{
    //Converts text to allow next line
    var fullString = value.reduce((a, v) => a = a + v + '\n', '')
    return(
        <div className='text-box parameter-bar-items'>
            <label>{label}</label>
            <textarea name="tesr" id="" cols="30" rows="10" value={fullString} readOnly>
            </textarea>
        </div>
    );
};



//Input Box Type inputs------------------------------------------------------------------------------------------------------------------
const InputBox = ({label, defaultValue, register, errors, parameterbar_id, focusNode, minRange, maxRange}) => {
    
    const { setParameterBarOpened } = useStore(
        (state) => ({
            setParameterBarOpened: state.setParameterBarOpened,
        }),
        shallow
    );

    return(
        <div className="input-box parameter-bar-items">
            <label>{label}</label>
            <input type="text" defaultValue={defaultValue} {...register(label, {required:true, min: minRange, max:maxRange})}/>
            {errors[label] && focusNode(parameterbar_id)}  
            {errors[label] && setParameterBarOpened(parameterbar_id)}  
            {errors[label]?.type === 'required' && <p className='form-error'>This value is required</p>}
            {errors[label]?.type === 'min'   &&  <p className='form-error'>Values Must be above {minRange}</p>}  
            {errors[label]?.type === 'max'  &&  <p className='form-error'>Values Must be Below {maxRange}</p>}  
        </div>
    );
};


//Drop down list-------------------------------------------------------------------------------------------------------------------------
const DropDown = ({label, options, defaultValue, register}) => {
    return(
        <div className='drop-box parameter-bar-items'>
            <label>{label}</label>
            <select name={label} defaultValue={defaultValue}  {...register(label, {required: true})}>
                {options.replace(/\s/g,'').split(',').map((item) => {
                    return(
                        <option key={item} value={item}>{item}</option>
                    )
                })}
            </select>
        </div>
    );
};

//Hyperparameter Item
const Hyperparameter = ({parameter, description, defaultValues, validation}) => {

    let errors = {};

    const { hypertuneRegister } = useStore(
        (state) => ({
            hypertuneRegister: state.hypertuneRegister
        }),
        shallow
    );

    return(
        <>
            <div className='parameter' >
                <div className='parameter-header'>
                    {parameter.algorithmParameterName}
                </div>
                <div className="description">
                    {description}
                </div>
                <div className="values">
                    <input type="text" key={defaultValues} defaultValue={defaultValues}  {...hypertuneRegister(parameter.algorithmParameterName, {validate: validation})}/>
                    {errors[parameter.algorithmParameterName]?.type === 'validate'  &&  <p className='form-error'>Please follow the conditions as stated</p>}  
                    {errors[parameter.algorithmParameterName]?.type === 'required'  &&  <p className='form-error'>This value is required</p>}  
                </div>
            </div>
        </>
    );
};


export {Hyperparameter}


//Hyperparameter input Area-------------------------------------------------------------------------------------------------------------
const HyperParameters = ({label, algorithmActive,  register}) => {
    
    const { algorithmHyperparameterElements, setHypertuneRegister } = useStore(
        (state) => ({
            algorithmHyperparameterElements: state.algorithmHyperparameterElements, 
            setAlgorithmHyperparameterElements: state.setAlgorithmHyperparameterElements,
            setHypertuneRegister: state.setHypertuneRegister
        }),
        shallow
    );
    
    useEffect(()=>{
        setHypertuneRegister(register)
    }, []);
  
    return(
        <div className="hyperparameters-section">
            <div className="model-selected">
                Model: {algorithmActive}
            </div>
            <div className='hyperparameters-title'>
                {label}
            </div>
            {algorithmHyperparameterElements}
        </div>
    );
};


//Input Allocator to decide which input type to give --------------------------------------------------------------------------
const InputAllocation = ({form, register, errors, parameterbar_id, focusNode, algorithmActive }) => {
    if (form.modelBuildingNodeBooleanParameterModel != null) {
        return(
            <SwitchButton label={form.parameterName} defaultValue={form.modelBuildingNodeBooleanParameterModel.defaultValue} register={register}/>
        );
        
    } else if (form.modelBuildingNodeFloatParameterModel != null) {
        return(
            <InputBox 
                label={form.parameterName} 
                defaultValue={form.modelBuildingNodeFloatParameterModel.defaultValue} 
                register={register} 
                errors={errors} 
                parameterbar_id={parameterbar_id} 
                focusNode={focusNode}
                minRange = {form.modelBuildingNodeFloatParameterModel.minRange}
                maxRange = {form.modelBuildingNodeFloatParameterModel.maxRange}
            />
        );
    } else if (form.modelBuildingNodeIntegerParameterModel != null) {
        return(
            <InputBox 
                label={form.parameterName} 
                defaultValue={form.modelBuildingNodeIntegerParameterModel.defaultValue} 
                register={register} 
                errors={errors} 
                parameterbar_id={parameterbar_id} 
                focusNode={focusNode}
                minRange = {form.modelBuildingNodeIntegerParameterModel.minRange}
                maxRange = {form.modelBuildingNodeIntegerParameterModel.maxRange}
            />
        );
    } else if (form.modelBuildingNodeStringParameterModel != null) {
        return(    
            <DropDown label={form.parameterName} options={form.modelBuildingNodeStringParameterModel.valueOption} defaultValue={form.defaultValue} register={register}/>
        );
    } else if(form.modelBuildingNodeSlider != null ) {
        return(
            <Slider max={form.modelBuildingNodeSlider.max} restrict={form.modelBuildingNodeSlider.restrict} label={form.parameterName} defaultValue={form.modelBuildingNodeSlider.defaultValue} register={register} />
        );
    } else if(form.modelBuildingAlgoHyperParameterOptionModel != null) {
        return(
            <HyperParameters label={form.parameterName} algorithmActive={algorithmActive} parameters={form.modelBuildingAlgoHyperParameterOptionModel} register={register} errors={errors}/>
        );
    };
};

export {FixedText, TextBox};

//Main Parameter bar item----------------------------------------------------------------------------------------------------------------------------------------
export default({id, data}) => {
    const {  
        parameterBarOpened, algorithmActive, submissionStack,
        dataType, entryList, entryProportions, totalData, setSubmissionStack,
        setHeaderOpened, setNodeBarButton, setNodeBarSection} = useStore(
        (state) => ({
            parameterBarOpened: state.parameterBarOpened,
            algorithmActive: state.algorithmActive,
            submissionStack: state.submissionStack,
            setSubmissionStack: state.setSubmissionStack,
            dataType: state.dataType, 
            entryList: state.entryList, 
            entryProportions: state.entryProportions, 
            totalData: state.totalData,
            setHeaderOpened: state.setHeaderOpened, 
            setNodeBarButton: state.setNodeBarButton,
            setNodeBarSection: state.setNodeBarSection
        }),
        shallow
    );

    const [dataSelectPopup, setDataSelectPopup] = useState(false);
    const [dataSelectBar, setDataSelectBar] = useState(null);

    //Focusing on the node
    const reactFlowStore = useStoreApi();
    const { setCenter } = useReactFlow();

    //React Hook Form
    const { register, handleSubmit, watch, formState: { errors }} = useForm({
        shouldFocusError:false,   
        mode: "onSubmit",
        reValidateMode: "onChange",
    });
    
    const { nodeInternals } = reactFlowStore.getState();
    const nodes = Array.from(nodeInternals).map(([, node]) => node);

    //Upon submit-----------------------------------------------------------------------------
    const onSubmit = (new_data) => {
        //Retrieve nodes from flow
        const { nodeInternals } = reactFlowStore.getState();
        const nodes = Array.from(nodeInternals).map(([, node]) => node);
        //Map our each node 
        nodes.map((node) => {

            //If node in array is same as the node's id map through each item in the node
            if(node.id == id) {
                //If Data Selection
                if (id.includes('select')) {
                    submissionStack.entryCount = totalData;
                    submissionStack.dataTypeTrained = dataType;
                    // submissionStack.chemicalProportion = entryProportions
                    submissionStack.entryList = entryList

                    node.data.modelBuildingNodeParameterModelList[0] = {
                        'Data Entries':  totalData,
                        'Data Type': dataType,
                        // 'Chemical Proportions': entryProportions
                    };
                    node.data.entryList = entryList;

                //If Hypertuning
                } else if (id.includes('hypertune')){
                    node.data.modelBuildingNodeParameterModelList.map((parameter) => {
                        if ( parameter.parameterName == 'Hyperparameter Tuning method') {
                            parameter.modelBuildingNodeStringParameterModel.defaultValue = new_data['Hyperparameter Tuning method'];
                            submissionStack.hyperparameterTuneMethod = new_data['Hyperparameter Tuning method'];
                        };
                    });
                    try{
                        submissionStack.algorithmId = node.data.modelBuildingNodeParameterModelList[1].modelBuildingAlgoHyperParameterOptionModel[0].algorithmId;

                    } catch (error) {
                        focusNode('model_1');
                        //alert('Model Not Selected, Please Select 1')
                    }
                    console.log('Submitting');
                    let hyperparamList = [];
                    Object.keys(new_data).slice(1, ).forEach((key, index) => {
                        node.data.modelBuildingNodeParameterModelList[1].modelBuildingAlgoHyperParameterOptionModel.map((parameter) => {
                            if (key === parameter.algorithmParameterName) {
                                Object.keys(parameter).forEach((paramKey,paramIndex) => {
                                    if(parameter[paramKey] !== null && paramKey.includes('modelBuilding')) {
                                        parameter[paramKey].defaultValue = new_data[key];
                                        let parameterSave = parameter[paramKey];
                                        //console.log(parameterSave)
                                        //If validated, add this to the submission Stack
                                        hyperparamList.push({
                                            algorithmHyperparameterId: parameterSave.parameterId, 
                                            tuningValue: parameterSave.defaultValue.replace(/\s/g,'').replace(/,\s*$/, "")
                                        });
                                    };
                                });
                            };
                        });
                    });
                    submissionStack.modelTrainingAlgorithmInputDetailsDTOList = hyperparamList;
                    console.log('submitted');
                } else {
                    //DND Node
                    let nodeSubmission = {};
                    if (node.id.includes('dndnode')) {
                        nodeSubmission = {
                            nodeId: node.data.nodeId,
                            order: submissionStack.modelTrainingNodeInputDetailsDTOList.length + 1,
                            modelTrainingNodeParameterInputDetailsDTO: [
                            ]
                        };
                    };

                    Object.keys(new_data).forEach((key, index) => {
                        //Checks if there is a connected node. if so change the default values of that node 
                        if (node.data.modelBuildingNodeParameterModelList !== undefined){
                            node.data.modelBuildingNodeParameterModelList.map((parameter) => {
                                if (key === parameter.parameterName) {
                                    Object.keys(parameter).forEach((paramKey,paramIndex) => {
                                        if(parameter[paramKey] !== null && paramKey.includes('modelBuildingNode')) {
                                            parameter[paramKey].defaultValue = new_data[key]
                                            if (node.id.includes('dndnode')) {
                                                nodeSubmission.modelTrainingNodeParameterInputDetailsDTO.push({
                                                    "nodeParameterId": parameter[paramKey].nodeParameterId,
                                                    "value": parameter[paramKey].defaultValue
                                                });
                                            };
                                        };
                                    });
                                };
                            });
                        }else {
                            console.log('No value', node.data.modelBuildingNodeParameterModelList);
                        };
                    });

                    if (node.id.includes('split')){
                        submissionStack.trainProportion = parseInt(node.data.modelBuildingNodeParameterModelList[0].modelBuildingNodeIntegerParameterModel.defaultValue);
                        submissionStack.testProportion = 100 - node.data.modelBuildingNodeParameterModelList[0].modelBuildingNodeIntegerParameterModel.defaultValue;
                    };

                    if (node.id.includes('dndnode')){
                        submissionStack.modelTrainingNodeInputDetailsDTOList.push(nodeSubmission);
                    };                
                };
            };
        });
    };

    
    //Collects find node and node info, then centralise it on the screen
    const focusNode = (id) => {
        const { nodeInternals } = reactFlowStore.getState();
        const nodes = Array.from(nodeInternals).map(([, node]) => node);

        if (nodes.length > 0) {
            nodes.map((node) => {
                if(node.id == id) {

                    const x = node.position.x + node.width / 2;
                    const y = node.position.y + node.height / 2;
                    const zoom = 1.85;
        
                    setCenter(x, y, { zoom, duration: 1000 });
                };
            });
        };
    };

    
    return (
        <>
            <aside className={parameterBarOpened === id?('parameter-bar open'):('parameter-bar')}>
                    <div className='parameter-bar-title'>{data.nodeName}</div>

                    {/* Meant to create a form and conduct validation */}
                    <form id={id+"_form"} onSubmit={handleSubmit(onSubmit)} >
                        {data.modelBuildingNodeParameterModelList.map((set) => {
                            if(id.includes('select')) {
                                useEffect(() => {
                                    setDataSelectBar(
                                        <>
                                            <FixedText label='Data Entries' value={set['Data Entries']}/>
                                            <FixedText label='Data Type' value={set['Data Type']}/>
                                            <TextBox label = 'Chemcial Proportions' value={set['Chemical Proportions']}/>
                                            <button className='button parameter-bar-items' onClick={()=> {
                                                setDataSelectPopup(true)
                                                setHeaderOpened(false)
                                                setNodeBarButton(false)
                                                setNodeBarSection(false)
                                            }}>Reselect Data</button>
                                        </>
                                    )
                                }, [])
                                return(
                                    <>  
                                        {dataSelectBar}
                                    </>
                                )
                            }else{
                                return(
                                    <>
                                        <InputAllocation 
                                            form={set} 
                                            register={register} 
                                            errors={errors} 
                                            parameterbar_id = {id}  
                                            focusNode={focusNode} 
                                            algorithmActive={algorithmActive}
                                            setDataSelectPopup = {setDataSelectPopup}
                                        />
                                    </>
                                )
                            }
                        })} 
                    </form>
            </aside>
            {id.includes('select')?(<DataSelectPopup  trigger={dataSelectPopup} setTrigger={setDataSelectPopup} setDataSelectBar={setDataSelectBar} nodes={nodes} setHeaderOpened={setHeaderOpened} setNodeBarButton={setNodeBarButton} />):('')}
        </>
    )
    
}

