import React, {Component} from 'react';

//Tabs and Tab Component for Graphs
const Tab = () => null;

class Tabs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0
    };

    this.setActiveTab = this.setActiveTab.bind(this);
  }


  setActiveTab(activeTab) {
    this.setState({ activeTab });
  }
  
  render() {
    const {children} = this.props;
    const {activeTab} = this.state;
    return (
      <div className="tabs">
        <div className='tabs-header'>
          {
            children.map((tab, i) => {
              const isActive = (i === activeTab);

              return (
                <button
                  key={"tabH"+i}
                  className={(isActive ? "active" : "")}
                  onClick={() => this.setActiveTab(i) }
                >
                  <div>{tab.props.title}</div>
                </button>
              );
            })
          }
        </div>
        <div className="tab-body">
          {
            children.map((tab, i) => {
              const isActive = (i === activeTab);

              return (
                <div
                  key={"tabB"+i}
                  className={(isActive ? "active" : "")}
                >
                  {tab.props.children}
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
}

export { Tabs, Tab };