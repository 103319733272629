//Module Imports
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Helmet } from "react-helmet";
import ReactFlow, {
    addEdge,
    updateEdge,
    ReactFlowProvider,
    MiniMap, 
    Controls, 
    Background,
    useNodesState,
    useEdgesState,
} from 'reactflow';
import useStore from '../store';
import { shallow } from 'zustand/shallow';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom';


//Custom Components
import {NodeBar} from './NodeBar';
import {
    CustomLayoutNodeFormat as CustomProcessingNode, 
    CustomModelNodeFormat as CustomModelNode,
    AdminNodeFormat as AdminNode,
    ProcessNodeFormat as ProcessNode
} from './StarterNodes';
import { nodes as initialNodes, edges as initialEdges } from './InitialNodes.js';
import ParameterBar from './ParameterBar';
import ModelPopup from './ModelBuilderPopUp';

//CSS files
import 'reactflow/dist/style.css';
import '../../Deprecated_CSS/CSS_Files/ModelBuilder.css';
import '../../Deprecated_CSS/CSS_Files/ModelBuilderPopup.css';


//Node ID system
let id = 0;
const  getId = () => `dndnode_${id++}`;

//Declare node types for custom nodes in StarterNodes
const nodeTypes = {
    customlayoutnode: CustomProcessingNode,
    custommodelnode: CustomModelNode,
    processnode: ProcessNode,
    adminnode: AdminNode,
};

//Main Model Builder Component
const ModelBuilder=()=>{
    
    //State Outside Store!
    const [parameterBars, setParameterBars] = useState([]);
    const [filteredProcessNodes, setFilteredProcessNodes] = useState([]);
    
    const navigate = useNavigate();

    const {
        setNavBar, setIsItSubmit, setSubmissionStack, 
        dataType, entryList,entryProportions, modelName, 
        totalData, submissionStack, setAlgorithmActive, 
        setAlgorithmSelect,setAlgorithmSelectId, setDetailElement,
        setAlgorithmHyperparameterElements,setCurrentPopUp, headerOpened} = useStore(
        (state) => ({ 
            setNavBar: state.setNavBar,
            setIsItSubmit: state.setIsItSubmit,
            setProcessIndex: state.setProcessIndex,
            setSubmissionStack: state.setSubmissionStack,
            dataType: state.dataType, 
            entryList: state.entryList,
            entryProportions: state.entryProportions, 
            modelName: state.modelName,
            totalData: state.totalData,
            submissionStack: state.submissionStack,
            setAlgorithmActive: state.setAlgorithmActive,
            setAlgorithmSelect: state.setAlgorithmSelect,
            setAlgorithmSelectId: state.setAlgorithmSelectId,
            setDetailElement: state.setDetailElement,
            setAlgorithmHyperparameterElements: state.setAlgorithmHyperparameterElements,
            setCurrentPopUp: state.setCurrentPopUp,
            headerOpened: state.headerOpened,
        }),
        shallow
    );

    //React Flow Canvas and Node Set Up
    const reactFlowWrapper = useRef(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    
    //Add this to the selection node for data selection
    Object.keys(initialNodes).map((node) => {
        if (initialNodes[node].id.includes('select') ) {
            initialNodes[node].data.modelBuildingNodeParameterModelList[0]['Data Entries'] = totalData;
            initialNodes[node].data.modelBuildingNodeParameterModelList[0]['Data Type']= dataType;
            initialNodes[node].data.modelBuildingNodeParameterModelList[0]['Chemical Proportions'] = entryProportions.split(',');
            initialNodes[node].data.entryList = entryList;
        };


        //Add this to the admin node 
        if (initialNodes[node].id.includes('admin')) {
            initialNodes[node].data.inputs.modelName = modelName;
        };
    });
    
    //Load in initial Nodes 
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    //Edge Updating----------------------------------------------------------------------------
    const edgeUpdateSuccessful = useRef(true);
    
    //Begin editing the edge
    const onEdgeUpdateStart = useCallback(() => {
        edgeUpdateSuccessful.current = false;
    }, []);

    //In the case where there is an update, set the edges of the new connection
    const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
        edgeUpdateSuccessful.current = true;
        setEdges((els) => updateEdge(oldEdge, newConnection, els));
    }, []);

    //In the case where there is no update, remove the entire edge
    const onEdgeUpdateEnd = useCallback((_, edge) => {
        if (!edgeUpdateSuccessful.current) {
            setEdges((eds) => eds.filter((e) => e.id !== edge.id));
        }
    
        edgeUpdateSuccessful.current = true;
    }, []);
    
        
    //Node Connect Function--------------------------------------------------------------------
    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);


    //Drag and Drop----------------------------------------------------------------------------

    //Drag Over Event
    const onDragOver = useCallback((event) => {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'
    }, [])


    //Dropping in Canvas Event
    const onDrop = useCallback((event) => {
        event.preventDefault()
        
        //Get Canvas Boundary
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const data = JSON.parse(event.dataTransfer.getData('text'))

        //Get the data of teh dataTransfer from drag event
        const type = event.dataTransfer.getData('application/reactflow');

        // check if the dropped element is valid
        if (typeof type === 'undefined' || !type) {
            return;
        }

        //Calculate Placing Coordinate with Special ReactFlow Coordinate Calculator
        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top
        })

        //Condition is to prevent double dropping in Deep Learning
        if (data.modelBuildingNodeCategoryModel.nodeCategoryName != 'Deep Learning Layers') {
            let id = getId()
            //Create Node
            const newNode = {
                id: id,
                type: type,
                position, 
                data: {
                    nodeId: data.nodeId,
                    nodeName: data.nodeName,
                    explanation: data.description,
                    form_title: data.nodeName,
                    modelBuildingNodeParameterModelList: data.modelBuildingNodeParameterModelList
                },
                className:'process-node',
                targetPosition: 'left',
                sourcePosition: 'right'
            };
    
            //Add Node to Existing Node List
            setNodes((nds) => nds.concat(newNode));

            //Create and add a new parameter bar for that node
            setParameterBars(parameterBars => [...parameterBars, <ParameterBar id={id} data={data} key={id}/>])   ;    
        };
        
    }, [reactFlowInstance]);


    //Get Node Bar Items and map parameter Bars
    const getNodeBar = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/ModelBuilding/`).then(res => {

            //Filter nodes as 2 types preprocessing and DL
            let filteredProcessNodeList = [];
            let filteredDLNodeList = [];

            //Push them to different lists
            Object.keys(res.data.listOfModelBuildingNodeModel).forEach(key => {
                if(res.data.listOfModelBuildingNodeModel[key].modelBuildingNodeCategoryModel.nodeCategoryName != 'Deep Learning Layers') {
                    filteredProcessNodeList.push(res.data.listOfModelBuildingNodeModel[key]);
                }else{
                    filteredDLNodeList.push(res.data.listOfModelBuildingNodeModel[key]);
                };
            });
            

            setFilteredProcessNodes(filteredProcessNodeList);

            //For each node, check its type and either assign a param bar or model pop up
            let compiledParameterBars = nodes.map((set) => {
                //Error With Parameter Bar for Dnd
                if (set.type === 'customlayoutnode' && (set.id !== 'start' && set.id !== 'end')) {
                    return(<ParameterBar id={set.id} data={set.data}/>);
                }   ;                            
                if (set.type === 'custommodelnode') {
                    return(<ModelPopup id={set.id} algorithmList={res.data.listOfModelBuildingAlgorithmModel} filteredDLNodes={filteredDLNodeList}/>) ;                                       
                }
                return('');
            })

            //Set all of the bars and pop ups
            setParameterBars(compiledParameterBars); 
        });
    };

    // Model Submission
    const modelSubmit = (nodes, edges, starting_node, ending_node) => {  
        
        //Running through the Flow Chart
        let current_node = '';

        //Find starting node
        edges.map((node) => {
            if (node.source == starting_node) {
                current_node = node.target;
            };
        });
        const loop_limit = 1;
        let loops = 0 ;

        //Get the node's form and submit it 
        document.getElementById(starting_node+'_form').requestSubmit();

        //While the current node is not the ending node, map through each node
        while(current_node != ending_node){
            //Map and reassign
            edges.map((node) => {
                if(node.source == current_node) {
                    current_node = node.target;
                    // If there is a form, submit it
                    if (document.getElementById(current_node+'_form') !== null){
                        document.getElementById(current_node+'_form').requestSubmit();
                    };
                };
            });

            //If Submission is incomplete then do break away the function
            if (loops >= loop_limit && current_node != 'end') {
                console.log('Incomplete Connection');
                return;
            };
            loops ++
        };

        setSubmissionStack(submissionStack);
        
        //If the submission stack has been filled completely then submit the stack 
        if (Object.values(submissionStack).every((v) => v !== null)) {
            //Axios For submission---------------------------------
            axios.post(`${process.env.REACT_APP_BASE_URL}/ModelBuilding/saveModelDetails`, submissionStack).then(res => {
                console.log(res.data)
                if (res.data == 'AI Model Training; Details saved') {
                    navigate('/modelselect');
                };
                }).catch((error) => {
                    console.log(error);
                });
        };
        submissionStack.modelTrainingNodeInputDetailsDTOList = [];
    };

    //Parameter Bars are mapped for each node in InitialNodes.js
    useEffect(() => {
        //Deactivate Nav Bar
        if (sessionStorage.getItem('userId') === 'undefined') {
            navigate('/');
        }
        setNavBar(false);
        getNodeBar() ;
        setAlgorithmActive('No Model Selected');
        setAlgorithmSelect(null);
        setAlgorithmSelectId(null);
        setDetailElement(null);
        setAlgorithmHyperparameterElements('No Algorithm Selected');
        setCurrentPopUp('ModelChooser');
    }, []);

    return(
        <>
            <Helmet>
                <title>Model Selector</title>
                <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet' />
            </Helmet>
            <div className={headerOpened?("model-builder-back-btn"):("model-builder-back-btn inactive")}><NavLink to='/'><i className='bx bx-chevron-left'></i><div>Return To Model Selection</div></NavLink></div>
            <main className='model-builder'>
                <ReactFlowProvider>
                    <NodeBar nodeInformation={filteredProcessNodes}/>                
                    <div className="main-canvas" ref={reactFlowWrapper} >
                        <ReactFlow key='model-builder'
                            //Node and Edge Rendering
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            nodeTypes={nodeTypes}

                            //Sets Reactflow playground diagram as the instance.
                            onInit={setReactFlowInstance}

                            //Drag Drop
                            onDrop={onDrop}
                            onDragOver={onDragOver}

                            //Updating Edges
                            onEdgeUpdateStart={onEdgeUpdateStart}
                            onEdgeUpdate={onEdgeUpdate}
                            onEdgeUpdateEnd={onEdgeUpdateEnd}

                            //Deleting Nodes and Edges
                            deleteKeyCode={["Backspace","Delete"]}
                        >
                            <MiniMap zoomable pannable/>
                            <Background />
                            <Controls className='controls'/>
                            <button onClick={() => modelSubmit(nodes, edges, 'admin', 'end')} className='train-button'>Train</button>
                            {parameterBars}
                        </ReactFlow>
                    </div>                    
                </ReactFlowProvider>

            </main>
        </>
    )
}

export default ModelBuilder