//Libary Imports 
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import useStore from "../store";
import { shallow } from "zustand/shallow";

//Component Imports
import { Tabs, Tab } from './Tabs';
import { getUniqueDataEntry, getFrequency, toggleEntrySetActiveness } from '../AxiosList';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CriticalConfirmation } from '../EUDEComponents/EUniqueDrugEntry';
import { SwitchButton } from '../SwitchButton';


//CSS and Image Imports
import '../../SCSS_Files/UniqueDrugEntryStyle.scss';
import 'react-alice-carousel/lib/alice-carousel.css';


//Graphical Tab for different Graphs
const GraphicalTab = ({ index, graphTitle, graphData, currentChart, frequency, height, dot }) => {
    //Graph data is a long text hence it has to be split
    let graphArray = graphData.split('|');
    let frequencyArray = frequency.split('|');

    //Data still needs to be filtered for 0 to 3 Thz
    let frequencyPair = [];
    var filteredData = [];
    var filteredFrequency = [];

    //Create a Frequency Pair for each graph column and frequency value
    for (var i = 0; i < frequencyArray.length; i++) {
        var pair = [];
        pair.push(frequencyArray[i]);
        pair.push(graphArray[i]);
        frequencyPair.push(pair);
    };


    // Filter the frequency to extract only 0 to 3 THz of data
    for (var j = 0; j < frequencyPair.length; j++) {
        if (frequencyPair[j][0] <= 3 && frequencyPair[j][0] >= 0) {
            filteredFrequency.push(parseFloat(frequencyPair[j][0]).toFixed(1) + 'Hz');
            filteredData.push(parseFloat(frequencyPair[j][1]));
        };
    };

    // Return a line chart for the filtered data, as well its stacked chart
    return (
        <div className={currentChart == index ? ('row graph-component active') : ('row graph-component')}>
            <div className='entry-graph'>
                <h3 id='title'>{graphTitle} Plot</h3>
                <div className='chart'>
                    <LinePlot xAxis={filteredFrequency} yAxis={filteredData} height={height} dot={dot} />
                </div>
            </div>
        </div>
    );
};

const LinePlot = ({ xAxis, yAxis, height, dot }) => {
    let lineData = []

    xAxis.map((item, key) => {
        lineData.push({
            x: item, amplitude: yAxis[key]
        })
    })

    const DataFormater = (number) => {
        if (number > 1000000000) {
            return (number / 1000000000).toString() + 'B';
        } else if (number > 1000000) {
            return (number / 1000000).toString() + 'M';
        } else if (number > 1000) {
            return (number / 1000).toString() + 'K';
        } else {
            return number.toString();
        }
    }


    return (
        <ResponsiveContainer width="100%" height={height}>
            <LineChart
                data={lineData}
                margin={{
                    top: 5,
                    right: 0,
                    left: 30,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="x" />
                <YAxis tickFormatter={DataFormater} label={{ value: 'Amplitude', angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="amplitude" stroke="#8884d8" activeDot={{ r: 2 }} dot={false} />
            </LineChart>
        </ResponsiveContainer>
    )
}


//Main Unique Drug Entry Page
const UniqueDrugEntry = () => {

    const [UniqueDrugEntry, setUniqueDrugEntry] = useState([]);

    //Chart States
    const [currentChart, setCurrentChart] = useState(null);
    const [currentChartType, setCurrentChartType] = useState('Smooth')
    const [currentEntry, setCurrentEntry] = useState(0);
    const [frequency, setFrequency] = useState([]);

    const [deleteBox, setDeleteBox] = useState(false)

    // Admin Editing Page management
    const adminRole = sessionStorage.getItem('role') == 'admin'
    const [adminHotbar, setAdminHotbar] = useState(false)

    const navigate = useNavigate();

    //Access Store for notification
    const {  setNotification ,setLoadingActiveness } = useStore(
        (state) => ({
            setLoadingActiveness: state.setLoadingActiveness,
            setNotification: state.setNotification
        }),
        shallow
    );


    //Get UDE Index from session storage for current entry data retrieval
    let udeIndex = sessionStorage.getItem('udeIndex');

    //Retrieve Frequency and UDE Data
    useEffect(() => {
        if (sessionStorage.getItem('userId') === 'undefined') {
            navigate('/');
        }

        setLoadingActiveness(true)
        getUniqueDataEntry(setUniqueDrugEntry, udeIndex);
        getFrequency(setFrequency, 1);
    }, []);

    
    useEffect(() => {
        setTimeout(() => {
            setLoadingActiveness(false)
        }, 1000)

        
    }, [UniqueDrugEntry])


    // Toggle Activeness of entry
    const changeActiveness = () => {
        // Integrate Status Update Function
        toggleEntrySetActiveness(
            UniqueDrugEntry.entrySetId,
            setNotification
        )
    }

    return (
        <>
            <main className='uniquedrugentry main-content'>
                <div className="scroll-wrap">

                {
                    adminRole &&
                    <div className={adminHotbar ? ("removal-hotbar") : ("removal-hotbar inactive")}>
                        <span>Admin Control Panel</span>
                        <button id="delete" onClick={() => setDeleteBox(true)}>Delete</button>
                        {
                            UniqueDrugEntry.activationStatus &&
                            <SwitchButton label='Active' defaultValue={UniqueDrugEntry.activationStatus} onChangeEvent={changeActiveness} />
                        }

                        <div className="hotbar-opener" onClick={() => setAdminHotbar(!adminHotbar)}>
                            <i className='bx bx-chevron-down'></i>
                        </div>

                        <div className={adminHotbar ? ("hotbar-label inactive") : ("hotbar-label")}>Admin Tools</div>
                    </div>
                }

                <div className='camel'><NavLink to='/archivesummary'>Data archive</NavLink> <i className='bx bx-chevron-right'></i> {UniqueDrugEntry.fileName}</div>
                {/* Tabs for different things in the future */}
                <Tabs>
                    <Tab title="Details"  >
                        <section className="entry-section">
                            <div className="file-details">
                                <h1 className="file-name">
                                    File: {UniqueDrugEntry.fileName}
                                </h1>
                                <h2 className="chemical-name">
                                    {UniqueDrugEntry.drugName}
                                </h2>
                                <div className="file-info">
                                    <div className="base-info">
                                        <div className="upload-details detail-box">
                                            <h3>Upload Details</h3>
                                            <div className="info">
                                                Upload Date: {UniqueDrugEntry.uploadedDate}
                                            </div>
                                            <div className="info">
                                                Uploader: {UniqueDrugEntry.uploadedBy}
                                            </div>
                                        </div>
                                        <div className="collection-details detail-box">
                                            <h3>Collection Details</h3>
                                            <div className="info">
                                                Collection Date: {UniqueDrugEntry.testDate}
                                            </div>
                                            <div className="info">
                                                Provider: {UniqueDrugEntry.providedBy}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sample-info">
                                        <div className="sample-details detail-box">
                                            <h3>Sample Details</h3>
                                            <div className="info">
                                                Form/Consistency: {UniqueDrugEntry.shape}
                                            </div>
                                            <div className="info">
                                                Mask Size: {UniqueDrugEntry.mask}
                                            </div>
                                            <div className="info">
                                                Package: {UniqueDrugEntry.filePackage}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chemical-image">
                                        {UniqueDrugEntry.drugMolecularStructureImageFileName ? (<img src={process.env.REACT_APP_ENCYCLOPEDIA_BUCKET_URL + UniqueDrugEntry.drugMolecularStructureImageFileName} />) : (null)}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="graphs">
                            {/* Map out Data Line Graphs for each type of data, powerlaw/powercoef/smooth/peaks */}
                            <div className='ude-analytics-tabs'>
                                {UniqueDrugEntry.experimentEntryDTO ? (UniqueDrugEntry.experimentEntryDTO.map((graphInformation, i) => {

                                    return (
                                        <div className={currentEntry == i ? ('ude-analytics-tab active') : ('ude-analytics-tab')} key={i}>
                                            <div className="hot-bar">
                                                {graphInformation.map((graph, index) => {
                                                    if (graph.fingerprintValues != undefined) {
                                                        return (
                                                            <button key={i + "_" + index} className={currentChart == index ? ('active') : ('')} onClick={() => {
                                                                setCurrentChart(index)
                                                                setCurrentChartType(graph.graphTitle)
                                                            }}>{graph.graphTitle}</button>
                                                        );
                                                    }
                                                })}
                                            </div>

                                            {graphInformation.map((graph, index) => {
                                                if (graph.fingerprintValues != undefined) {
                                                    if (currentChart == null) {
                                                        setCurrentChart(index)
                                                    }
                                                    return (
                                                        <GraphicalTab key={i + "_" + index} index={index} mainTitle={graph.mainTitle} graphTitle={graph.graphTitle} graphData={graph.fingerprintValues} currentChart={currentChart} frequency={frequency} height={400} dot={true} />
                                                    );
                                                }
                                            })}
                                        </div>
                                    )
                                })) : ('')
                                }
                                <div className="entry-select">
                                    <label htmlFor="ude-entry">Entry: </label>
                                    <select name="ude-entry" id="ude-entry" onChange={(e) => { setCurrentEntry(e.target.value) }}>
                                        {UniqueDrugEntry.experimentEntryDTO ? (UniqueDrugEntry.experimentEntryDTO.map((object, i) => {
                                            return <option key={i} value={i}>Entry {i}</option>
                                        })) : ('')}
                                    </select>
                                </div>
                            </div>
                        </section>
                    </Tab>
                    <Tab title="Encyclopedia Details">
                        <section className="entry-section">
                            <div className="file-details">
                                <h1 className="chemical-name">
                                    {UniqueDrugEntry.drugName}
                                </h1>
                                <h2>
                                    {UniqueDrugEntry.casNumber}
                                </h2>
                                <div className="file-info">

                                    <div className="chemical-info">
                                        <div className="upload-details detail-box">
                                            <h3>Baseline Chemical Info</h3>
                                            <div className="info">
                                                Molecular Formula: {UniqueDrugEntry.molecularFormula}
                                            </div>
                                            <div className="info">
                                                Control Type: {UniqueDrugEntry.controlType}
                                            </div>
                                        </div>

                                        <div className="description">
                                            {UniqueDrugEntry.description}
                                        </div>
                                    </div>

                                    <div className="chemical-image">
                                        {UniqueDrugEntry.drugMolecularStructureImageFileName ? (<img src={process.env.REACT_APP_ENCYCLOPEDIA_BUCKET_URL + UniqueDrugEntry.drugMolecularStructureImageFileName} />) : (null)}
                                    </div>
                                </div>
                            </div>

                        </section>
                    </Tab>
                    <Tab title="Graphical Comparison">
                        <section className="graphs-comparison">
                            {/* Map out Data Line Graphs for each type of data, powerlaw/powercoef/smooth/peaks */}
                            <div className='ude-analytics-tabs'>
                                {UniqueDrugEntry.experimentEntryDTO ? (UniqueDrugEntry.experimentEntryDTO.map((graphInformation, i) => {

                                    return (
                                        <div className={currentEntry == i ? ('ude-analytics-tab active') : ('ude-analytics-tab')} key={i}>
                                            <div className="hot-bar">
                                                {graphInformation.map((graph, index) => {
                                                    if (graph.fingerprintValues != undefined) {
                                                        return (
                                                            <button key={i + "_" + index} className={currentChart == index ? ('active') : ('')} onClick={() => {
                                                                setCurrentChart(index)
                                                                setCurrentChartType(graph.graphTitle)
                                                            }}>{graph.graphTitle}</button>
                                                        );
                                                    }
                                                })}
                                            </div>

                                            {graphInformation.map((graph, index) => {
                                                if (graph.fingerprintValues != undefined) {
                                                    if (currentChart == null) {
                                                        setCurrentChart(index)
                                                    }
                                                    return (
                                                        <GraphicalTab key={i + "_" + index} index={index} mainTitle={graph.mainTitle} graphTitle={graph.graphTitle} graphData={graph.fingerprintValues} currentChart={currentChart} frequency={frequency} height={300} dot={false} />
                                                    );
                                                }
                                            })}
                                        </div>
                                    )
                                })) : ('')
                                }
                                <div className="entry-select">
                                    <label htmlFor="ude-entry">Entry: </label>
                                    <select name="ude-entry" id="ude-entry" onChange={(e) => { setCurrentEntry(e.target.value) }}>
                                        {UniqueDrugEntry.experimentEntryDTO ? (UniqueDrugEntry.experimentEntryDTO.map((object, i) => {
                                            return <option key={i} value={i}>Entry {i}</option>
                                        })) : ('')}
                                    </select>
                                </div>
                            </div>
                            <div className="stacked-charts">
                                {
                                    UniqueDrugEntry.experimentEntryDTO ? (UniqueDrugEntry.experimentEntryDTO[0].map((graphInformation, i) => {
                                        return (
                                            <div key={i} className={graphInformation.graphTitle == currentChartType ? ('stacked-image active') : ('stacked-image')}>
                                                <div className="name">Stacked {graphInformation.graphTitle}</div>
                                                <img src={process.env.REACT_APP_ENCYCLOPEDIA_BUCKET_URL + graphInformation.stackedChartImageFileName} alt="" />
                                            </div>
                                        )
                                    })) : ('')
                                }
                            </div>
                        </section>
                    </Tab>
                </Tabs>
                </div>

            </main>
            {/* Critical Conformation box for deletion (Yet to be used) */}
            <CriticalConfirmation
                popupBox={deleteBox}
                setPopupBox={setDeleteBox}
                title={"Delete this entry?"}
                subtitle={
                    <>
                        <span>Just a heads up.</span> Deleting this entry will remove all information.
                        <br /><br />
                        Once removed, the data can <span>no longer be recovered</span>, please consider carefully
                        <br /><br />
                        If you're sure, please type <span>DELETE</span> in this box:
                    </>
                }
                confirmButton={
                    'Delete'
                }
                confirmationValue={
                    'DELETE'
                }
                confirmationFunction={() => console.log('Hello')}
            />
        </>
    );
};

export default UniqueDrugEntry;