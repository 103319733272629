//Library Imports
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import useStore from "../store";
import { shallow } from "zustand/shallow";


//Component Imports
import { getEncyclopediaFilters, filterEncyclopedia } from '../AxiosList';
import { stringBoolExtractor, range, isImage, randomIndex } from '../GeneralFunctions';
import PaginationBox from '../Pagination';


//CSS and Images
import '../../SCSS_Files/EncyclopediaStyle.scss';
import noChemical from '../../Icons/EncyclopediaIcons/noChemical.jpg'
import { useOutsideAlerter } from '../GeneralFunctions';
import defaultProfile from '../../Images/profile_sample.png'
import { SwitchButton } from '../SwitchButton'

//Encyclopedia Card used to showcase some information of the chemical
/* 
    Parameters -----
    id ----> EUDE index for later use
    imgSource ----> Image source from S3 for that chemical 
    title ---> Chemical Name
    description ---> Some chemical descriptive information
    updatedDate ---> updated date of chemical card if have
    createdBy.imageUrl --> user image
*/
const Card = (props) => {

    let cardDate = props.updatedDate ? (new Date(props.updatedDate)) : (new Date(props.createdDate))

    return (
        <>
            {/* Each Card has a link to its corresponding Encyclopedia Unique Entry Page */}
            <NavLink to='/EUDE'>
                <div className="chemical-card" onClick={() => sessionStorage.setItem("EUDEIndex", props.id)}>
                    <div className="chemical-image-container">
                        <img src={!isImage(props.imgSource.slice(-4)) ? (noChemical) : (props.imgSource)} alt='cgem' className="chemical-image" />
                        <div className="overlay"></div>
                        <h3 className="card-title">{props.title}</h3>
                    </div>

                    <div className='card-text'>
                        <p className="card-description">{props.description.length < 200 ? (props.description) : (props.description.slice(0, 200))}....</p>

                        <div className="bottom-section">
                            <div className="user-info">
                                <div className="user-name">{props.createdBy.userName}</div>
                                {props.createdBy.imageUrl ? (<img src={`${process.env.REACT_APP_AWS_BUCKET_URL}${props.createdBy.imageUrl}`} alt="" />) : (<img src={defaultProfile} alt="" />)}
                                <div className="latest-date">{props.updatedDate ? ('Updated On:') : ('Created On:')} <br /> {cardDate.toDateString()}</div>
                            </div>
                            <i className='bx bx-play-circle'></i>
                        </div>

                    </div>
                    <div className="overlay"></div>
                </div>
            </NavLink>
        </>
    );
};


// Filter Drop down element to hide and show filter boxes for neater representation
const CheckboxDropdown = (props) => {

    const [filterActive, setFilterActive] = useState(false)
    const wrapperRef = useRef(null);

    const handleOnChange = (position) => {
        const updatedStateList = props.stateList.map((item, index) => index === position ? !item : item);
        props.setStateList(updatedStateList)
    }

    useOutsideAlerter(wrapperRef, () => setFilterActive(false));



    return (
        <>
            <div className={filterActive ? 'checkbox-dropdown' : 'checkbox-dropdown closed'} ref={wrapperRef}>
                {/* Use the `open` state to conditionally change the direction of the chevron icon. */}
                <>
                    <div className="filter-title" onClick={() => setFilterActive(!filterActive)}>
                        {props.title} <i className='bx bx-chevron-down'></i>
                    </div>

                    <div className={'checkbox-container'}>
                        <div className="title">{props.title}</div>
                        {props.options &&
                            props.options.map((option, index) => {
                                if (typeof option === 'object') {
                                    let keys = Object.keys(option)
                                    return (
                                        <div className='filter-category' key={index}>
                                            <input type="checkbox" name={option[keys[0]]} id={option[keys[1]]} onChange={() => handleOnChange(index)} />
                                            <label >{option[keys[1]]}</label>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <div className='filter-category' key={index}>
                                            <input type="checkbox" name={option} id={option} onChange={() => handleOnChange(index)} />
                                            <label >{option}</label>
                                        </div>
                                    )
                                }

                            })
                        }
                    </div>
                </>

            </div >
        </>
    )
}



//Filter bar meant for filtering Encyclopedia Information, Can be used for other things 
/*
    Parameters ------
    setFilterActive --> Manage Activeness of advanced filter bar
    filterActive ---> Activeness of filter bar
    chemicalList --> State to set chemical list in encyclopedia
    setChemicalList -->
    setPageableResultsDTO --> State to manage pagination
    pageableResultsDTO --> pagination object state  
    batchSize --> Size of Items per Page
    setSorterType --> State to set the current sorter 
    sorterType --> Sort Object to manage sorting type
    setNotification --> Notification state management
    filters --> Current Filters applied
    setFilters --> State to set the record of existing filters
    setFilterChanged --> State to manage filter changing detection
    setDataMetrics --> State management for data metrics
    showActive --> State to display active and inactive results
    setShowActive --> State management for setting activeness
*/
const Filter = (props) => {

    // Statet for filter bar state 
    const [encyclopediaFilters, setEncyclopediaFilters] = useState({})
    const [sortableFields, setSortableFields] = useState([])

    // State to handle categories, subtypes and properties group checking
    const [categoryState, setCategoryState] = useState([])
    const [subtypeState, setSubtypeState] = useState([])
    const [propertiesState, setPropertiesState] = useState([])

    // Resets the entire filter bar and relevant states 
    // const resetFilter = () => {
    //     setCategoryState(new Array(encyclopediaFilters.listOfCategoryDetails.length).fill(false))
    //     setSubtypeState(new Array(encyclopediaFilters.controlTypes.length).fill(false))
    //     setPropertiesState(new Array(encyclopediaFilters.listOfAttributeDetails.length).fill(false))
    // }

    // Submit filter values and render in changes 
    const submitFilter = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target)
        const filterObject = {
            'drugName': formData.get('chemicalName'),
            'categoryIds': stringBoolExtractor(encyclopediaFilters.listOfCategoryDetails, categoryState).map((a) => a.id),
            'attributeIds': stringBoolExtractor(encyclopediaFilters.listOfAttributeDetails, propertiesState).map((a) => a.id),
            'controlTypes': stringBoolExtractor(encyclopediaFilters.controlTypes, subtypeState),
        }

        // Retrive a sorted list with the new value
        filterEncyclopedia(
            props.setChemicalList,
            props.setPageableResultsDTO,
            0,
            props.batchSize,
            props.sorterType.sortField,
            props.sorterType.direction,
            props.setNotification,
            sessionStorage.getItem('userId'),
            filterObject,
            props.showActive,
            props.setDataMetrics
        )
        props.setFilters(filterObject)
        props.setFilterChanged(true)
    }

    // Encyclopedia filter information retrieval 
    useEffect(() => {
        getEncyclopediaFilters(setEncyclopediaFilters, setSortableFields)
    }, [])


    // When the filters are retrieved from DB, render them to the declared state 
    useEffect(() => {
        if (Object.keys(encyclopediaFilters).length !== 0) {
            setCategoryState(new Array(encyclopediaFilters.listOfCategoryDetails.length).fill(false))
            setSubtypeState(new Array(encyclopediaFilters.controlTypes.length).fill(false))
            setPropertiesState(new Array(encyclopediaFilters.listOfAttributeDetails.length).fill(false))
        }

    }, [encyclopediaFilters])


    return (
        <div className='filter-wrapper'>
            {/* Enclosed in a form for easier submission */}
            <form onSubmit={submitFilter}>
                <div className="input-boxes">

                    {/* Search Bar, sort and inactiveness/activness toggle feature */}
                    <div className="search">
                        <button className="filter-button" type='submit'>
                            <i className='bx bx-search'></i>
                        </button>
                        <input type="text" placeholder='Search Chemical Name...' className='name-filter' name='chemicalName' />
                    </div>
                    <div className="sort">
                        <div className="sort-label">
                            Sort By:
                        </div>
                        <select onChange={(e) => {
                            props.setSorterType({ ...props.sorterType, [`${'sortField'}`]: e.target.value })

                            filterEncyclopedia(
                                props.setChemicalList,
                                props.setPageableResultsDTO,
                                props.pageableResultsDTO.existingPage,
                                props.batchSize,
                                e.target.value,
                                props.sorterType.direction,
                                props.setNotification,
                                sessionStorage.getItem('userId'),
                                props.filters,
                                props.showActive,
                                props.setDataMetrics
                            )
                        }}>
                            {/* Maps our the sort types possible */}
                            {
                                sortableFields.map((field) => {
                                    return (
                                        <option key={field} value={field}>{field.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + field.replace(/([A-Z])/g, " $1").slice(1)}</option>
                                    )
                                })
                            }

                        </select>
                        <div className="order">
                            <i className={props.sorterType['direction'] == 'ascending' ? 'bx bxs-up-arrow active' : 'bx bxs-up-arrow'} onClick={() => {
                                props.setSorterType({ ...props.sorterType, [`${'direction'}`]: 'ascending' })

                                filterEncyclopedia(
                                    props.setChemicalList,
                                    props.setPageableResultsDTO,
                                    props.pageableResultsDTO.existingPage,
                                    props.batchSize,
                                    props.sorterType.sortField,
                                    'ascending',
                                    props.setNotification,
                                    sessionStorage.getItem('userId'),
                                    props.filters,
                                    props.showActive,
                                    props.setDataMetrics
                                )
                            }}></i>
                            <i className={props.sorterType['direction'] == 'descending' ? 'bx bxs-down-arrow active' : 'bx bxs-down-arrow'} onClick={() => {
                                props.setSorterType({ ...props.sorterType, [`${'direction'}`]: 'descending' })

                                filterEncyclopedia(
                                    props.setChemicalList,
                                    props.setPageableResultsDTO,
                                    props.pageableResultsDTO.existingPage,
                                    props.batchSize,
                                    props.sorterType.sortField,
                                    'descending',
                                    props.setNotification,
                                    sessionStorage.getItem('userId'),
                                    props.filters,
                                    props.showActive,
                                    props.setDataMetrics
                                )
                            }}></i>
                        </div>
                    </div>
                    {
                        ['admin', 'superadmin'].includes(sessionStorage.getItem('role')) &&
                        <div className="show-active">
                        <SwitchButton defaultValue="INACTIVE" label='Show Inactive' onChangeEvent={() => {
                            props.setShowActive(!props.showActive)
                            filterEncyclopedia(
                                props.setChemicalList,
                                props.setPageableResultsDTO,
                                0,
                                props.batchSize,
                                props.sorterType.sortField,
                                props.sorterType.direction,
                                props.setNotification,
                                sessionStorage.getItem('userId'),
                                props.filters,
                                !props.showActive,
                                props.setDataMetrics
                            )
                            props.setActivenessChanged(true)

                        }}/>
                        </div>
                    }
                </div>

                {/* Advanced filters for drop down checkboxes */}
                <div className={props.filterActive ? ("advanced-filters active") : ("advanced-filters")}>

                    <div className="opener" onClick={() => props.setFilterActive(!props.filterActive)}>
                        <div className="closed-label">Advanced Filters</div>
                        <i className='bx bxs-down-arrow' ></i>
                    </div>
                    <div className="advanced-filters-container">
                        <div className='flex-wrapper'>

                            <div className="filter-section checkbox-filter">
                                <CheckboxDropdown options={encyclopediaFilters.listOfCategoryDetails} title="Chemical Category" stateList={categoryState} setStateList={setCategoryState} />
                            </div>
                            <div className="filter-section checkbox-filter">
                                <CheckboxDropdown options={encyclopediaFilters.controlTypes} title="Chemical Subtype" stateList={subtypeState} setStateList={setSubtypeState} />
                            </div>
                            <div className="filter-section  checkbox-filter">
                                <CheckboxDropdown options={encyclopediaFilters.listOfAttributeDetails} title="Properties" stateList={propertiesState} setStateList={setPropertiesState} />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    )
}

//Main Encyclopedia Page
/* 
    Shoud display all chemicals found in data base that the user is able to checkout as cards
    Should allow users to search and filter for specific chemicals
    Should allow for users to sort thegrid and list view
*/
const Encyclopedia = () => {

    // Page component management
    const [filterActive, setFilterActive] = useState(false)
    const [chemicalList, setChemicalList] = useState([]);
    const [pageableResultsDTO, setPageableResultsDTO] = useState({})
    const [dataMetrics, setDataMetrics] = useState({})

    // Sorter state management
    const [sorterType, setSorterType] = useState({ sortField: 'drugName', direction: 'descending' })

    //Activeness Management
    const [ showActive, setShowActive ] = useState(false)

    //Encyclopedia Filter Object
    const [filters, setFilters] = useState({
        "drugName": "",
        "categoryIds": [],
        "attributeIds": [],
        "controlTypes": [],
    })

    //Filter change detection
    const [filterChanged, setFilterChanged] = useState(false)
    const [activenessChanged, setActivenessChanged] = useState(false)

    // Pagingation batch size (to be converted to malleable state)
    const pageOptions = [12, 16, 28, 40]
    const [batchSize, setBatchSize] = useState(pageOptions[0])

    const navigate = useNavigate();

    //Access Store for notification and loading page
    const { setNotification, setLoadingActiveness } = useStore(
        (state) => ({
            setNotification: state.setNotification,
            setLoadingActiveness: state.setLoadingActiveness
        }),
        shallow
    );


    // Get All encyclopedia data API 
    useEffect(() => {
        if (sessionStorage.getItem('userId') === 'undefined') {
            navigate('/');
        }

        setLoadingActiveness(true)

        //Collect Details for the EUDE Id
        filterEncyclopedia(
            setChemicalList,
            setPageableResultsDTO,
            0,
            batchSize,
            sorterType.sortField,
            sorterType.direction,
            setNotification,
            sessionStorage.getItem('userId'),
            filters,
            showActive,
            setDataMetrics
        )
    }, []);


    useEffect(() => {
        setTimeout(() => {
            setLoadingActiveness(false)
        }, 1000)
        
    }, [chemicalList])

    return (
        <>
            <main className={'main-content encyclopedia-page'}>
                <div className="encyclopedia-section">
                    <div className='chemical-encyclopedia-header'>
                        <div className="title">Chemical Encyclopedia</div>
                        <div className="subtitle">Learn more abour chemicals as well as their respective traits an properties. Log new chemical information for your own use</div>
                    </div>
                    <section className="encyclopedia-section main-section">
                        {/* Filter section */}
                        <Filter
                            filterActive={filterActive}
                            setFilterActive={setFilterActive}
                            chemicalList={chemicalList}
                            setChemicalList={setChemicalList}
                            pageableResultsDTO={pageableResultsDTO}
                            setPageableResultsDTO={setPageableResultsDTO}
                            batchSize={batchSize}
                            setSorterType={setSorterType}
                            sorterType={sorterType}
                            setNotification={setNotification}
                            filters={filters}
                            setFilters={setFilters}
                            setFilterChanged={setFilterChanged}
                            setDataMetrics={setDataMetrics}
                            showActive={showActive}
                            setShowActive={setShowActive}
                            setActivenessChanged = {setActivenessChanged}

                        />
                    </section>

                    {/* Metrics section */}
                    <section className='information-section'>
                        <div className="information-block">
                            <div className="title">Total Entries</div>
                            <div className="value">{dataMetrics.totalEntries}</div>
                        </div>
                        {
                            dataMetrics.metrics && dataMetrics.metrics.map((metric, key) => {
                                return (
                                    <div className="information-block" key={key}>
                                        <div className="title">{metric.metricName}</div>
                                        <div className="value">{metric.metricCount}</div>
                                    </div>
                                )
                            })
                        }
                    </section>
                    <div className="chemical-header">
                        <div className="chemical-title">Encyclopedia</div>
                        <button className="create-button" onClick={() => navigate('/eudeCreate')}>+ Create</button>
                    </div>
                    {/* Chemical display section */}
                    <section className='chemicals'>
                        {
                            chemicalList.map((values) => {
                                return (
                                    <Card
                                        updatedDate={values.updatedDate}
                                        createdDate={values.createdDate}
                                        imgSource={`${process.env.REACT_APP_ENCYCLOPEDIA_BUCKET_URL}${values.drugImageFilePath}`}
                                        title={values.drugName}
                                        description={values.description}
                                        id={values.drugId}
                                        key={values.drugId}
                                        createdBy={values.createdBy}


                                    />
                                );
                            })
                        }
                    </section>

                    {/* Pagination section */}
                    <section className="pagination-section">
                        {pageableResultsDTO.totalPages > 0 && <PaginationBox
                            retrievalFunction={filterEncyclopedia}
                            setStateList={setChemicalList}
                            setPageableResultsDTO={setPageableResultsDTO}
                            pageableResultsDTO={pageableResultsDTO}
                            sorterType={sorterType}
                            batchSize={batchSize}
                            setNotification={setNotification}
                            maxPageBoxes={5}
                            filters={filters}
                            setBatchSize={setBatchSize}
                            pageOptions={pageOptions}
                            filterChanged={filterChanged}
                            setFilterChanged={setFilterChanged}
                            showActive={showActive}
                            setDataMetrics={setDataMetrics}
                            activenessChanged ={activenessChanged}
                            setActivenessChanged ={setActivenessChanged}
                        />}
                    </section>
                </div>
            </main>
        </>
    );
};

export default Encyclopedia;
export { CheckboxDropdown }