//Library Imports
import React, { useEffect, useState } from "react";
import useStore from "../store.js";
import { shallow } from 'zustand/shallow';
import Calendar from 'moedim';
import { useForm } from "react-hook-form";
import moment from "moment/moment.js";


//Component Imports
import { getAllChemicalName, getDevicesBySystem, getProcessingSetBySystemId, uploadSubmission, getAllDataArchiveEntries} from "../AxiosList.js";
import { getBase64 } from "../GeneralFunctions.js";
import { idDeviceReference } from "./UploadPopup.js";
import { subListing } from "../ConditionalChecks.js";

//CSS and Image Imports
import prismIcon from '../../Icons/UploadIcons/prismIcon.png'

//Global Form component
/* 
    Should Retrieve all of the neccessary drop down information based on device type
    Should allow users to submit and verify every form before proceeding
    Parameters-----
    activeStep --> Integer for carousel active step
    device --> device for that form containing id name and index
    chemicalDropdown --> List of chemical names 
*/
const GlobalForm = (props) => {

    // Form States for dropdowns and dates
    const [collectionDate, setCollectionDate] = useState(new Date())
    const [deviceSerialOptions, setDeviceSerialOptions] = useState([])
    const [processingSetOptions, setProcessingSetOptions] = useState([])
    
    //Enabling and Disabling Forms State 
    const [formDisabled, setFormDisabled] = useState(false)

    
    //Notification state usage 
    const { setNotification, setGlobalSubmissionForms, removeGlobalSubmissionForms, organisedSubmissionFiles, referenceSubmissionFiles} = useStore(state => ({ 
        setNotification: state.setNotification, 
        setGlobalSubmissionForms: state.setGlobalSubmissionForms,
        removeGlobalSubmissionForms: state.removeGlobalSubmissionForms,
        organisedSubmissionFiles : state.organisedSubmissionFiles ,
        referenceSubmissionFiles: state.referenceSubmissionFiles
    }), shallow);


    //  Use Form State for verification
    const { register, reset, handleSubmit, formState: { errors } } = useForm();

    // Verify form upon submission
    const verifyForm = (data) => {
        if (formDisabled) {
            // In the case I edit the form, remove item from globalSubmissionForms as well as enable the form for editing
            setNotification('Editing Enabled', 'This form can now be editted please verify again after completion.', 'info')
            setFormDisabled(false)
            removeGlobalSubmissionForms(props.device.deviceId)
        } else {
            // In the case I verify the form, I will disable it, as well as set GlobalSubmissionForm with form data as well as reference Id and datafile List 
            setNotification('Form Verified', 'This form has been verified, to edit please click the edit button.', 'success')
            setFormDisabled(true)
            data['referenceFileId'] = referenceSubmissionFiles[props.device.deviceId]['referenceFileId']
            data['dataFiles'] = organisedSubmissionFiles[props.device.deviceId]
            data['dataFileCollectionDate'] = collectionDate
            setGlobalSubmissionForms(props.device.deviceId, data)
        }
    }

    //Due to multiple permutations for each global form, this is used to append classes if conditions are fulfilled
    const formConditioning = () => {
        let className = "global-form" 
        if (props.activeForm === props.device.deviceId) {
            className += " active"
        }

        if (formDisabled === true) {
            className += " global-form-disabled"
        }

        return className
    }

    // Effect occurs to pull information needed for form
    useEffect(() => {
        getDevicesBySystem(setDeviceSerialOptions, sessionStorage.getItem('companyId'), props.device.deviceId)
        getProcessingSetBySystemId(setProcessingSetOptions, props.device.deviceId)
    }, [])

    // Effect that resets form when data is retrieved
    useEffect(() => {
        if (processingSetOptions.length !== 0) {
            reset({"chemicalName":"Amphetamine","processingSet": `${processingSetOptions[0].processingSetId}`})
        }
    }, [processingSetOptions])

    // Effect that enables form and resets from
    useEffect(() => {
        if (props.activeStep > parseInt(props.stepCount)) {
            setFormDisabled(false)
            reset()
        }
    }, [props.activeStep])

    // Effect that enables form when organized submission false is changed
    useEffect(()=> {
        setFormDisabled(false)
        removeGlobalSubmissionForms(props.device.deviceId)
    }, [organisedSubmissionFiles])
    

    return (
        <form className={formConditioning()} onSubmit={handleSubmit(verifyForm)} >
            <div className="global-form-container">
                <div className="global-form-section">
                    {/* Chemical Name */}
                    <div className="global-form-label">
                        Chemical Name
                    </div>
                    <select name="chemicalName" id="chemicalName" className="global-form-input" {...register('chemicalName')}>
                        {props.chemicalDropdown.map((chemical, key) => {
                            return(
                                <option value={chemical} key={key}>{chemical}</option>
                            )
                        })}
                    </select>                            
                    <div className="global-form-subsection">
                        {/* Device Used For Data Collection */}
                        <div className="global-form-input-entry">
                            <div className="global-form-label">
                                Device
                            </div>
                            <select name="device" id="device" className="global-form-input" {...register('deviceSerial')}>
                                {deviceSerialOptions.map((device) => {
                                    return(
                                        <option key={device.deviceSerial} value={device.deviceSerial}>{device.deviceName}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="global-form-input-entry">
                            {/* Form of Substance */}
                            <div className="global-form-label">
                                Form
                            </div>
                            <select name="form" id="form" className="global-form-input" {...register('chemicalForm')}>
                                <option value="Pellet">Pellet</option>
                                <option value="Powder">Powder</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="global-form-section">
                    {/* Collection Date of Substance */}
                    <div className="global-form-label">
                        Date of Collection
                    </div>
                    <Calendar value={collectionDate} onChange={(d) => setCollectionDate(d)} />
                </div>  
            </div>
            <div className="global-form-container">
                <div className="global-form-subsection">
                    <div className="global-form-input-entry">
                        {/* Humidity Temp and Mask */}
                        <div className="global-form-label">
                            Humidity
                        </div>
                        <input type="number" placeholder="25" className="global-form-input" id="humidity" name="humidity" min="0" max="100" {...register("humidity", { required: true })}/>
                        {errors.humidity && (errors.humidity.type == 'required' && <p className="form-error">Required*</p>)}
                    </div>
                    <div className="global-form-input-entry">
                        <div className="global-form-label">
                            Temperature
                        </div>
                        <input type="number" placeholder="27" className="global-form-input" id="temperature" name="temperature" min="0" max="100" {...register("temperature", { required: true })}/>
                        {errors.temperature && (errors.temperature.type == 'required' && <p className="form-error">Required*</p>)}
                    </div>
                    <div className="global-form-input-entry">
                        <div className="global-form-label">
                            Mask
                        </div>
                        <input type="number" placeholder="8" className="global-form-input" id="mask" name="mask" min="0" max="100" {...register("mask", { required: true })}/>
                        {errors.mask && (errors.mask.type == 'required' && <p className="form-error">Required*</p>)}
                    </div>
                </div>
                <div className="global-form-input-entry">
                    {/* Window Type Used */}
                    <div className="global-form-label">
                        Window
                    </div>
                    <select name="window" id="window" className="global-form-input" {...register("window")}>
                        <option value="Tukey">Tukey</option>
                    </select>
                </div>
                <div className="global-form-input-entry">
                    {/* Type of Processing Set Based on device  */}
                    <div className="global-form-label">
                        Processing Set
                    </div>
                    <select name="processingSet" id="processingSet" className="global-form-input"  {...register("processingSet")}>
                        {processingSetOptions.map((processingSet) => {
                            return(
                                <option key={processingSet.processingSetId} value={processingSet.processingSetId}>{processingSet.processingSetName}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="global-form-input-entry">
                    {/* Type of Processing Set Based on device  */}
                    <div className="global-form-label">
                        Package Type
                    </div>
                    <input type="text" name='package-type' id='package-type' className="package-type global-form-input" placeholder="Plastic" {...register("packageType", { required: true, pattern: {
                        value: /^[A-z]+$/i,
                        message: "No Special Characters"
                    }})}/>
                </div>
            </div>
            <button className="global-form-verify-button">{formDisabled?('Edit'):('Verify')}</button>
            <div className={formDisabled?("global-form-verified active"):("global-form-verified")}>Verified</div>
            <i className={formDisabled?('bx bxs-badge-check active'):('bx bxs-badge-check')}></i>
        </form>
    )
}


//Main slide for general info forms
/* 
    Should display all devices detected from dataFlePage
    Should allow users to select each device 1 by 1 
    Should allow users to key in values for each device that are applied to all 
    Parameters-----
    activeStep --> Integer for carousel active step
    stepCount --> current Step Number

*/
const GlobalFormSlide = (props) => {

    const [chemicalDropdown, setChemicalDropdown] = useState([])
    const [activeForm, setActiveForm] = useState(null)
    
    const [deviceButtons, setDeviceButtons] = useState([])
 
    // remove global submission Forms when specific is being implemented-----------------
    const { referenceSubmissionFiles,globalSubmissionForms, detectedDevices, setNotification } = useStore(state => ({
        referenceSubmissionFiles: state.referenceSubmissionFiles,
        globalSubmissionForms: state.globalSubmissionForms,
        detectedDevices: state.detectedDevices,
        setNotification: state.setNotification
    }), shallow);

    const finalUploadSubmission = async () => {
        let dataFileDetails = []

        console.log(detectedDevices, Object.keys(globalSubmissionForms))

        if (subListing([...(new Set(Object.values(detectedDevices)))], Object.keys(globalSubmissionForms), 0, 0)) {
            setNotification('Missing information', 'Please fill in all forms and verify them before proceeding', 'error')
            return
        }

        await Promise.all(Object.keys(globalSubmissionForms).map( async (deviceId) => {
            
            let globalSubmissionFormsReformatted = {
                'systemId': deviceId,
                "chemicalName": globalSubmissionForms[deviceId]['chemicalName'],
                'shape':  globalSubmissionForms[deviceId]['chemicalForm'],
                "dataFileCollectedDate": moment(globalSubmissionForms[deviceId]['dataFileCollectionDate']).format('YYYY-MM-DD'),
                'dataFileUploadedBy': sessionStorage.getItem('userId'),
                'dataFileDeviceSerial': globalSubmissionForms[deviceId]['deviceSerial'],
                'dataFileWindowMethod': globalSubmissionForms[deviceId]['window'],
                "dataFileHumidity": globalSubmissionForms[deviceId]['humidity'],
                "dataFileTemperature": globalSubmissionForms[deviceId]['temperature'],
                "dataFileMask":  globalSubmissionForms[deviceId]['mask'],
                "dataFilePackageType": globalSubmissionForms[deviceId]['packageType'],
                "dataFiles": await Promise.all(globalSubmissionForms[deviceId]['dataFiles'].map(async file => await getBase64(file))),
                'dataBucketFilePath':'',
                "dataFileNames": globalSubmissionForms[deviceId]['dataFiles'].map(file => file.name),
                "dataFileSizes": globalSubmissionForms[deviceId]['dataFiles'].map(file => file.size),
                "processingSetId": globalSubmissionForms[deviceId]['processingSet'],
                'refFileId': globalSubmissionForms[deviceId]['referenceFileId']
            }
            dataFileDetails.push(
                globalSubmissionFormsReformatted
            )
        }))

        uploadSubmission(dataFileDetails, [], ()=> {
            props.setActiveStep(parseInt(props.stepCount)+1)
            props.filterDataArchive()
        })

        
    }

    //Triggers a device check event that occurs when the user is onthe third slide
    useEffect(() => {
        if (props.activeStep == 3) {

            let devices = []

            // console.log(referenceSubmissionFiles)
            Object.keys(referenceSubmissionFiles).map((referenceKey) => {
                devices.push({deviceId: referenceKey, deviceName: idDeviceReference[referenceKey], listIndex: devices.length})
            })
            setDeviceButtons(devices)

            getAllChemicalName(setChemicalDropdown, sessionStorage.getItem('userId'))
            setActiveForm(Object.keys(referenceSubmissionFiles)[0])
        }
    }, [props.activeStep])

    return (
        <>
            <div className="global-forms-slide">
                <div className="device-selection-container">
                    <div className="slide-header">
                        <div className="slide-title"><span className="step-count">{props.stepCount}.</span> {props.header}</div>
                        <div className="slide-subtitle">Key in general information applied to all entries</div>
                    </div>
                    <div className="device-array-container">
                        <div className="device-array">
                            {deviceButtons.map((device) => {
                                return(
                                    <div key={device.deviceId} className={activeForm === device.deviceId?("device active"):("device")} onClick={() => setActiveForm(device.deviceId)}><span>{device.deviceName}</span></div>
                                )
                            })}
                        </div>
                        <div className="prism-connection-icon">
                            <img src={prismIcon} alt="prismIcon" className="prism-icon" />
                        </div>
                    </div>
                </div>
                {
                    deviceButtons.map((device)=>{
                        return(
                            <GlobalForm chemicalDropdown={chemicalDropdown} device={device} activeForm={activeForm} setActiveForm={setActiveForm} key={device['deviceId']} activeStep={props.activeStep} stepCount={props.stepCount}/>
                        )
                    })
                }    

                <button className="complete-upload" onClick={finalUploadSubmission}>Complete Submission</button>
            </div>
        </>
    );
};

export { GlobalFormSlide }