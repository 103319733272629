//Library Imports
import React, { useEffect, useRef, useState  } from 'react';

//CSS and Image Imports
import raman_icon from "../../Icons/ModelSelectionIcons/Raman.png";
import xs_icon from "../../Icons/ModelSelectionIcons/XS-TDS-01.png";
import fc_icon from "../../Icons/ModelSelectionIcons/FC-TDS-01.png";
import { getAllDeviceEntries, deployDevice  } from '../AxiosList';

//Device Deployment Drag and Drop
const DeviceDragNDrop = ({list, setList}) => {
    let deviceObject = {
        'Raman': raman_icon, 
        'XS-TDS-01': xs_icon,
        'FC-TDS-01': fc_icon
    };

    const [dragging, setDragging] = useState(false);

    const dragItem = useRef();
    const dragNode = useRef();

    //When Dragging add listener
    const handleDragStart = (e, params) => {
        dragItem.current = params;
        dragNode.current = e.target;
        dragNode.current.addEventListener('dragend', handleDragEnd);
        setTimeout(() => {
            setDragging(true);
        }, 0);
    };

    //When Dragging to correct box, add a tag and change the list    
    const handleDragEnter = (e, params) => {
        const currentItem = dragItem.current;
        if (e.target !== dragNode.current) {
            setList(oldList => {
                let newList = JSON.parse(JSON.stringify(oldList));
                newList[params.grpI].items.splice(params.itemI, 0, newList[currentItem.grpI].items.splice(currentItem.itemI, 1)[0]);
                dragItem.current = params;
                return newList;
            });
        };
    };

    // Remove Event Listerner when user let go
    const handleDragEnd = () => {
        setDragging(false);
        dragNode.current.removeEventListener('dragend', handleDragEnd);
        dragItem.current = null;
        dragNode.current = null;
    };

    //Change CSS class when dragging
    const getStyles = (params) => {
        const currentItem = dragItem.current;
        if (currentItem.grpI === params.grpI && currentItem.itemI === params.itemI) {
            return 'current dnd-item';
        };
        return 'dnd-item';
    };

    return (
        <div className="drag-n-drop">
            {/* Drag and Drop List, 2 lists [original and new] */}
            {list.map((grp, grpI) => (
                <div
                    key={grp.title}
                    onDragEnter={dragging && !grp.items.length ? (e) => handleDragEnter(e, { grpI, itemI: 0 }) : null}
                    className="dnd-group"
                >
                    <div className='dnd-group-items'>
                        {/* For each list, map out the items inside */}
                        {grp.items.map((item, itemI) => {
                            return (
                                <div
                                    draggable
                                    onDragStart={(e) => { handleDragStart(e, { grpI, itemI }) }}
                                    onDragEnter={dragging ? (e) => { handleDragEnter(e, { grpI, itemI }) } : null}
                                    className={dragging ? getStyles({ grpI, itemI }) : "dnd-item"}
                                >
                                    <span id='dnd-filename'>{item.deviceName}</span>
                                    <span id='dnd-drugname'>{item.version}</span>
                                    <span id='dnd-img'><img src={deviceObject[item.systemName]} alt="device icon" /></span>
                                </div>
                            );
                        })}
                    </div>
                </div>

            ))};
        </div>
    );
};

//Drag and Drop Popup Element 
const ModelDevicePopup = (props) => {
    const [list, setList] = useState([]);
    const [resetItems, setResetItems] = useState([]);
    const userId = sessionStorage.getItem('userId');

    //Get all available deployment devices
    useEffect(() => {
        getAllDeviceEntries(setResetItems, setList, userId);
    }, []);

    return (
        <>
        <div className={props.trigger ? ('test-popup') : ('test-popup inactive')}>
            <div className='popup-inner'>
                <button className='close-btn' onClick={() => props.setTrigger(false)}><i className ='bx bx-x'></i></button>
                <div className='row'>
                    <div className='column dnd-column'>
                        <div className="drag-title">Select all of the devices you want to deploy your model to.</div>
                        <input type="text" placeholder="search" ></input>
                    </div>
                    <div className="verticalLine"></div>
                    <div className='column dnd-column'>
                        <div className="select-title">Selected Devices</div>
                    </div>
                </div>
                {/* Onclick Submit the model to deploy and devices to deploy to  */}
                <button id="details-done-btn" onClick={() => {
                    props.setTrigger(false);
                    let indexList = [];

                    if (list[1].items.length === 0) {
                        alert('No files Selected');
                        return;
                    };
                    list[1].items.map((set) => {
                        indexList.push(set.deviceId);
                    })
            
                    let deviceSubmit = {
                        modelId:props.modelId,
                        deviceIds: indexList,
                        userId: parseInt(userId)
                    };
                    //Send Call
                    deployDevice(deviceSubmit);
                    props.setModelId(null);
                    
                }}>Done</button>

                <DeviceDragNDrop list={list} setList={setList}/>
            </div>
        </div>
        </>
    );
};

//Model Selection Section, to select those that have completed training so that can deploy in devices
const ModelSelected = (props) => {
    return(
        <>
            <div className="model-selected">
                <div className="model-title">Model Name: {props.modelName}</div>
                <div className="entry-count">Data Entries Used: <span>{props.entryCount}</span></div>
                <div className="date-details">
                    <div className="created-date">Created On: <span>{props.createdDate}</span></div>
                    <div className="created-by">By: <span>{props.creator}</span></div>
                </div>
                <div className="algorithm-name">Model Algorithm: <span>{props.modelType}</span></div>
                <div className="accuracy">
                    <div>Accuracy</div>
                    <div className="training">Training:<span>{props.trainAccuracy}%</span></div>
                    <div className="testing">Testing:<span>{props.testAccuracy}%</span></div>
                </div>
            </div>
        </>
    );
};

//Main Deployment Pop up 
const DeploymentPopup = (props) => {
    const [devicePopup, setDevicePopup] = useState(false);
    const [details, setDetails] = useState(<div className="no-models-selected">No Models Selected</div>);
    const [modelId, setModelId] = useState(null);

    return (
        <>
            <div className={props.trigger ? ('model-input-popup') : ('model-input-popup inactive')}>
                <div className='popup-inner'>
                    <button className='close-btn' onClick={() => props.setTrigger(false)}><i className ='bx bx-x'></i></button>
                    <div className='row'>
                        <div className="drag-title">Select a model you wish to create a test case on.</div>
                        <input type="text" placeholder="search" ></input>
                        <div className='model-chooser'>
                            <div className='model-select-container'>
                                {props.models.map((model) => {
                                    //If the model has completed training, create this component and show details
                                    if (model.modelStatus === 'TRAINED') {
                                        return(
                                            <div className='model-name' onClick={() => {
                                                // setModelID(model.modelId)
                                                setModelId(model.modelId);
                                                setDetails(
                                                    <ModelSelected
                                                        modelName = {model.modelName}
                                                        entryCount = {model.entryCount}
                                                        createdDate = {model.createdDate}
                                                        modelType = {model.modelType}
                                                        trainAccuracy = {model.modelAccuracy.trainAccuracy}
                                                        testAccuracy = {model.modelAccuracy.testAccuracy}
                                                    />
                                                );}
                                            }>{model.modelName}</div>
                                        )
                                    }
                                })}

                            </div>
                            <div className="model-selected">
                                {details}
                            </div>
                        </div>
                        {/* OnClick move to device pop up */}
                        <button className='model-select-btn' onClick={() => {
                            if (modelId !==null) {
                                props.setTrigger(false);
                                setDevicePopup(true);
                            };
                        }}>Done</button>
                    </div>
                </div>
            </div>
            <ModelDevicePopup trigger={devicePopup} setTrigger={setDevicePopup} modelId={modelId} setModelId={setModelId}/>
        </>
    );
};

export default DeploymentPopup;