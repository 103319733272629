// Library Imports 
import React from 'react';
import { useForm } from "react-hook-form"
import useStore from "../store";
import { shallow } from "zustand/shallow";
import { useNavigate } from "react-router-dom";

// Component Imports 
import { passwordCheck } from "../GeneralFunctions";
import { newUserPassword } from '../AxiosList';

// CSS and Images
import '../../SCSS_Files/ResetPasswordPage.scss'


// Reset Password Page meant to allow users to reset their passwords upon first login 
/*
    title --> Title to display
    subttle --> Subtitle of the reset page
*/
const ResetPasswordPage = (props) => {

    const { register, handleSubmit, getValues, formState: { errors } } = useForm();

    //React Router State for navigation
    const navigate = useNavigate();

    //Access Store for notification
    const { setNotification } = useStore(
        (state) => ({
            setNotification: state.setNotification
        }),
        shallow
    );

    // Password Reset Request
    const resetPassword = (data) => {

        newUserPassword(
            sessionStorage.getItem('resetUserName'),
            sessionStorage.getItem('anorPassword'),
            data.newPassword,
            setNotification,
            navigate,
            '/dataarchive'
        )
    }

    return (
        <div className="reset-page-wrapper">
            <div className="reset-page">
                <section className="reset-header">
                    <h1 className="title">{props.title}</h1>
                    <h3 className="subtitle">
                        {props.subtitle}
                    </h3>
                </section>
                <section className="reset-body">
                    {/* Password Submission Form */}
                    <div className="reset-form">
                        <div className="title">
                            Reset Password
                        </div>
                        <form onSubmit={handleSubmit(resetPassword)}>
                            <label htmlFor="name">
                                New Password
                            </label>
                            <input type="password" className="name" name='' placeholder="Key In New Password..."  {...register("newPassword", { required: true, validate: passwordCheck, maxLength: 20, minLength: 8 })} />
                            {errors.newPassword && (errors.newPassword.type == 'required' && <p className='form-error'>Value is Requried</p>)}
                            {errors.newPassword && (errors.newPassword.type == 'validate' && <p className='form-error'>Must have at least 1 special character, 1 digit, 1 capital and 1 lower letter</p>)}
                            {errors.newPassword && (errors.newPassword.type == 'maxLength' && <p className='form-error'>Password must be between 8 to 20 characters</p>)}
                            {errors.newPassword && (errors.newPassword.type == 'minLength' && <p className='form-error'>Password must be between 8 to 20 characters</p>)}

                            <label htmlFor="name">
                                Confirm Password
                            </label>
                            <input type="password" className="name" name='confirmPassword' placeholder="Confirm Password..."  {...register("confirmPassword", {
                                required: true, validate: (value) => {
                                    const { newPassword } = getValues();
                                    return newPassword == value;
                                }
                            })} />
                            {errors.confirmPassword && (errors.confirmPassword.type == 'required' && <p className='form-error'>Value is Requried</p>)}
                            {errors.confirmPassword && (errors.confirmPassword.type == 'validate' && <p className='form-error'>Passwords should match!</p>)}

                            <button className="submit" type="submit">
                                Reset
                            </button>
                        </form>
                    </div>

                    {/* Criteria Box that displays password criterias */}
                    <div className="criteria">
                        <div className='title'>Password Criterias</div>
                        <div className="description">
                            To Reset the password, there are some criterias to follow as stated below
                        </div>
                        <ul>
                            <li>Password must be 8 to 30 characters long</li>
                            <li>Password must contain at least 1 special characters</li>
                            <li>Password must contain at least 1 number</li>
                            <li>Password must contain a mix of upper and lower case alphapbets (at least 1 each)</li>
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    )
}

export { ResetPasswordPage }