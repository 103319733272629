import React from "react"
import { Link } from "react-router-dom"

import errorBot from '../Icons/GeneralIcons/404Bot.png'

// Page to display not found
export default function NotFound() {
    return (
        <div className={sessionStorage.getItem('userId') == undefined?("error-404 overlay"):("error-404")}>
            <img src={errorBot} alt="error" className="error-bot"/>
            <h2>Page Not Found :/</h2>
            <p>The page you are looking for doesn't exist or an other error occured. Go to <Link to='/'>Home</Link> Page</p>
        </div>
    )   
}