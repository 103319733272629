import React from "react";
import '../SCSS_Files/Loading.scss'
import useStore from "./store";
import { shallow } from "zustand/shallow";

// Loading Page to display a loading screen 
const LoadingPage = () => {

    const { loadingActiveness } = useStore(
        (state) => ({
            loadingActiveness: state.loadingActiveness
        }),
        shallow
    );


    return (
        <div className={loadingActiveness?"loading":"loading inactive"}>
            <div className="ring">
            <span></span>
            </div>
        </div>
    )
}


export { LoadingPage }