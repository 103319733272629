//Library Imports
import React, { useEffect, useState } from 'react'
import useStore from "../store";
import { shallow } from "zustand/shallow";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form"

// Component Imports 
import { getUserDetails, updateProfile } from '../AxiosList';
import { specialCharCheck, getBase64 } from '../GeneralFunctions';
import { PasswordChangeInput, PasswordVerificationInput } from '../DataSelectionComponents/DetailInput';

// CSS and Images
import defaultProfile from '../../Images/profile_sample.png'
import '../../SCSS_Files/ProfileViewerStyle.scss'

// Mime type to check accepted images
const imageMimeType = /image\/(png|jpg|jpeg)/i;



// Profile Picture Editor used to allow users to add in new images
/*
    Parameters ---------
    profileUrl --> Existing Profile pic
    setNewImage --> state to set new picture for submission
*/
const ProfilePictureEditor = (props) => {
    // State file and fileDataURL
    const [file, setFile] = useState(null)
    const [fileDataURL, setFileDataURL] = useState(null);

    // When the file is changed, initiate function for Mime Validation and to set the file
    const changeHandler = (e) => {
        const file = e.target.files[0];
        if (!file.type.match(imageMimeType)) {
            alert("Image mime type is not valid");
            return;
        }
        setFile(file);
    }

    // Effect is then launched when file is edited else
    useEffect(() => {

        let fileReader, isCancel = false;

        // If there is a file 
        if (file) {

            //Launch File Reader 
            fileReader = new FileReader();

            // The load event is fired when a file has been read successfully
            fileReader.onload = (e) => {
                const { result } = e.target;

                // If there is file and process not canelled set the file data url for preview
                if (result && !isCancel) {
                    setFileDataURL(result)
                    sessionStorage.setItem('userImage', result)
                    //Insert Image File to Compiler
                    props.setNewImage(file)
                }
            }
            fileReader.readAsDataURL(file);
        }

        return () => {

            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }

    }, [file]);

    return(
        <div className="user-image">
            {/* 
                If Image exist place image, if new image has been placed, show new image and if no image, show profile default pic
            */}
            {fileDataURL?(<img src={fileDataURL} alt="preview" className="user-image-content" />):(props.profileUrl?(<img src={`${process.env.REACT_APP_AWS_BUCKET_URL}${props.profileUrl}`} alt="" className="user-image-content" />):(<img src={defaultProfile} alt="" className="user-image-content" />))}
            <div className="overlay">
                <i className='bx bx-pencil' ></i>
                <input type="file" onChange={changeHandler}/>
            </div>
        </div>
    )
}


// Profile Viewer that allows users to update their images and particulars
const ProfileViewer = () => {

    // States to manage profile viewer data as well as submissions for info change
    const [userDetails, setUserDetails] = useState({})
    const [changeDetected, setChangeDetected] = useState(false)
    const [newImage, setNewImage] = useState(undefined)

    // Passowrd Change Popup State management
    const [passwordPage, setPasswordPage] = useState(0)
    const [oldPassword, setOldPassword] = useState()

    //Access Store for notification amd loading page 
    const { setNotification, setLoadingActiveness } = useStore(
        (state) => ({
            setNotification: state.setNotification,
            setLoadingActiveness: state.setLoadingActiveness
        }),
        shallow
    );

    const navigate = useNavigate()
    
    const { register, reset, handleSubmit, formState: { errors } } = useForm();

    // Function handler to change user details and images
    const changeUserDetails = async (data) => {
        let convertedImage;

        // If change is detected, prepare submission
        if (changeDetected || newImage !== userDetails.profileUrl) {
            if (newImage instanceof File) {
                convertedImage = await getBase64(newImage)
            } else {
                convertedImage = newImage
            }

            const details = {   
                "email": data.email,
                "firstName":data.firstName,
                "lastName": data.lastName,
            }

            // Update profile and refresh account
            updateProfile(
                sessionStorage.getItem('userId'),
                details,
                convertedImage,
                setNotification,
                () => {
                    
                    setTimeout(() => {
                        window.location.reload(false)
                    }, 1000)
                    sessionStorage.setItem('email', details.email)

                }
            )
        }
    }

    // Retrieve User details
    useEffect(() => {
        if (sessionStorage.getItem('userId') === 'undefined') {
            navigate('/');
        }

        setLoadingActiveness(true)

        getUserDetails(
            sessionStorage.getItem('userId'),
            setUserDetails,
            setNotification
        )

    }, [])


    // Close Loading Page
    useEffect(() => {

        setNewImage(userDetails.profileUrl)
        
        setTimeout(() => {
            setLoadingActiveness(false)
        }, 1000)

    }, [userDetails])

    // Reset form
    useEffect(() => {
        if (Object.keys(userDetails).length !== 0) {
            reset({
                'firstName': userDetails.firstName,
                'lastName': userDetails.lastName,
                // 'userName': userDetails.userName,
                'email': userDetails.email,
            })
        }
    }, [userDetails])


    return (
        <>
            <main className="profile-viewer main-content">
                <div className="bg">
                </div>
                {
                    userDetails &&

                    <form className="profile-card" onSubmit={handleSubmit(changeUserDetails)}>
                        <ProfilePictureEditor profileUrl={`${userDetails.profileUrl}`} setNewImage={setNewImage}/>
                        <button className="save-changes">Save Changes</button>
                        <h3 className='user'>{userDetails.fullName} <span>({userDetails.accountRole})</span></h3>
                        <h4 className="name">Company: {userDetails.companyDetails && userDetails.companyDetails.companyName}</h4>

                        <div className="input-box">
                            <label htmlFor='firstName'>First Name:</label>
                            <input type="text" name='firstName' {...register("firstName", { required: true, validate: specialCharCheck })} onChange={() => setChangeDetected(true)} />
                        </div>

                        <div className="input-box">
                            <label htmlFor='LastName'>Last Name:</label>
                            <input type="text" name='LastName' {...register("lastName", { required: true, validate: specialCharCheck })} onChange={() => setChangeDetected(true)} />
                        </div>

                        <div className="input-box">
                            <label htmlFor='userName'>Email:</label>
                            <input type="email" name='email'  {...register("email", { required: true })} onChange={() => setChangeDetected(true)} />
                        </div>

                        <div className="password-change">
                            <button className="change-password" type='button' onClick={() => setPasswordPage(true)}>Change Password</button>

                        </div>

                    </form>
                }


            </main>

            {/* Popups slider for password verification */}
            <div className="popup-wrapper">
                <div className={passwordPage == 1 ? 'slide' : 'slide inactive'}>
                    <PasswordVerificationInput setNotification={setNotification} axiosAfterEvent={(oldPassword) => {
                        setPasswordPage(2)
                        setOldPassword(oldPassword)
                    }} closeSlide={() => {
                        setPasswordPage(0)
                        setOldPassword('')
                    }} />
                </div>

                <div className={passwordPage == 2 ? 'slide' : 'slide inactive'}>
                    <PasswordChangeInput setNotification={setNotification} axiosAfterEvent={() => setPasswordPage(0)} oldPassword={oldPassword} closeSlide={() => {
                        setPasswordPage(0)
                        setOldPassword('')
                    }} />
                </div>
            </div>
        </>
    )
}

export {
    ProfileViewer, ProfilePictureEditor
}