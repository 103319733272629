//Library Imports
import React, { useState, useEffect, useRef } from "react";

// Component Imports
import { getArchiveFilters, filterDataArchive } from "../AxiosList";
import { useOutsideAlerter, removeAttribute, stringBoolExtractor } from "../GeneralFunctions";
import PaginationBox from "../Pagination";
import { CheckboxDropdown } from "../EnclopediaComponents/EncyclopediaPage";

// CSS and Images
import '../../SCSS_Files/DataSelectionPopupStyle.scss'


// Filter Bar for Data selection pop up (Similar to Data Archive Filter Bar )
/*
    Parameters ------
    filterActive --->State to toggle advanced filters opening/closing
    setFilterActive --> state management for advanced filters
    archiveFilters ---> State for Avaliable fulters
    archiveData ---> Data Entry State 
    setArchiveData --> State Management for Data entry display
    archiveSortersAvailable --> Archive Sorter available
    filterChemical --> State contains currently selected chemical
    setFilterChemical --> State Management for currently selected chemical
    pageableResultsDTO --> State containing pagination object
    setPageableResultsDTO --> State Management for pagination object
    batchSize --> Size of page
    setSorterType --> State management for current selected sorter and direction
    sorterType --> State containing current sorter
    setNotification --> Notification State Management
    filters --> Current Filter Object
    setFilters --> State management for filter object
    setFilterChanged --> State management to detect filter changing 
    setDataMetrics --. State management for data metrics 
*/

const Filters = (props) => {

    // State management for components
    const [chemicalSelector, setChemicalSelector] = useState(false) //Drop down for chemcial selection

    // Outside Click detector for state management
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setChemicalSelector(false))

    const advancedFilterWrapperRef = useRef(null);
    useOutsideAlerter(advancedFilterWrapperRef, () => props.setFilterActive(false))

    // States to handle multi group checking
    const [dataPackaging, setDataPackaging] = useState([])
    const [dataProviders, setDataProviders] = useState([])
    const [dataMask, setDataMask] = useState([])
    const [dataForm, setDataForm] = useState([])
    const [dataMaterial, setDataMaterial] = useState([])
    const [dataProperties, setDataProperties] = useState([])
    const [dataProcessingStatus, setDataProcessingStatus] = useState([])


    // Submit filter values and render in changes 
    const submitFilter = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target)

        // Compile Entire Filter Object 
        const filterObject = {
            'dataFileName': formData.get('fileName'),
            "dataNames": [props.filterChemical.drugId],
            "dataPackaging": stringBoolExtractor(props.archiveFilters.dataPackaging, dataPackaging),
            "dataProvider": stringBoolExtractor(props.archiveFilters.dataProviders, dataProviders).map((a) => a.userId),
            "dataMask": stringBoolExtractor(props.archiveFilters.dataMask, dataMask),
            "dataForm": stringBoolExtractor(props.archiveFilters.dataForm, dataForm),
            "dataMaterial": stringBoolExtractor(props.archiveFilters.dataMaterial, dataMaterial).map((a) => a.categoryId),
            "dataProperties": stringBoolExtractor(props.archiveFilters.dataProperties, dataProperties).map((a) => a.attributeId),
            "dataProcessingStatus": stringBoolExtractor(props.archiveFilters.dataProcessingStatus, dataProcessingStatus),
        }

        // Filter API
        // Send Request to Filter Archive
        filterDataArchive(
            props.setArchiveData,
            props.setPageableResultsDTO,
            props.pageableResultsDTO.existingPage,
            props.batchSize,
            props.sorterType.sortField,
            props.sorterType.direction,
            props.setNotification,
            sessionStorage.getItem('userId'),
            filterObject,
            false,
            props.setDataMetrics,
        )

        // Declare that the filter object has changed as well as change the new filters
        props.setFilters(filterObject)
        props.setFilterChanged(true)

    }

    // When the filters are retrieved from DB, render them to the declared state for multi search checkboxes
    useEffect(() => {
        if (Object.keys(props.archiveFilters).length !== 0) {
            setDataPackaging(new Array(props.archiveFilters.dataPackaging.length).fill(false))
            setDataProviders(new Array(props.archiveFilters.dataProviders.length).fill(false))
            setDataMask(new Array(props.archiveFilters.dataMask.length).fill(false))
            setDataForm(new Array(props.archiveFilters.dataForm.length).fill(false))
            setDataMaterial(new Array(props.archiveFilters.dataMaterial.length).fill(false))
            setDataProperties(new Array(props.archiveFilters.dataProperties.length).fill(false))
            setDataProcessingStatus(new Array(props.archiveFilters.dataProcessingStatus.length).fill(false))
        }

    }, [props.archiveFilters])

    return (
        <div className="filters">
            {/* Enclosed in a form for easier submission */}

            {/* Search Bar, sort and inactiveness/activness toggle feature */}
            <form className="search" onSubmit={submitFilter} ref={advancedFilterWrapperRef}>
                <button className="filter-button" type='submit'>
                    <i className='bx bx-search'></i>
                </button>
                <input type="text" placeholder='Search File Name...' className='name-filter' name='fileName' />
                <button className="advanced-filters-button" onClick={() => props.setFilterActive(!props.filterActive)}>Advanced <i className='bx bx-slider-alt'></i></button>

                {/* Advanced filters for drop down checkboxes for each filter type*/}
                <div className={props.filterActive ? "advanced-filters-container" : "advanced-filters-container closed"} >
                    <div className='flex-wrapper'>
                        <div className="filter-section  checkbox-filter">
                            <CheckboxDropdown options={props.archiveFilters.dataMask} title="Mask Type" stateList={dataMask} setStateList={setDataMask} />
                        </div>
                        <div className="filter-section  checkbox-filter">
                            <CheckboxDropdown options={props.archiveFilters.dataMaterial} title="Material Type" stateList={dataMaterial} setStateList={setDataMaterial} />
                        </div>

                        <div className="filter-section  checkbox-filter">
                            <CheckboxDropdown options={props.archiveFilters.dataForm} title="Chemical Form" stateList={dataForm} setStateList={setDataForm} />
                        </div>

                        <div className="filter-section  checkbox-filter">
                            <CheckboxDropdown options={props.archiveFilters.dataPackaging} title="Package Type" stateList={dataPackaging} setStateList={setDataPackaging} />
                        </div>
                        <div className="filter-section  checkbox-filter">
                            <CheckboxDropdown options={props.archiveFilters.dataProcessingStatus} title="Processing Status" stateList={dataProcessingStatus} setStateList={setDataProcessingStatus} />
                        </div>
                        <div className="filter-section  checkbox-filter">
                            <CheckboxDropdown options={props.archiveFilters.dataProperties} title="Chemical Name" stateList={dataProperties} setStateList={setDataProperties} />
                        </div>
                        <div className="filter-section  checkbox-filter">
                            <CheckboxDropdown options={props.archiveFilters.dataProviders} title="Chemical Name" stateList={dataProviders} setStateList={setDataProviders} />
                        </div>
                    </div>
                </div>
            </form>
            {/* Filter to specifically select a chemical for viewing */}
            <div className={chemicalSelector ? "select-sort" : "select-sort inactive"} >
                <div className="select-chemical" ref={wrapperRef}>
                    <div className="select-box" onClick={() => setChemicalSelector(!chemicalSelector)}>
                        {props.filterChemical.drugName}
                        <i className='bx bx-chevron-down'></i>
                    </div>
                    <div className={'checkbox-container'}>
                        {
                            // Event and mapping for each chemical to call for a filter
                            Object.keys(props.archiveFilters).length !== 0 && props.archiveFilters.dataNames.map((dataName) => {
                                return (
                                    <div key={dataName.drugId} onClick={() => {
                                        props.setFilterChemical(dataName)
                                        props.setFilters({
                                            ...props.filters,
                                            'dataNames': [dataName.drugId]
                                        })
                                        setChemicalSelector(false)

                                    }} className="checkbox-item"><i className={props.filterChemical.drugName == dataName.drugName ? 'bx bx-check' : 'bx bx-check inactive'}></i>{dataName.drugName}</div>
                                )
                            })
                        }
                    </div>
                </div>

                {/* Sorting Feature */}
                <div className="sort">
                    <div className="sort-label">
                        Sort:
                    </div>
                    <select onChange={(e) => {
                        props.setSorterType({ ...props.sorterType, [`${'sortField'}`]: e.target.value })
                        filterDataArchive(
                            props.setArchiveData,
                            props.setPageableResultsDTO,
                            props.pageableResultsDTO.existingPage,
                            props.batchSize,
                            e.target.value,
                            props.sorterType.direction,
                            props.setNotification,
                            sessionStorage.getItem('userId'),
                            props.filters,
                            false,
                            props.setDataMetrics
                        )

                    }}>
                        {
                            props.archiveSortersAvailable && props.archiveSortersAvailable.map((sorter) => {
                                return (
                                    <option value={sorter} key={sorter}>
                                        {sorter.split('_').map((a) => a.charAt(0).toUpperCase() + a.slice(1)).join(' ')}
                                    </option>

                                )
                            })
                        }
                    </select>
                    <div className="order">
                        <i className={props.sorterType['direction'] == 'ascending' ? 'bx bxs-up-arrow active' : 'bx bxs-up-arrow'} onClick={() => {
                            props.setSorterType({ ...props.sorterType, [`${'direction'}`]: 'ascending' })
                            filterDataArchive(
                                props.setArchiveData,
                                props.setPageableResultsDTO,
                                props.pageableResultsDTO.existingPage,
                                props.batchSize,
                                props.sorterType.sortField,
                                'ascending',
                                props.setNotification,
                                sessionStorage.getItem('userId'),
                                props.filters,
                                false,
                                props.setDataMetrics
                            )
                        }}></i>
                        <i className={props.sorterType['direction'] == 'descending' ? 'bx bxs-down-arrow active' : 'bx bxs-down-arrow'} onClick={() => {
                            props.setSorterType({ ...props.sorterType, [`${'direction'}`]: 'descending' })
                            filterDataArchive(
                                props.setArchiveData,
                                props.setPageableResultsDTO,
                                props.pageableResultsDTO.existingPage,
                                props.batchSize,
                                props.sorterType.sortField,
                                'descending',
                                props.setNotification,
                                sessionStorage.getItem('userId'),
                                props.filters,
                                false,
                                props.setDataMetrics
                            )
                        }}></i>
                    </div>
                </div>
            </div>

        </div>
    )
}



//Data Selection Pop up for users to select data for specific use
/* 
    Parameters---------------------
    setNotification --> State management to send notifictaions
    changePrevFunction --> Function that happens to click next slide (optional)
    changeNextFunction --> Function that happens to click prev slide (optional)
    data --> Main data handling
    closeSlide --> Function that happens when closing the slide
*/
const DataSelectionPopup = (props) => {

    // Data Select Pop up component State management
    const [archiveData, setArchiveData] = useState([]);
    const [pageableResultsDTO, setPageableResultsDTO] = useState({})
    const [dataMetrics, setDataMetrics] = useState({})
    const [filterActive, setFilterActive] = useState(false) //Advanced filter opening
    const [cascadeSection, setCascadeSection] = useState(0) //Cascading feature

    // Possible Filters
    const [archiveFilters, setArchiveFilters] = useState([])
    const [archiveSortersAvailable, setArchiveSortersAvailable] = useState()

    // Check if filter is initiated
    const [filterChanged, setFilterChanged] = useState(false)

    // Selected File Management
    const [filterChemical, setFilterChemical] = useState({ drugId: '-1', drugName: 'Unselected' })

    // Sorter state management
    const [sorterType, setSorterType] = useState({ sortField: 'created date', direction: 'descending' })

    // Page Sizing
    const pageOptions = [5, 10, 15, 20]
    const [batchSize, setBatchSize] = useState(pageOptions[0])

    //Archive Filter Object
    const [filters, setFilters] = useState({
        "dataFileName": "",
        "dataNames": [],
        "dataPackaging": [],
        "dataProvider": [],
        "dataMask": [],
        "dataForm": [],
        "dataMaterial": [],
        "dataProperties": [],
        "dataProcessingStatus": [],
    })

    // Useeffect ot restrieve possibe filters 
    useEffect(() => {
        getArchiveFilters(setArchiveFilters, sessionStorage.getItem('userId'), setArchiveSortersAvailable)
    }, [])

    //Extra filter handler when available filter changes to immediately set a filter with the firt drug name
    useEffect(() => {
        if (Object.keys(archiveFilters).length !== 0) {
            // Gain current chemical to be retrieved
            setFilterChemical(archiveFilters.dataNames[0])

            // Generate Groups and replace 
            let chemicalObject = {}

            archiveFilters.dataNames.map((chemical) => {
                chemicalObject[chemical.drugName] = {}
            })
            props.setData(chemicalObject)
            setFilters({
                ...filters,
                'dataNames': [archiveFilters.dataNames[0].drugId]
            })
        }

    }, [archiveFilters])

    /* 
        Triggers when final submission data outside changes, 
        so that we generate a sub object inside for each chemical 
        (Only Occurs if final submission data is empty)
    */
    useEffect(() => {
        //
        try {
            if (Object.keys(props.data).length <= 0) {

                // Generate Groups
                let chemicalObject = {}

                archiveFilters.dataNames.map((chemical) => {
                    chemicalObject[chemical.drugName] = {}
                })

                props.setData(chemicalObject)
            }
        }

        catch {
            console.log('')
        }
    }, [props.data])


    /* 
        Triggers when filters change and drug Id is valid
        Calls filter function to filter archive
    */
    useEffect(() => {
        if (filterChemical.drugId !== '-1') {

            filterDataArchive(
                setArchiveData,
                setPageableResultsDTO,
                0,
                batchSize,
                sorterType.sortField,
                sorterType.direction,
                props.setNotification,
                sessionStorage.getItem('userId'),
                filters,
                false,
                setDataMetrics
            )

            setFilterChanged(true)
        }

    }, [filters])

    // Event handler to select entry
    const selectBox = (fileEntry) => {
        props.setData({
            ...props.data,
            [`${fileEntry.drugName}`]: { ...props.data[fileEntry.drugName], [`${fileEntry.entrySetId}`]: fileEntry }
        })
    }

    // Event Handler to remove selected entry 
    const removeBox = (fileEntry) => {
        props.setData({
            ...props.data,
            [`${fileEntry.drugName}`]: removeAttribute(props.data[fileEntry.drugName], fileEntry.entrySetId)
        })
    }

    // Event Handler to select all entries of a particular chemical
    const selectAll = (chemicalName) => {
        let allFiles = {}
        archiveData.map((entry) => {
            allFiles[entry.entrySetId] = entry
        })

        props.setData({
            ...props.data,
            [`${chemicalName}`]: { ...props.data[chemicalName], ...allFiles }
        })

    }

    // Event Handler to Remove all entries of a particular chemical
    const removeAll = (chemicalName) => {
        props.setData({
            ...props.data,
            [`${chemicalName}`]: {}
        })
    }

    // Handler to reset entier data selection feature
    const clearAll = () => {
        let chemicalObject = {}

        archiveFilters.dataNames.map((chemical) => {
            chemicalObject[chemical.drugName] = {}
        })
        props.setData(chemicalObject)
    }


    return (
        <div className="data-selection-popup">
            {/* Step Button that exists in the case of a next slide */}
            {
                props.changePrevFunction &&
                <div className="prev-button step-button" onClick={() => props.changePrevFunction()}>
                    <i className='bx bxs-chevron-left' ></i>
                </div>
            }
            <div className="data-selection-popup-content">
                <i className='bx bx-x close-popup' onClick={() => {
                    props.closeSlide()
                }}></i>

                {/* Box to handle data to be selected */}
                <section className="selection-box">
                    <h1 className="selection-box-title">
                        Accumulative Datasets
                    </h1>
                    {/* Data select Filter */}
                    <div className="filters-wrapper">
                        <Filters
                            archiveFilters={archiveFilters}

                            archiveData={archiveData}
                            setArchiveData={setArchiveData}
                            archiveSortersAvailable={archiveSortersAvailable}

                            filterChemical={filterChemical}
                            setFilterChemical={setFilterChemical}

                            pageableResultsDTO={pageableResultsDTO}
                            setPageableResultsDTO={setPageableResultsDTO}

                            batchSize={batchSize}

                            setSorterType={setSorterType}
                            sorterType={sorterType}

                            setNotification={props.setNotification}

                            filters={filters}
                            setFilters={setFilters}

                            setFilterChanged={setFilterChanged}

                            setDataMetrics={setDataMetrics}

                            filterActive={filterActive}
                            setFilterActive={setFilterActive}

                        />
                    </div>
                    <div className="selection-box-header">
                        <div className="title">
                            Active Sets
                        </div>
                        <button className="select-all" onClick={() => selectAll(filterChemical['drugName'])}>Select All</button>

                    </div>

                    {/* Data Selection display */}
                    <div className="file-display">

                        {/* Map and create a functional selectable data entry */}
                        {
                            props.data !== undefined && Object.keys(props.data).length > 0 &&

                                archiveData.length > 0 ? archiveData.map((entry, key) => {
                                    let selectedChemicalGroup = props.data[entry.drugName]

                                    return (<>
                                        {
                                            <div className={Object.keys(selectedChemicalGroup).includes(String(entry.entrySetId)) ? "entry-file selected" : "entry-file"} key={key}> 
                                                <div className="details">
                                                    <div className="file-name">
                                                        {entry.fileName}
                                                    </div>
                                                    <div className="shape">{entry.shape}</div>
                                                </div>
                                                <button className="transfer-button" onClick={
                                                    () => {
                                                        selectBox(entry)
                                                    }
                                                }>
                                                    <i className='bx bx-right-arrow-circle'></i>                                        </button>
                                            </div>

                                        }
                                    </>
                                    )
                                }) :
                                <div className="no-data-wrapper">
                                    <div className="no-data">
                                        No Entries Found
                                    </div>
                                </div>
                        }
                    </div>

                    {/* Pagination Componenet */}
                    {
                        pageableResultsDTO && pageableResultsDTO.totalPages && <PaginationBox
                            retrievalFunction={filterDataArchive}
                            setStateList={setArchiveData}
                            setPageableResultsDTO={setPageableResultsDTO}
                            pageableResultsDTO={pageableResultsDTO}
                            sorterType={sorterType}
                            batchSize={batchSize}
                            setNotification={props.setNotification}
                            maxPageBoxes={5}
                            filters={filters}
                            setBatchSize={setBatchSize}
                            pageOptions={pageOptions}
                            filterChanged={filterChanged}
                            setFilterChanged={setFilterChanged}
                            setDataMetrics={setDataMetrics}
                            showActive={false}
                        />
                    }
                </section>

                {/* Box to handle selected data  */}
                <section className="selected-box">
                    <div className="selected-box-header">
                        <h1 className="selected-box-title">Customize your data for data exportation</h1>
                        <h3 className="selected-box-subtitle">
                            Select the data you wish to compile and export as a zip file <button className="empty-files" onClick={() => clearAll()}>Clear All</button>
                        </h3>
                    </div>


                    {/* 
                        Smart mapping system to map groups and their respective selected entries 
                        Allows single, group and all clearing 
                    */}
                    <div className="selection-recieval">

                        {
                            Object.keys(props.data).map((chemicalCategory, key) => {
                                // console.log(chemicalCategory)

                                let selectedChemicalEntries = props.data[chemicalCategory]

                                return (
                                    <div className={Object.keys(selectedChemicalEntries).length === 0 ? "category-group no-chemicals" : "category-group"}>
                                        <div className="group-header">
                                            <h2 className="group-name">{chemicalCategory}</h2>
                                            <div className="actions">
                                                <button className="remove-chemical" onClick={() => removeAll(chemicalCategory)}>Clear chemical <i className='bx bx-x-circle'></i></button>
                                                <button className={cascadeSection === key ? "remove-chemical cascade" : "remove-chemical "} onClick={() => setCascadeSection(key)}>Show <i className='bx bxs-down-arrow'></i></button>
                                            </div>
                                        </div>
                                        <div className="selected-entries">
                                            <div className={cascadeSection === key ? "cascaded-display cascaded" : "cascaded-display "}>Hidden</div>
                                            <div className={cascadeSection === key ? "section-cut" : "section-cut cascaded"}>
                                                {
                                                    Object.keys(selectedChemicalEntries).map((selectedEntry, key) => {

                                                        let selectedChemicalEntry = selectedChemicalEntries[selectedEntry]

                                                        return (
                                                            <div className="selected-file" key={key}>
                                                                <div className="details">
                                                                    <div className="file-name">
                                                                        {selectedChemicalEntry.fileName}
                                                                    </div>
                                                                    <div className="shape">{selectedChemicalEntry.shape}</div>
                                                                </div>
                                                                <button className="return-button" onClick={
                                                                    () => {
                                                                        removeBox(selectedChemicalEntry)
                                                                    }
                                                                }>
                                                                    <i className='bx bx-x' ></i>
                                                                </button>

                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>
            </div>

            {/* Step Button that exists in the case of a next slide */}
            {
                props.changeNextFunction &&
                <div className="next-button step-button" onClick={() => {
                    const flattenedFiles = Object.values(props.data).map(a => Object.keys(a)).flat()

                    if (flattenedFiles.length > 0) {
                        props.changeNextFunction()

                    }
                }}>
                    <i className='bx bxs-chevron-right' ></i>
                </div>
            }
        </div>
    )
}

export { DataSelectionPopup }
