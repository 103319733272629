//Library Imports
import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form"

//Component Imports
import { getBase64, stringBoolExtractor, specialCharCheck, arraysEqual } from "../GeneralFunctions.js";
import { getEncyclopediaFilters, updateEncyclopediaEntry } from '../AxiosList.js';
import { useOutsideAlerter } from '../GeneralFunctions';

//CSS and Image Imports 
import '../../SCSS_Files/EUniqueChemicalEntryEditor.scss';


const imageMimeType = /image\/(png|jpg|jpeg)/i;


// Synonym Editor 
/* 
    Parameters ------------
    synonymElements ---> State to manage current synoyms
    setSynonymElements ---> event to set new or remove synoymns 
*/
const SynonymEditor = (props) => {

    // Synonym Update States 
    const [synonymChanger, setSynonymChanger] = useState(false)
    const [word, setWord] = useState(null)

    //handle page misclick to turn off 
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setSynonymChanger(false));

    // Add synonym if new 
    const synonymAddEvent = (event) => {
        if (props.synonymElements.includes(word)) {
            return
        }

        props.setSynonymElements([...props.synonymElements, word])

    }

    // Synonym Removal
    const synonymRemovalEvent = (word) => {
        props.setSynonymElements(props.synonymElements.filter(element => element !== word))
    }

    return (
        <div className="entry-input" ref={wrapperRef}>
            <div className="label">Synonyms <i className='bx bx-edit' onClick={() => setSynonymChanger(true)}></i></div>
            <div className="synonym-container">
                {
                    props.synonymElements.map((synonym) => {
                        return <div className="synonym" key={synonym} > {synonym} <i className='bx bx-x' onClick={() => synonymRemovalEvent(synonym)}></i></div>
                    })
                }

            </div>
            {/* Synonym Adder Popup */}
            <div className={synonymChanger ? ("synonym-creator-popup") : ("synonym-creator-popup closed")}>
                <div className="title">Add Synonym <i className='bx bx-x' onClick={() => setSynonymChanger(false)}></i></div>
                <input type="text" placeholder='Add...' onChange={(event) => setWord(event.target.value)} />
                <div className="add-synonym" onClick={synonymAddEvent}>Add</div>

            </div>
        </div>

    )
}



// Grouped Checkbox to select multiple items 
/*
    Parameters ------
    options ---> List of options to display 
    title ---> Checkbox title string
    stateList ---> State list to manage checked boxes with boolean list
    setStateList ---> Event to edit stateList

*/
const GroupedCheckbox = (props) => {

    // On Change event to update value for group checkbox
    const handleOnChange = (position) => {
        const updatedStateList = props.stateList.map((item, index) => index === position ? !item : item);
        props.setStateList(updatedStateList)
    }

    return (
        <div className="entry-input">
            <div className="label">{props.title}</div>
            <hr />
            <div className="properties-container">
                {props.options &&
                    props.propertiesObject &&
                    props.options.map((option, index) => {

                        let keys = Object.keys(option)

                        return (
                            <div className='property' key={index}>
                                <input type="checkbox" defaultChecked={!(props.propertiesObject[`${option.id}`] == undefined)} name={option[keys[0]]} id={option[keys[1]]} onChange={() => handleOnChange(index)} />
                                <label >{option[keys[1]]}</label>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}



// Image Comparison Component that allows users to preview and upload new images 
/*
    Parameter ----
    label ---> String title for image group
    oldImageLink ---> Old image url 
    imageEditsCompiler ---> object state to manage all images that have been updated
    setImageEditsCompiler ---> event to set new image updates 
    compilerIndex ---> index value to be used as key to update imageEditsCompiler
*/
const ImageComparison = (props) => {

    // State file and fileDataURL
    const [file, setFile] = useState(null)
    const [fileDataURL, setFileDataURL] = useState(null);

    // When the file is changed, initiate function for Mime Validation and to set the file
    const changeHandler = (e) => {
        const file = e.target.files[0];
        if (!file.type.match(imageMimeType)) {
            alert("Image mime type is not valid");
            return;
        }
        setFile(file);
    }

    // Effect is then launched when file is edited else
    useEffect(() => {

        let fileReader, isCancel = false;

        // If there is a file 
        if (file) {

            fileReader = new FileReader();
            // The load event is fired when a file has been read successfully
            fileReader.onload = (e) => {
                const { result } = e.target;

                // If there is file and process not canelled set the file data url for preview
                if (result && !isCancel) {
                    setFileDataURL(result)

                    //Insert Image File to Compiler
                    props.setImageEditsCompiler(imageEditsCompiler => ({ ...props.imageEditsCompiler, [`${props.compilerIndex}`]: file }))

                }
            }
            fileReader.readAsDataURL(file);
        }

        return () => {
            isCancel = true;

            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }
    }, [file]);


    return (
        <>
            <div className="entry-input">
                <div className="label">
                    {props.label}
                    {/* Image Previewer Not Yet for use */}
                    {/* <div className='preview-button' onClick={() => {
                        if (fileDataURL) {
                            props.setImagePreviewerState(true)
                            props.setNewURL(fileDataURL)
                            props.setOldURL(props.oldImageLink)
                        }
                    }}>
                        <i className='bx bxs-slideshow'></i>
                        <div className="tooltip">Preview</div>
                    </div> */}
                </div>
                <hr />
                <div className="image-comparison">
                    <div className="image-container">
                        <div className="image-overlay">
                            <div className="title">Old</div>
                            <img src={props.oldImageLink && props.oldImageLink} alt="" />
                        </div>
                        <div className="image-overlay">
                            <div className="title">New</div>
                            {fileDataURL ? (<img src={fileDataURL} alt="preview" />) : (<div className="image-template"></div>)}
                            <div className="overlay"></div>
                            <div className="upload">Upload</div>
                            <input type="file" onChange={changeHandler} />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}




// Editorial Page for admins to edit details of Chemical in Encyclopedia
const EncyclopediaEditor = (props) => {

    // States to manage chemcial attribuets and synonyms
    const [encyclopediaFilters, setEncyclopediaFilters] = useState({})
    const [propertiesState, setPropertiesState] = useState([])
    const [propertiesObject, setPropertiesObject] = useState('')
    const [synonymElements, setSynonymElements] = useState([])

    //State used to detect change to form values
    const [changeDetection, setChangeDetection] = useState(false)

    // Image Compiler object for image states to update
    const [imageEditsCompiler, setImageEditsCompiler] = useState({
        'thumbnail': props.thumbnailImg && props.thumbnailImg.split(process.env.REACT_APP_ENCYCLOPEDIA_BUCKET_URL)[1],
        'molecule': props.moleculeImg && props.moleculeImg.split(process.env.REACT_APP_ENCYCLOPEDIA_BUCKET_URL)[1],
        'stacked_smooth': props.stackedImages['stacked_smooth'] && props.stackedImages['stacked_smooth'].split(process.env.REACT_APP_ENCYCLOPEDIA_BUCKET_URL)[1],
        'stacked_power_law': props.stackedImages['stacked_power_law'] && props.stackedImages['stacked_power_law'].split(process.env.REACT_APP_ENCYCLOPEDIA_BUCKET_URL)[1],
        'stacked_power_coefficient': props.stackedImages['stacked_power_coefficient'] && props.stackedImages['stacked_power_coefficient'].split(process.env.REACT_APP_ENCYCLOPEDIA_BUCKET_URL)[1],
        'stacked_peaks': props.stackedImages['stacked_peaks'] && props.stackedImages['stacked_peaks'].split(process.env.REACT_APP_ENCYCLOPEDIA_BUCKET_URL)[1],
    })
    
    //Date to see if updated/not 
    let date = props.updatedDate ? (new Date(props.updatedDate)) : (new Date(props.createdDate))


    //  Use Form State for verification
    const { register, reset, handleSubmit, formState: { errors } } = useForm();

    //Update Chemical Entry Submission Event
    const updateChemicalEntry = async (data) => {
        let confirmedImages = {}
        let convertedImages = {}
        let isEmptyFileList = Object.values(imageEditsCompiler).filter(element => element instanceof File)

        let attributeList = stringBoolExtractor(encyclopediaFilters.listOfAttributeDetails, propertiesState).map((a) => String(a.id))

        // If change has been detected
        if (changeDetection || isEmptyFileList.length !== 0 || !arraysEqual(synonymElements, props.synonyms) || !arraysEqual(Object.keys(propertiesObject), attributeList)) {


            Object.keys(imageEditsCompiler).map((key) => {
                if (imageEditsCompiler[key] instanceof File) {
                    confirmedImages[key] = imageEditsCompiler[key]
                }
            })

            let convertedImageList = await Promise.all(Object.keys(confirmedImages).map(async key => await getBase64(confirmedImages[key])))

            Object.keys(confirmedImages).map((key, index) => {
                convertedImages[key] = convertedImageList[index]
            })

            const urlImageKeys = Object.keys(imageEditsCompiler).filter((key => {
                return !Object.keys(confirmedImages).includes(key)
            }))

            urlImageKeys.map((key) => {
                convertedImages[key] = imageEditsCompiler[key]
            })


            const moleculeImage = convertedImages['molecule']
            const thumbnailImage = convertedImages['thumbnail']

            const finalisedStackedImageObject = {
                "stackedChartImageSmoothEncodedFile": convertedImages['stacked_smooth'],
                "stackedChartImagePowerLawEncodedFile": convertedImages['stacked_power_law'],
                "stackedChartImagePowerCoefficientEncodedFile": convertedImages['stacked_power_coefficient'],
                "stackedChartImagePeaksEncodedFile": convertedImages['stacked_peaks']
            }


            updateEncyclopediaEntry(
                sessionStorage.getItem('userId'),
                props.drugId,
                data.chemicalName,
                data.chemicalCategory,
                data.casNumber,
                data.controlLevel,
                data.chemicalCode,
                data.description,
                'ACTIVE',
                attributeList,
                finalisedStackedImageObject,
                moleculeImage,
                thumbnailImage,
                () => props.navigate(props.navigateUrl),
                props.setNotification,
                synonymElements.join(',')
            )

            return
        }
    }

    // Retrieve Information form CMS
    useEffect(() => {
        getEncyclopediaFilters(setEncyclopediaFilters, (dummy) => { })
    }, [])

    useEffect(() => {
        if (props.synonyms) {
            setSynonymElements(props.synonyms.split(','))
        }
    }, [props.synonyms])

    
    // Upon data retrieval, update properties state 
    useEffect(() => {
        if (Object.keys(encyclopediaFilters).length !== 0) {
            let finalObj = {}

            props.properties.map((property) => {
                finalObj[property.encyclopediaEntryAttributeModel.encyclopediaEntryAttributeId] = property.encyclopediaEntryAttributeModel.encyclopediaEntryAttributeName
            })

            setPropertiesObject(finalObj)

            let booleanAttributeList = encyclopediaFilters.listOfAttributeDetails.map((attribute) => {
                if (finalObj[attribute.id]) {
                    return true
                }

                return false
            })

            setPropertiesState(booleanAttributeList)
        }

    }, [encyclopediaFilters])


    // Resets react hook forms for current encyclopedia values to be loaded in
    useEffect(() => {
        reset({
            "chemicalName": `${props.drugName}`,
            'casNumber': `${props.casNumber}`,
            'description': `${props.description}`,
            'chemicalCode': `${props.chemicalCode}`,
            'controlLevel': `${props.controlType}`,
            'chemicalCategory': `${props.chemicalCategory && props.chemicalCategory.encyclopediaEntryCategoryId}`
        })
    }, [props.description])


    return (
        <div className={props.adminEditing ? ('encyclopedia-editor') : ('encyclopedia-editor inactive')}>
            <div className='camel'>
                <NavLink to='/encyclopedia'>Encyclopedia</NavLink>
                <i className='bx bx-chevron-right'></i>
                {props.drugName}
                <i className='bx bx-chevron-right'></i>
                Editor Mode
            </div>

            <div className="header">
                <div className="title">
                    Chemical Editor
                    <button className='save-submit' type='submit' form="update-entry-form">Save Info</button>
                </div>
                <div className="subtitle">
                    Edit any chemical information or images for your co-workers to view
                </div>
                <div className="date">{props.updatedDate ? ('Updated Date:') : ('Created Date:')} {date.toDateString()}</div>
            </div>

            {/* Update Form */}
            <form onSubmit={handleSubmit(updateChemicalEntry)} id="update-entry-form">
                <div className="section">
                    <div className="entry-input">
                        <label htmlFor="chemicalName">Chemical Name</label>
                        <input type="text" name="chemicalName" id="chemicalName"  {...register("chemicalName", { required: true, validate: specialCharCheck })} onChange={() => setChangeDetection(true)} />
                        {errors.chemicalName && (errors.chemicalName.type == 'required' && <p className='form-error'>Value is Requried</p>)}
                        {errors.chemicalName && (errors.chemicalName.type == 'validate' && <p className='form-error'>No Special Charcters Except for ',' and '.' Allowed</p>)}
                    </div>

                    <div className="entry-input">
                        <label htmlFor="casNumber">CAS Number</label>
                        <input type="text" name="casNumber" id="casNumber"  {...register("casNumber", { required: true, validate: specialCharCheck })} onChange={() => setChangeDetection(true)} />
                        {errors.casNumber && (errors.casNumber.type == 'required' && <p className='form-error'>Value is Requried</p>)}
                        {errors.casNumber && (errors.casNumber.type == 'validate' && <p className='form-error'>No Special Charcters Except for ',' and '.' Allowed</p>)}
                    </div>

                    <div className="entry-input">
                        <label htmlFor="chemicalCode">Chemical Code</label>
                        <input type="text" name="chemicalCode" id="chemicalCode"  {...register("chemicalCode", { required: true, validate: specialCharCheck })} onChange={() => setChangeDetection(true)} />
                        {errors.chemicalCode && (errors.chemicalCode.type == 'required' && <p className='form-error'>Value is Requried</p>)}
                        {errors.chemicalCode && (errors.chemicalCode.type == 'validate' && <p className='form-error'>No Special Charcters Except for ',' and '.' Allowed</p>)}
                    </div>

                    <div className="entry-input">
                        <label htmlFor="controlLevel">Control Level</label>
                        <select name="controlLevel" id="controlLevel" defaultValue={props.controlType} {...register("controlLevel")} onChange={() => setChangeDetection(true)}>
                            <option value="COMMON">COMMON</option>
                            <option value="ILLICIT">ILLICIT</option>
                        </select>
                    </div>

                    <div className="entry-input">
                        <label htmlFor="chemicalType">Chemical Type</label>
                        <select name="chemcialCategory" id="chemicalCategory" {...register("chemicalCategory")} onChange={() => setChangeDetection(true)}>
                            {encyclopediaFilters.listOfCategoryDetails &&
                                encyclopediaFilters.listOfCategoryDetails.map((category, index) => {
                                    return (
                                        <option value={category.id} key={index}>{category.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    {/* Insert Checkbox */}
                    <GroupedCheckbox options={encyclopediaFilters.listOfAttributeDetails} title="Chemical Properties" stateList={propertiesState} setStateList={setPropertiesState} propertiesObject={propertiesObject} />


                    {/* Image Comparison For Thumbnail, Moelcule and Charts */}
                    <ImageComparison label='Thumbnail' oldImageLink={props.thumbnailImg} imageEditsCompiler={imageEditsCompiler} setImageEditsCompiler={setImageEditsCompiler} compilerIndex={'thumbnail'} setImagePreviewerState={props.setImagePreviewerState} setNewURL={props.setNewURL} setOldURL={props.setOldURL} />

                    <ImageComparison label='Molecular Model' oldImageLink={props.moleculeImg} imageEditsCompiler={imageEditsCompiler} setImageEditsCompiler={setImageEditsCompiler} compilerIndex={'molecule'} setImagePreviewerState={props.setImagePreviewerState} setNewURL={props.setNewURL} setOldURL={props.setOldURL} />

                </div>
                <div className="section" id='right-section'>
                    <div className="entry-input">
                        <label htmlFor="description">Description</label>
                        <textarea name="description" id="description" defaultValue={props.description} {...register("description", { required: true, validate: specialCharCheck })} onChange={() => setChangeDetection(true)} />
                        {errors.description && (errors.description.type == 'validate' && <p className='form-error'>No Special Charcters Except for ',' and '.' Allowed</p>)}
                    </div>

                    {/* Insert Synonym Editor */}
                    <SynonymEditor synonymElements={synonymElements} setSynonymElements={setSynonymElements} />


                    <ImageComparison key={'stacked_peaks'} label={'Stacked Peaks'} oldImageLink={props.stackedImages['stacked_peaks']} imageEditsCompiler={imageEditsCompiler} setImageEditsCompiler={setImageEditsCompiler} compilerIndex={'stacked_peaks'} setImagePreviewerState={props.setImagePreviewerState} setNewURL={props.setNewURL} setOldURL={props.setOldURL} />
                    <ImageComparison key={'stacked_smooth'} label={'Stacked Smooth'} oldImageLink={props.stackedImages['stacked_smooth']} imageEditsCompiler={imageEditsCompiler} setImageEditsCompiler={setImageEditsCompiler} compilerIndex={'stacked_smooth'} setImagePreviewerState={props.setImagePreviewerState} setNewURL={props.setNewURL} setOldURL={props.setOldURL} />
                    <ImageComparison key={'stacked_power_law'} label={'Stacked Power Law'} oldImageLink={props.stackedImages['stacked_power_law']} imageEditsCompiler={imageEditsCompiler} setImageEditsCompiler={setImageEditsCompiler} compilerIndex={'stacked_power_law'} setImagePreviewerState={props.setImagePreviewerState} setNewURL={props.setNewURL} setOldURL={props.setOldURL} />
                    <ImageComparison key={'stacked_power_coefficient'} label={'Stacked Power Coefficient'} oldImageLink={props.stackedImages['stacked_power_coefficient']} imageEditsCompiler={imageEditsCompiler} setImageEditsCompiler={setImageEditsCompiler} compilerIndex={'stacked_power_coefficient'} setImagePreviewerState={props.setImagePreviewerState} setNewURL={props.setNewURL} setOldURL={props.setOldURL} />


                </div>

            </form>


        </div>
    )
}

export { EncyclopediaEditor, GroupedCheckbox, SynonymEditor }