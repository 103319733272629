import React, {useEffect} from "react";

//Function to swap key and values in objects
const ObjectSwapKey = obj => Object.fromEntries(Object.entries(obj).map(a => a.reverse()))

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, action) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                action()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}


//Object Null Checker to detect and reassign values related to null as a means of consistency
/*
    Parameters -----
    obj ---> Object to check

    Return ----
    obj ---> Consistent Nullified Object
*/
const nullChecker = (obj) => {
    const nullKeys = ['undefined', 'null', '0', 0]

    Object.keys(obj).map((key) => {
        if (nullKeys.includes(obj[key] || obj[key].trim().length === 0) || obj[key] === '') {
            obj[key] = null
        }
    })
    return obj
}

// Check if overall object is empty 
const undefinedClassifier = (object) => {
    const isNullish = Object.values(object).every(value => {
        if (!value || value.length == 0) {
          return true;
        }
      
        return false;
    });
    return(isNullish)
} 

// File Size Calculator
/*
    Parameters-----
    size ---> Int (Bytes)
*/
const fileSize = (size) => {
    var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
}


// Delete Attribute from Object
/*
    Parameters-----
    obj ---> Object to delete from
    attribute ---> Attribut the person wishes to delete (String)
*/
const removeAttribute = (obj, attribute) => {
    let indirectObject = obj
    delete indirectObject[attribute]
    return indirectObject
}


//Asynchronous Function to get base 64 for uploaded file
function getBase64(file) {
    var reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onerror = () => {
            reader.abort();
            reject(new DOMException("Error parsing file"));
        };
        // read file content
        reader.onload = () => {
            resolve(reader.result.split(',')[1]);
        }
        // convert data to base64
        reader.readAsDataURL(file);
    })
}




//Compare String list with booolean list, extract true values in string list 
/* 
    Example [1,2,3] [true, false, true]
    Extract [1,3]
    Parameters -----
    stringList --> list of strings to extract
    booleanList --> List of booleans to compare

    Return ----
    extractedList
*/

const stringBoolExtractor = (stringList, booleanList, captureIndexInstead=false) => {
    let extractedList = stringList.map((string, index) => {
        if (booleanList[index] == true) {
            if(captureIndexInstead) {
                return index 
            }
            return string
        }
    })

    extractedList = extractedList.filter(( element ) => {
        return element !== undefined;
    });

    return extractedList
}


// Range genrator between 2 numbers 
function range(start, end) {
    if (end < 0) {
        return []
    }
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
}
  


// Image Checker, Checks if a link is an image based on its extensions
/* 
    Parameters ------
    url --> Image URL
*/
function isImage(url) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

//Special Character Checker, Check if string contains special characters to prevent sql injection
/*
    Parameters ------
    item ----> String value to check
*/
function specialCharCheck(item) {

    const specialChars = /[`@#$%^&*_+\=\[\]{}'"\\|<>\/~]/;
    return !specialChars.test(item);
}


//Random Index generator 
function randomIndex(max, min) { 
    // console.log(max, min)
    return Math.floor(Math.random() * (max - min) + min)
}


// Check if Arrays are equal 
function arraysEqual(a,b) { return !!a && !!b && !(a<b || b<a); }

// Function to convert list of objects with attributes to object where attribute1 : attribute 2
function listOfObjToJustObject(list, attribute1, attribute2) {
    let finalObj = {}
    list.map((element) => {
        finalObj[element[attribute1]] = element[attribute2]
    })

    return finalObj
} 

//Passwword Checker, Check if password is good
/*
    Parameters ------
    item ----> String value to check
*/
function passwordCheck(item) {

    const passwordChars = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/;

    if (!passwordChars.test(item)) {
        return false
    }


    return true;
}

 

export {
    ObjectSwapKey, 
    nullChecker, 
    fileSize, 
    removeAttribute, 
    getBase64, 
    stringBoolExtractor,
    range,
    isImage,
    randomIndex,
    specialCharCheck,
    arraysEqual,
    listOfObjToJustObject,
    undefinedClassifier,
    useOutsideAlerter,
    passwordCheck
}


