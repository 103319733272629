//Library Imports
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form"
import useStore from "../store";
import { shallow } from "zustand/shallow";

//Component Imports
import { getBase64, stringBoolExtractor, specialCharCheck, removeAttribute } from "../GeneralFunctions.js";
import { getEncyclopediaFilters, createEncyclopediaEntry } from '../AxiosList.js';
import { GroupedCheckbox, SynonymEditor } from './EUniqueChemicalEntryEditor.js';

//CSS and Image Imports 
import '../../SCSS_Files/EUniqueChemicalEntryCreator.scss';


const imageMimeType = /image\/(png|jpg|jpeg)/i;

// Image Loader Component that allows users to preview and upload new images 
/*
    Parameter ----
    label ---> String title for image group
    oldImageLink ---> Old image url 
    imageEditsCompiler ---> object state to manage all images that have been updated
    setImageEditsCompiler ---> event to set new image updates 
    compilerIndex ---> index value to be used as key to update imageEditsCompiler
*/
const ImageLoader = (props) => {

    // State file and fileDataURL
    const [file, setFile] = useState(null)
    const [fileDataURL, setFileDataURL] = useState(null);

    // When the file is changed, initiate function for Mime Validation and to set the file
    const changeHandler = (e) => {
        const file = e.target.files[0];
        if (!file.type.match(imageMimeType)) {
          alert("Image mime type is not valid");
          return;
        }
        setFile(file);
    }

    // Effect is then launched when file is edited else
    useEffect(() => {

        let fileReader, isCancel = false;

        // If there is a file 
        if (file) {
        
            //Launch File Reader 
            fileReader = new FileReader();

            // The load event is fired when a file has been read successfully
            fileReader.onload = (e) => {
                const { result } = e.target;

                // If there is file and process not canelled set the file data url for preview
                if (result && !isCancel) {
                    setFileDataURL(result)
                    //Insert Image File to Compiler
                    props.setImageEditsCompiler(() => ({...props.imageEditsCompiler, [`${props.compilerIndex}`]: file}))

                }
            }
            fileReader.readAsDataURL(file);
        }

        return () => {

            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }
    
      }, [file]);


    return (
        <>
            <div className="entry-input">
                <div className="label">{props.label}</div>
                <hr />
                <div className="image-comparison">
                    <div className="image-container-header">
                        <div className="title">New Image</div>
                    </div>
                    <div className="image-container">
                        <div className="image-overlay">
                            {fileDataURL?(<img src={fileDataURL} alt="preview" />):(<div className="image-template"></div>)}
                            <div className="overlay"></div>
                            <div className="upload">Upload</div>
                            <input type="file" onChange={changeHandler}/>
                        </div>
                        {/* <div className='preview-button' onClick={()=> {
                            // if(fileDataURL) {
                            //     props.setImagePreviewerState(true)
                            //     props.setNewURL(fileDataURL)
                            //     props.setOldURL(props.oldImageLink)
                            // } 
                        }}>
                            <i className='bx bxs-slideshow'></i>
                            <div className="tooltip">Preview</div>
                        </div> */}

                    </div>
                </div>
            </div>
        </>
    )
}


// Editorial Page for admins to edit details of Chemical in Encyclopedia
const EncyclopediaCreator = (props) => {

    // States to manage chemcial attribuets and synonyms
    const [encyclopediaFilters, setEncyclopediaFilters] = useState({})
    const [propertiesState, setPropertiesState] = useState([])
    const [propertiesObject, setPropertiesObject] = useState('')
    const [synonymElements, setSynonymElements] = useState([])


    // Image Compiler object for image states to update
    const [imageEditsCompiler, setImageEditsCompiler] = useState({
        'thumbnail': '',
        'molecule': '',
        'stacked_smooth': '',
        'stacked_power_law': '',
        'stacked_power_coefficient': '',
        'stacked_peaks': '',
    })
    
    //  Use Form State for verification
    const { register, reset, handleSubmit, formState: { errors } } = useForm();

    //Access Store for notification
    const { setNotification } = useStore(
        (state) => ({
            setNotification: state.setNotification
        }),
        shallow
    );

    const navigate = useNavigate()

    //Update Chemical Entry Submission Event
    const createChemical = async (data) => {

        let attributeList = stringBoolExtractor(encyclopediaFilters.listOfAttributeDetails, propertiesState).map((a) => String(a.id))
        let convertedImages = {}
        let copyImageObject = imageEditsCompiler

        let convertedImageList = await Promise.all(Object.keys(copyImageObject).map(async key => copyImageObject[key] && await getBase64(copyImageObject[key])))

        // console.log(convertedImageList)
        Object.keys(copyImageObject).map((key, index) => {
            convertedImages[key] = convertedImageList[index]
        })

        // console.log(data)
        const moleculeImage = convertedImages['molecule']
        const thumbnailImage = convertedImages['thumbnail']

        if (moleculeImage === '' || thumbnailImage === '') {
            setNotification('Thumbnail and Molecule Missing', 'Please Submit your entry with a thumbnail and molecule image', 'info')
            return
        }

        const stackedImageObject = removeAttribute(removeAttribute(convertedImages, 'thumbnail'), 'molecule')

        if (stackedImageObject['stacked_smooth'] === '' || stackedImageObject['stacked_power_law'] === '' || stackedImageObject['stacked_power_coefficient'] === '' || stackedImageObject['stacked_peaks'] === ''  )  {
            setNotification('Certain Stacked Charts are missing', 'Please submit your entry with all stacked charts uploaded', 'info')
            return
        }


        const finalisedStackedImageObject = {
            "stackedChartImageSmoothEncodedFile": stackedImageObject['stacked_smooth'],
            "stackedChartImagePowerLawEncodedFile": stackedImageObject['stacked_power_law'],
            "stackedChartImagePowerCoefficientEncodedFile": stackedImageObject['stacked_power_coefficient'],
            "stackedChartImagePeaksEncodedFile": stackedImageObject['stacked_peaks']
        }
        
        
        const synonymString = synonymElements.length == 0? 'NA': synonymElements.join(',')

        createEncyclopediaEntry(
            sessionStorage.getItem('userId'),
            data.chemicalName.trim(), 
            data.chemicalCategory,
            data.casNumber,
            data.controlLevel,
            data.chemicalCode,
            data.description,
            'ACTIVE', 
            attributeList,
            finalisedStackedImageObject,
            moleculeImage,
            thumbnailImage,
            setNotification,
            synonymString,
            navigate
        )


        return
        
    }    

    // Retrieve Information form CMS
    useEffect(() => {
        getEncyclopediaFilters(setEncyclopediaFilters, encyclopediaFilters)
    }, [])


    // Upon data retrieval, update properties state 
    useEffect(() => {
        if (Object.keys(encyclopediaFilters).length !== 0) {
            let finalObj = {}

            setPropertiesObject(finalObj)

            let booleanAttributeList = encyclopediaFilters.listOfAttributeDetails.map((attribute) => {
                if (finalObj[attribute.id]) {
                    return true
                }

                return false
            })

            setPropertiesState(booleanAttributeList)
            reset({'chemicalCategory': '0'})
        }

    }, [encyclopediaFilters])



    return (
        <main className='main-content'>
        <div className={'encyclopedia-creator'}>
            <i className='bx bx-x' onClick={() => navigate('/encyclopedia')}></i>
            <div className="header">
                <div className="title">
                    Add a New Chemical
                    <button className='save-submit' type='submit' form="update-entry-form">Save and Submit</button>
                </div>
                <div className="subtitle">
                    Add a new chemical for your subordinates to view
                </div>
            </div>

            {/* Create Form */}
            <form  id="update-entry-form" onSubmit={handleSubmit(createChemical)}>
                <div className="section">
                    <div className="entry-input">
                        <label htmlFor="chemicalName">Chemical Name</label>
                        <input type="text" name="chemicalName" id="chemicalName" placeholder='Enter Chemical Name' {...register("chemicalName", { required: true, validate: specialCharCheck})} />
                        {errors.chemicalName && (errors.chemicalName.type == 'required' && <p className='form-error'>Value is Requried</p>)}
                        {errors.chemicalName && (errors.chemicalName.type == 'validate' && <p className='form-error'>No Special Charcters Except for ',' and '.' Allowed</p>)}
                    </div>
                    
                    <div className="entry-input">
                        <label htmlFor="casNumber">CAS Number</label>
                        <input type="text" name="casNumber" id="casNumber" placeholder='Enter Cas Number' {...register("casNumber", { required: true, validate: specialCharCheck})} />
                        {errors.casNumber && (errors.casNumber.type == 'required' && <p className='form-error'>Value is Requried</p>)}
                        {errors.casNumber && (errors.casNumber.type == 'validate' && <p className='form-error'>No Special Charcters Except for ',' and '.' Allowed</p>)}
                    </div>

                    <div className="entry-input">
                        <label htmlFor="chemicalCode">Chemical Code</label>
                        <input type="text" name="chemicalCode" id="chemicalCode" placeholder='Enter Chemical Code' {...register("chemicalCode", { required: true, validate: specialCharCheck})} />
                        {errors.chemicalCode && (errors.chemicalCode.type == 'required' && <p className='form-error'>Value is Requried</p>)}
                        {errors.chemicalCode && (errors.chemicalCode.type == 'validate' && <p className='form-error'>No Special Charcters Except for ',' and '.' Allowed</p>)}
                    </div>

                    <div className="entry-input">
                        <label htmlFor="controlLevel">Control Level</label>
                        <select name="controlLevel" id="controlLevel" defaultValue={props.controlType} {...register("controlLevel")} >
                            <option value="COMMON">COMMON</option>
                            <option value="ILLICIT">ILLICIT</option>
                        </select>
                    </div>

                    <div className="entry-input">
                        <label htmlFor="chemicalType">Chemical Type</label>
                        <select name="chemcialCategory" id="chemicalCategory" {...register("chemicalCategory")} >
                            {encyclopediaFilters.listOfCategoryDetails &&
                                encyclopediaFilters.listOfCategoryDetails.map((category,index) => {
                                    return(
                                        <option value={category.id} key={index}>{category.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    {/* Insert Checkbox */}
                    <GroupedCheckbox options={encyclopediaFilters.listOfAttributeDetails} title="Chemical Properties" stateList = {propertiesState} setStateList= {setPropertiesState} propertiesObject={propertiesObject}/>
                    
                    
                    {/* Image Comparison For Thumbnail, charts and molecules*/}  
                    <ImageLoader label='Thumbnail' imageEditsCompiler={imageEditsCompiler} setImageEditsCompiler={setImageEditsCompiler} compilerIndex='thumbnail'/>
                
                    <ImageLoader label='Molecular Model' imageEditsCompiler={imageEditsCompiler} setImageEditsCompiler={setImageEditsCompiler} compilerIndex='molecule'/>

                </div>
                <div className="section" id='right-section'>
                    <div className="entry-input">
                        <label htmlFor="description">Description</label>
                        <textarea  name="description" placeholder='Enter Chemical Description...' id="description" defaultValue={props.description} {...register("description", { required: true, validate: specialCharCheck })}/>
                        {errors.description && (errors.description.type == 'validate' && <p className='form-error'>No Special Charcters Except for ',' and '.' Allowed</p>)}
                    </div>
                    
                    {/* Insert Synonym Editor */}
                    <SynonymEditor synonymElements={synonymElements} setSynonymElements={setSynonymElements}/>

                    <ImageLoader label='Stacked Smooth' imageEditsCompiler={imageEditsCompiler} setImageEditsCompiler={setImageEditsCompiler} compilerIndex='stacked_smooth'/>

                    <ImageLoader label='Stacked Power Law' imageEditsCompiler={imageEditsCompiler} setImageEditsCompiler={setImageEditsCompiler} compilerIndex='stacked_power_law'/>
                    
                    <ImageLoader label='Stacked Power Coefficient' imageEditsCompiler={imageEditsCompiler} setImageEditsCompiler={setImageEditsCompiler} compilerIndex='stacked_power_coefficient'/>
                    
                    <ImageLoader label='Stacked Peaks' imageEditsCompiler={imageEditsCompiler} setImageEditsCompiler={setImageEditsCompiler} compilerIndex='stacked_peaks'/>

            
                </div>
            
            </form>


        </div>
        </main>
    )
}

export {EncyclopediaCreator}