//Library Imports
import React, { useState, useEffect } from 'react';

//Component Imports
import { range } from './GeneralFunctions';

//CSS and Images
import '../SCSS_Files/PaginationStyle.scss';

// Paginagtion Box used to implement Pagination functionality
/* Parameters ----
pageableResultsDTO --> object with information regarding current page 
maxPageBoxes --> Maximum number of page boxes
retrievalFunction --> Function to retrieve contents for that page
setStateList --> state to set display of page contents retrieved
batchSize --> Particular Batch Size of each page
sorterType -->  state containing information on the type of sorting was applied
setNotification --> Notification state setter

*/
const PaginationBox = (props) => {

    const [pageList, setPageList] = useState(props.pageableResultsDTO.totalPages < props.maxPageBoxes ? (range(1, props.pageableResultsDTO.totalPages)) : (range(1, props.maxPageBoxes)))

    const [currentPage, setCurrentPage] = useState(1)

    const [selectChange, setSelectChange] = useState(false)

    const pageChangeEvent = (pageNumber, cheveronButton = false, skipButton=false) => {


        if (cheveronButton && pageNumber > props.pageableResultsDTO.totalPages || pageNumber < 1) {
            return
        }

        setCurrentPage(pageNumber)

        props.retrievalFunction(
            props.setStateList,
            props.setPageableResultsDTO,
            pageNumber - 1,
            props.batchSize,
            props.sorterType.sortField,
            props.sorterType.direction,
            props.setNotification,
            sessionStorage.getItem('userId'),
            props.filters,
            props.showActive,
            props.setDataMetrics
        );

        // In the case where Page number is first if last dont move pagination bars  "X" XXX "X" if not skip button
        if (pageNumber === props.pageableResultsDTO.totalPages) {
            if (skipButton) {
                setPageList(props.pageableResultsDTO.totalPages < props.maxPageBoxes ? (range(1, props.pageableResultsDTO.totalPages)) : (range(props.pageableResultsDTO.totalPages-props.maxPageBoxes+1, props.pageableResultsDTO.totalPages)))
            }
            return
        }

        if (pageNumber === 1) {
            if (skipButton) {
                setPageList(props.pageableResultsDTO.totalPages < props.maxPageBoxes ? (range(1, props.pageableResultsDTO.totalPages)) : (range(1, props.maxPageBoxes)))
            }
            return
        }


        // In the case the current page number is the last one on the page list XXXX "X"
        if (pageNumber == pageList[pageList.length - 1]) {

            // In the case where the total pages left is less than the pagination boxes
            if (props.pageableResultsDTO.totalPages - pageNumber < props.maxPageBoxes - 1) {
                setPageList([pageNumber - 1, ...range(pageNumber, props.pageableResultsDTO.totalPages)])

                return
            }

            // console.log('here')
            setPageList([pageNumber - 1, ...range(pageNumber, pageNumber + (props.maxPageBoxes - 2))])

            return
        }

        if (pageNumber == pageList[0]) {

            if (pageNumber - pageList[0] < 1 && pageNumber < props.maxPageBoxes) {
                setPageList(range(1, props.maxPageBoxes))
                return
            }

            setPageList(range(pageNumber - (props.maxPageBoxes - 1), pageNumber))

            return

        }
    }


    useEffect(() => {
        if (selectChange) {
            setPageList(props.pageableResultsDTO.totalPages < props.maxPageBoxes ? (range(1, props.pageableResultsDTO.totalPages)) : (range(1, props.maxPageBoxes)))
            setCurrentPage(props.pageableResultsDTO.existingPage+1)
            setSelectChange(!selectChange)
        }

        if (props.filterChanged) {
            setPageList(props.pageableResultsDTO.totalPages < props.maxPageBoxes ? (range(1, props.pageableResultsDTO.totalPages)) : (range(1, props.maxPageBoxes)))
            setCurrentPage(props.pageableResultsDTO.existingPage+1)
            props.setFilterChanged(false)
        }

        if (props.activenessChanged) {
            console.log(props.activenessChanged)
            setPageList(props.pageableResultsDTO.totalPages < props.maxPageBoxes ? (range(1, props.pageableResultsDTO.totalPages)) : (range(1, props.maxPageBoxes)))
            setCurrentPage(props.pageableResultsDTO.existingPage+1)
            props.setActivenessChanged(false)
        }

    }, [props.pageableResultsDTO])


    return (
        <div className="pagination-box">
            <div className="column">
                <div className="far-page-button" onClick={() => pageChangeEvent(1, false, true)}><i className='bx bx-skip-previous'></i></div> 
                <div className="arrow" onClick={() => pageChangeEvent(currentPage - 1, true)}><i className='bx bx-chevron-left'></i><span>Previous</span></div>
                <div className='page-box'>

                    {
                        pageList.map((pageNumber) => {
                            return (
                                <div key={pageNumber} className={currentPage == pageNumber ? 'page-button active' : 'page-button'} onClick={() => pageChangeEvent(pageNumber)}>{pageNumber}</div>
                            )
                        })
                    }


                </div>
            </div>
            <div className="column">
                <div className="page-size-selector">
                    <label htmlFor="batch-size">Items per Page</label>
                    <select name="batch-size" onChange={(e) => {
                        props.setBatchSize(e.target.value)
                        setCurrentPage(1)
                        props.retrievalFunction(
                            props.setStateList,
                            props.setPageableResultsDTO,
                            0,
                            e.target.value,
                            props.sorterType.sortField,
                            props.sorterType.direction,
                            props.setNotification,
                            sessionStorage.getItem('userId'),
                            props.filters,
                            props.showActive,
                            props.setDataMetrics
                        );
                        setSelectChange(!selectChange)
                    }}>
                        {props.pageOptions.map((option, key) => {
                            return <option value={option} key={key}>{option}</option>
                        })} 
                    </select>
                </div>
                <div className="page-number">
                    Page {currentPage} of {props.pageableResultsDTO.totalPages}
                </div>
                <div className="arrow" onClick={() => pageChangeEvent(currentPage + 1, true)}><span>Next</span><i className='bx bx-chevron-right'></i></div>
                <div className="far-page-button" onClick={() => pageChangeEvent(props.pageableResultsDTO.totalPages, false, true)}><i className='bx bx-skip-next'></i></div>
            </div>

        </div>
    )
}

export default PaginationBox