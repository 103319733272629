//Initial Node Template
export const nodes = [
    //Start Node
    {
        id: 'start',
        type: 'customlayoutnode',
        data: { 
            nodeName: 'Start Of Process',
            description: 'Welcome to the Model Builder, if you require a run through on operations please click here!',
            explanation: "Here is the start of the process where you will be able to create and connect your flowchart. Take note that for all flow chart processes must be connected to the start node in order to train."
        },
        position: { x: 50, y: 150 },
        className: 'custom-layout-node',
        deletable: false
    },
    //Data Reselect Node
    {
        id: 'select_1',
        type: 'customlayoutnode',
        data: { 
            nodeId: 'select_1',
            nodeName: 'Selected Dataset',
            description: 'Click here to view your selected dataset details or reselect data and rebuild you dataset.',
            explanation: 'This node allows you to view details of your previously selected dataset for this model. In here you will be able to view information such as your data proportions as well as other statistical details that might aid you in your model development endeavours. You will also be able to reselect your data if you choose so.',
            form_title: 'Select Dataset Details',
            entryList: null,
            modelBuildingNodeParameterModelList: [{
                'Data Entries': null,
                'Data Type': null,
                'Chemical Proportions': null
            }]
        },
        position: { x: 1000, y: 400 },
        className: 'custom-layout-node',
        deletable: false,
        selectable: true
    },
    //Administrative Node
    {
        id: 'admin',
        type: 'adminnode',
        data: { 
            nodeId: 'admin_1',
            nodeName: 'Administrative Information',
            description: "Please Key in the model's Name and Description",
            inputs: {
                modelName: null,
                modelDescription: null
            }
        },
        position: { x: 50, y: 400 },
        className: 'custom-layout-node custom-admin-node',
        deletable: false
    },
    //Mode Development Node
    {
        id: 'model_1',
        type: 'custommodelnode',
        data: { 
            algorithmId: null,
            nodeName: 'No Model Used',
            description: 'No model has been created yet. Please click here to create either a Machine Learning Model or a Deep Learning Neural Network',
            explanation: "This node allows you to select which ever type of model to create. Here we offer 2 types from the AI world Machine Learning and Deep Learning. For Machine Learning, you will be able to select from a range of algorithms while for Deep Learning you will be able to ceate a neural network for yourself. Lets Get Started!"
        },
        position: { x: 1300, y: 800 },
        className: 'custom-layout-model-node',
        deletable: false
    },
    //Hyperparam Tuning Node
    {
        id: 'hypertune_1',
        type: 'customlayoutnode',
        data: { 
            nodeName: 'Hyperparamter Tuning',
            description: 'An Algorithm is yet to be created or selected, please do so conducting Hyperparameter Tuning for your model',
            explanation: 'In machine learning, hyperparameter optimization or tuning is the problem of choosing a set of optimal hyperparameters for a learning algorithm. A hyperparameter is a parameter whose value is used to control the learning process. By contrast, the values of other parameters are learned',
            form_title: 'Hyperparameter Tuning Method',

            modelBuildingNodeParameterModelList: [
                {
                    
                    "parameterName": 'Hyperparameter Tuning method',
                    "modelBuildingNodeIntegerParameterModel": null,
                    "modelBuildingNodeStringParameterModel": {                   
                        valueOption: 'GridSearchCV, RandomizedSearchCV',
                        defaultValue: 'GridSearchCV'
                    },
                    "modelBuildingNodeBooleanParameterModel": null,
                    "modelBuildingNodeTextboxModel": null,
                    'modelBuildingNodeFixedTextModel': null
        
               },
               {    
                "parameterName": 'Hyper Parameters',
                "modelBuildingNodeIntegerParameterModel": null,
                "modelBuildingNodeStringParameterModel": null,
                "modelBuildingNodeBooleanParameterModel": null,
                "modelBuildingNodeTextboxModel": null,
                "modelBuildingAlgoHyperParameterOptionModel": []
            }
            ]
        },
        position: { x: 1800, y: 300 },
        className: 'custom-layout-node',
        deletable: false
    },
    //Train Test Split Node
    {
        id: 'split_1',
        type: 'customlayoutnode',
        data: { 
            nodeId: 'split_1',
            nodeName: 'Train Test Split',
            description: 'Please click here to select the propotion of your dataset for training and testing',
            explanation: 'The train-test split procedure is used to estimate the performance of machine learning algorithms when they are used to make predictions on data not used to train the model.It is a fast and easy procedure to perform, the results of which allow you to compare the performance of machine learning algorithms for your predictive modeling problem. ',
            form_title: 'Train Test Split',
            modelBuildingNodeParameterModelList: [
                {
                    
                    "parameterName": 'Dataset Proportion',
                    "modelBuildingNodeIntegerParameterModel": {
                        "nodeParameterId": 1,
                        "minRange": 25.0,
                        "maxRange": 100.0,
                        "defaultValue": 70.0
                    },
                    "modelBuildingNodeStringParameterModel": null,
                    "modelBuildingNodeBooleanParameterModel": null,
                    "modelBuildingNodeTextboxModel": null,
                    // 'modelBuildingNodeSlider': {                
                    //     max: 100,
                    //     restrict: 25,
                    //     defaultValue: 70
                    // },
                 
                }
            ]
        },
        position: { x: 2100, y: 1000 },
        className: 'custom-layout-node',
        deletable: false
    },
    //Ending Node
    {
        id: 'end',
        type: 'customlayoutnode',
        data: { 
            nodeName: 'End Of Process',
            description: 'You have reached the end of the process, click train to train your model now!',
            explanation: 'This is the ending node of the entire full process of the model builder. Please ensure that you have all items filled in for your process, such as your model, data processing, hyperparameter tuning and etc.'
        },
        position: { x: 2350, y: 500 },
        className: 'custom-layout-node',
        deletable: false
    },
]

//Initial Edges Template
export const edges = [
    { 
        id: "estart-admin", 
        source: "admin", 
        target: "start", 
        animated: true, 
        className: "custom-layout-edges"
    },
    { 
        id: "eselect_1-model_1", 
        source: "select_1", 
        target: "model_1", 
        animated: true, 
        className: "custom-layout-edges"
    },
    { 
        id: "emodel_1-hypertune_1", 
        source: "model_1", 
        target: "hypertune_1", 
        animated: true, 
        className: "custom-layout-edges"
    },
    { 
        id: "ehypertune_1-split_1", 
        source: "hypertune_1", 
        target: "split_1", 
        animated: true, 
        className: "custom-layout-edges"
    },
    { 
        id: "esplit_1-end", 
        source: "split_1", 
        target: "end", 
        animated: true, 
        className: "custom-layout-edges"
    },

    
]
