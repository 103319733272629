//Library Imports 
import React, { useEffect, useState } from "react";
import useStore from "../store";
import { shallow } from "zustand/shallow";
import { useNavigate } from "react-router-dom";

// Component Imports
import { getParty, setUserActiveness, makeAdmin } from "../AxiosList";
import { SwitchButton } from "../SwitchButton";
import profile from '../../Images/profile_sample.png';
import { CreateUser } from "../DataSelectionComponents/DetailInput";

//CSS and Images
import '../../SCSS_Files/PartyManager.scss'


// User Display Item to allow activeness toggling, role managing and basic user viewing 
const UserDisplay = (props) => {

    // Change Activeness Event
    const changeActiveness = () => {
        setUserActiveness(
            sessionStorage.getItem('userId'),
            props.userDetails.userId,
            props.setNotification
        )
    }

    // Change Role Event
    const changeRole = () => {
        makeAdmin(
            sessionStorage.getItem('userId'), 
            props.userDetails.userId,
            props.setNotification,
            () => getParty(sessionStorage.getItem('userId'), props.setSubordinates, props.setNotification)
        )
    }

    return (
        <div className="user-wrapper">
            <div className="user">
                {/* Details */}
                <div className="user-info">
                    {props.userDetails.imageUrl ? <img src={`${process.env.REACT_APP_AWS_BUCKET_URL}${props.userDetails.imageUrl}`} className='profile-pic' alt="" /> : <img src={profile} alt="Profile Pic" className='profile-pic' />}
                    <div className="details">
                        <div className="username">
                            {props.userDetails.userName}
                        </div>
                        <p className="role"> {props.userDetails.role}</p>
                    </div>
                </div>

                {/* Actions and Events */}
                <div className={['admin', 'superadmin'].includes(props.userDetails.role)?  'non-clickable actions':'actions'}>
                    <SwitchButton defaultValue={props.userDetails.activationStatus} label='Activity' onChangeEvent={changeActiveness} />
                    <button className="make-admin" onClick={changeRole}>
                        Make admin
                    </button>
                </div>
            </div>
        </div>
    )
}


// Party Manager to manage and view users in your company
const PartyManager = () => {

    // Component manager for party manager
    const [subordinates, setSubordinates] = useState([])
    const [newUser, setNewUser] = useState(false)

    const navigate = useNavigate();


    //Access Store for notification and loading page
    const { setNotification, setLoadingActiveness } = useStore(
        (state) => ({
            setNotification: state.setNotification,
            setLoadingActiveness: state.setLoadingActiveness
        }),
        shallow
    );

    // UseEffect occurs when page loads to retrieve party users
    useEffect(() => {
        if (sessionStorage.getItem('userId') === 'undefined') {
            navigate('/');
        }

        setLoadingActiveness(true)
        getParty(sessionStorage.getItem('userId'), setSubordinates, setNotification)
    }, [])

    // UseEffect to deactivate loading page
    useEffect(() => {
        setTimeout(() => {
            setLoadingActiveness(false)
        }, 1000)
        
    }, [subordinates])
    
    return (
        <>
        <div className="main-content party-manager">
            <div className="party-manager-header">
                <h1 className="title">
                    Team Manager
                </h1>
                <h3 className="subtitle">
                    Manage your company subordiantes here!
                    <div className="create-new-user" onClick={()=>setNewUser(1)}>
                        Create New User
                    </div>
                </h3>

            </div>
            {/* Party Display */}
            <div className="party-manager-section">

                {
                    subordinates && subordinates.map((user, key) => {

                        if (user.userId != sessionStorage.getItem('userId')) {
                            return (
                                <UserDisplay key={key} userDetails={user} setNotification={setNotification} setSubordinates={setSubordinates}/>
                            )
                        }

                    })
                }
            </div>
        </div>

        {/* Create User Popup */}
        <div className="popup-wrapper">
            <div className={newUser == 1 ? 'slide' : 'slide inactive'}>
                {newUser==1 && <CreateUser setNotification={setNotification} closeSlide={() => setNewUser(0)}   axiosAfterEffect ={() => getParty(sessionStorage.getItem('userId'), setSubordinates, setNotification)}/>}
            </div>
        </div>
        </>
    )
}

export { PartyManager }