//Module Imports
import React from "react";
import useStore from "../store";
import { shallow } from "zustand/shallow";
import { useStoreApi, useReactFlow } from 'reactflow';
import DeepLearningChooser from "./DeeplearningBuilder";

//Custom Components
import { static_algorithms } from '../ModelBuilderComponents/ToolsAndInformation';
import { Hyperparameter } from "./ParameterBar";

//Images
import ml_logo from '../../Icons/ModelBuilderIcons/algorithm-icon.png';
import dl_logo from '../../Icons/ModelBuilderIcons/neural-network-icon.png';

// Icon For adding
const Icon = ({ src, alt, color }) =>{
    return (
        <>
            <div className={`icon ${color}`}>
                <img src={src} alt={alt} className='icon-image' />
            </div>
        </>
    );
};

// The Model choice user has to choose (ML vs DL)
const ModelTypeChoice = ({ src, color, alt, header, description, subheader, popup, id }) => {

    const { setCurrentPopUp} = useStore(
        (state) => ({
            setCurrentPopUp: state.setCurrentPopUp,
        }),
        shallow
    );

    return (
        <div className={`model-choice ${color}`} onClick={() => {
            setCurrentPopUp(popup)
        }} id={id}>
            <div className='model-choice-items'>
                <div className="model-choice-icon">
                    <Icon src={src} alt={alt} color={color} />
                </div>
                <div className="model-choice-header">
                    {header}
                </div>
                <div className="model-choice-description">
                    {description}
                </div>
                <div className="model-choice-subheader">
                    {subheader}
                </div>
            </div>
        </div>
    );
};


//Selection for model Type. ML or DL?
const ModelType = () => {
    const { setModelAlgOpened, setNodeBarButton, setHeaderOpened, currentPopUp} = useStore(
        (state) => ({
            setModelAlgOpened: state.setModelAlgOpened,
            setNodeBarButton: state.setNodeBarButton,
            setHeaderOpened: state.setHeaderOpened,
            currentPopUp: state.currentPopUp,
        }),
        shallow
    );

    return (
        <div className={currentPopUp === 'ModelChooser' ? ("model-chooser") : ('model-chooser inactive')}>
            <div className="model-chooser-title">
                <label className='model-chooser-title-label'>What Model Are You Building?</label>
                <i className='bx bx-x' onClick={() => {
                    setModelAlgOpened(null);
                    setHeaderOpened(true);
                    setNodeBarButton(true);
                }}></i>
            </div>

            <div className="model-chooser-choices">
                <ModelTypeChoice
                    header='Machine Learning Algorithms'
                    description='Welcome to the machine learning Algorithm library where you will be able to select from a range of algorithms which makes use of statistical methodologies for models'
                    subheader='You can start here if you are new to AI!'
                    src={ml_logo}
                    alt='ML'
                    color='blue'
                    popup='AlgorithmChooser'
                    id='ml-choice'
                />
                <div>OR</div>
                <ModelTypeChoice
                    header='Deep Learning Neural Networks'
                    description='Welcome to the Neural Network workshop where you will be able to customize and develop your very own neural network to suit your industrial needs. '
                    subheader='Have experience?  Build a neural network now!'
                    src={dl_logo}
                    alt='DL'
                    color='orange'
                    popup='DeepLearningChooser' //
                    id='dl-choice'
                />
            </div>
        </div>
    );
};

//Lays out the format for algorithm details found in ToolsAndInformation.js
const AlgorithmDetail = ({ detail }) => {
    switch (detail.type) {
        case 'text':
            const newText = detail.content.split('\n').map(str => <p>{str}</p>);
            return (
                <>
                    <div className="text">
                        {newText}
                    </div>
                </>
            );
        case 'heads':
            return (
                <>
                    <div className="heads">
                        {detail.content}
                    </div>
                </>
            );
        case 'subheads':
            return (
                <div className="subheads">
                    {detail.content}
                </div>
            );
        case 'image':
            return (
                <div className="image">
                    <img src={detail.content} style={{width:'100%'}} alt="" />
                </div>
            );
        default:
            return ('');
    };
};


//Algorithm Selection box
const Algorithm = ({ algorithmInfo }) => {
    const { setDetailElement, setAlgorithmSelect, algorithmSelect, setAlgorithmSelectId ,setHypertuneParams } = useStore(
        (state) => ({
            setDetailElement: state.setDetailElement,
            setAlgorithmSelect: state.setAlgorithmSelect,
            algorithmSelect: state.algorithmSelect,
            setAlgorithmSelectId: state.setAlgorithmSelectId,
            setHypertuneParams: state.setHypertuneParams
        }),
        shallow
    );
    
    return (
        <li className={algorithmSelect === algorithmInfo.algorithmName ? ("algorithm active") : "algorithm"} onClick={() => {
            setDetailElement(<AlgorithmDetails algorithm={algorithmInfo.algorithmName} />)
            setAlgorithmSelect(algorithmInfo.algorithmName)
            setAlgorithmSelectId(algorithmInfo.algorithmId)
            setHypertuneParams(algorithmInfo.modelBuildingAlgoHyperParameterOptionModel)
        }}>
            <div className='algorithm-label'>{((algorithmInfo.algorithmName).charAt(0).toUpperCase() + algorithmInfo.algorithmName.slice(1)).replaceAll('_', ' ')}</div>
            <div className="algorithm-icon">
                <i className='bx bx-chevron-right'></i>
            </div>
        </li>
    );
};
//Algorithm Details
const AlgorithmDetails = ({ algorithm }) => {
    let details = static_algorithms[algorithm];
    return (
        <>
            {(details) ?
                (
                    <div className="algorithm-details">
                        {details.map((detail) => {
                            return (<AlgorithmDetail detail={detail} />)
                        })}
                    </div>
                ) : ('')}
        </>
    );
};

//Algorithm Select Page
const AlgorithmChooser = ({ id, algorithmsList }) => {    
    const {
        setModelAlgOpened, setNodeBarButton, setHeaderOpened,
        algorithmDetailElement, setAlgorithmActive, algorithmSelect,
        currentPopUp, setCurrentPopUp, hypertuneParams,
        setAlgorithmHyperparameterElements,
        algorithmSelectId} = useStore(
            (state) => ({
                setModelAlgOpened: state.setModelAlgOpened,
                setNodeBarButton: state.setNodeBarButton,
                setHeaderOpened: state.setHeaderOpened,
                algorithmDetailElement: state.algorithmDetailElement,
                setAlgorithmActive: state.setAlgorithmActive,
                algorithmSelect: state.algorithmSelect,
                currentPopUp: state.currentPopUp,
                setCurrentPopUp: state.setCurrentPopUp,
                hypertuneParams: state.hypertuneParams,
                setAlgorithmHyperparameterElements: state.setAlgorithmHyperparameterElements,
                algorithmSelectId: state.algorithmSelectId,
            }),
            shallow
        )

    const reactFlowStore = useStoreApi();

    const { nodeInternals } = reactFlowStore.getState();
    const nodes = Array.from(nodeInternals).map(([, node]) => node);

    return (
        <div className={currentPopUp === 'AlgorithmChooser' ? ("algorithm-chooser") : ('algorithm-chooser inactive')}>
            <div className="algorithm-chooser-title">
                <div className="back-model-chooser" onClick={() => setCurrentPopUp('ModelChooser')}>
                    <i className='bx bx-chevron-left'></i>
                    <div>Re-select Model Type</div>
                </div>

                <i className='bx bx-x' onClick={() => {
                    setModelAlgOpened(null);
                    setHeaderOpened(true);
                    setNodeBarButton(true);
                }}></i>

            </div>
            <label className='algorithm-chooser-title-label'>Machine Learning Algorithms</label>

            <div className="algorithm-chooser-choices">
                <ul className="algorithm-list">
                    <div className="algorithm-category">Classification</div>
                    {algorithmsList.map((set, index) => { 
                        if (set.algorithmId != 1) {
                            return (
                                <Algorithm algorithmInfo={set} key={index}/>
                            )
                        };
                    })}

                </ul>
                <div className="algorithm-cut">

                </div>
                <div className="algorithm-display">
                    {algorithmDetailElement}
                </div>
                <button className='confirm-btn' onClick={() => {
                    //When you confirm and Alg. 
                    if (algorithmSelect) {

                        //Change the active Algorithm in the node
                        setAlgorithmActive(((algorithmSelect).charAt(0).toUpperCase() + algorithmSelect.slice(1)).replaceAll('_', ' '))

                        //Map Nodes and if  can find the algorithm
                        nodes.map((node) => {
                            if (node.id == id) {
                                node.data.nodeName = ((algorithmSelect).charAt(0).toUpperCase() + algorithmSelect.slice(1)).replaceAll('_', ' ')
                                node.data.algorithmId = algorithmSelectId
                            }
                            if (node.id == 'hypertune_1') {
                                node.data.modelBuildingNodeParameterModelList[1].modelBuildingAlgoHyperParameterOptionModel = hypertuneParams
                            };
                        })

                        let elementList = []
                        hypertuneParams.map((parameter) => {
                            let defaultValues = '';
                            let description = '';
                            let validation = () => {
                                return(true);
                            }
                    
                            //If Int/Decimal, create valiadation make sure each item in user input list is in range
                            if (parameter.modelBuildingIntegerParameterModel != null) {
                                defaultValues = parameter.modelBuildingIntegerParameterModel.defaultValue
                                description = `Any number from ${parameter.modelBuildingIntegerParameterModel.minRange} to ${parameter.modelBuildingIntegerParameterModel.maxRange}, you can type multiple values seperated by commas`
                                //Filter out between space, split to array and remove edge spaces 

                                
                                if (parameter.modelBuildingIntegerParameterModel.minRange != null && parameter.modelBuildingIntegerParameterModel.maxRange != null) {
                                    validation = (arr) => {
                                        return([...new Set(arr.replace(/\s/g,'').split(',').filter(function(str) {return /\S/.test(str)}))].every( 
                                        //Check if every item within array is within range 
                                        v => v >= parseInt(parameter.modelBuildingIntegerParameterModel.minRange) && v <= parseInt(parameter.modelBuildingIntegerParameterModel.maxRange) 
                                        ))
                                    }
                                }else if (parameter.modelBuildingIntegerParameterModel.minRange != null){
                                    validation = (arr) => {
                                        return([...new Set(arr.replace(/\s/g,'').split(',').filter(function(str) {return /\S/.test(str)}))].every( 
                                        //Check if every item within array is within range 
                                        v => v >= parseInt(parameter.modelBuildingIntegerParameterModel.minRange)
                                        ))
                                    }
                                }else if (parameter.modelBuildingIntegerParameterModel.maxRange != null) {
                                    validation = (arr) => {
                                        return([...new Set(arr.replace(/\s/g,'').split(',').filter(function(str) {return /\S/.test(str)}))].every( 
                                        //Check if every item within array is within range 
                                        v => v <= parseInt(parameter.modelBuildingIntegerParameterModel.maxRange) 
                                        ))
                                    }
                                }

                            }else if (parameter.modelBuildingFloatParameterModel != null) {
                                defaultValues = parameter.modelBuildingFloatParameterModel.defaultValue
                                description = `Any number from ${parameter.modelBuildingFloatParameterModel.minRange} to ${parameter.modelBuildingFloatParameterModel.maxRange}, you can type multiple values seperated by commas`;
                                //Filter out between space, split to array and remove edge spaces 

                                
                                if (parameter.modelBuildingFloatParameterModel.minRange != null && parameter.modelBuildingFloatParameterModel.maxRange != null) {
                                    validation = (arr) => {
                                        return([...new Set(arr.replace(/\s/g,'').split(',').filter(function(str) {return /\S/.test(str)}))].every( 
                                        //Check if every item within array is within range 
                                        v => v >= parseInt(parameter.modelBuildingFloatParameterModel.minRange) && v <= parseInt(parameter.modelBuildingFloatParameterModel.maxRange) 
                                        ))
                                    }
                                }else if (parameter.modelBuildingFloatParameterModel.minRange != null){
                                    validation = (arr) => {
                                        return([...new Set(arr.replace(/\s/g,'').split(',').filter(function(str) {return /\S/.test(str)}))].every( 
                                        //Check if every item within array is within range 
                                        v => v >= parseInt(parameter.modelBuildingFloatParameterModel.minRange)
                                        ))
                                    }
                                }else if (parameter.modelBuildingFloatParameterModel.maxRange != null) {
                                    validation = (arr) => {
                                        return([...new Set(arr.replace(/\s/g,'').split(',').filter(function(str) {return /\S/.test(str)}))].every( 
                                        //Check if every item within array is within range 
                                        v => v <= parseInt(parameter.modelBuildingFloatParameterModel.maxRange) 
                                        ))
                                    }
                                }  
                            }else if (parameter.modelBuildingStringParameterModel != null) {
                                defaultValues = parameter.modelBuildingStringParameterModel.defaultValue
                                description = `Any value within this list ${parameter.modelBuildingStringParameterModel.valueOption}, you can type multiple values seperated by commas`
                    
                                //Filter out between space, split to array and remove edge spaces 
                                let refList = parameter.modelBuildingStringParameterModel.valueOption.replace(/\s/g,'').split(',').filter(function(str) {return /\S/.test(str);})
                    
                                //Check if items in user input is a sub array of a reference array
                                validation = arr => {return([...new Set(arr.replace(/\s/g,'').split(',').filter(function(str) {return /\S/.test(str)}))].every( v =>  refList.includes(v)))}                    
                    
                            }else if (parameter.modelBuildingBooleanParameterModel != null) {
                                defaultValues = parameter.modelBuildingBooleanParameterModel.defaultValue
                                description = `True or False, you can type multiple values seperated by commas`
                                validation = arr => {return([...new Set(arr.replace(/\s/g,'').split(',').filter(function(str) {return /\S/.test(str)}))].every( v => v.toLowerCase() == "true" || v.toLowerCase() == "false"))}                    

                            }
                            elementList.push(<Hyperparameter parameter={parameter} description={description} defaultValues={defaultValues} validation={validation}/>)
                        })
                    
                
                        setAlgorithmHyperparameterElements(elementList);
                        setModelAlgOpened(null);
                        setHeaderOpened(true);
                        setNodeBarButton(true);
                    }
                }}>
                    <i className='bx bx-chevron-right'></i>
                    <div className="tooltip">
                        Select Algorithm
                    </div>
                </button>
            </div>

        </div>
    );
};


//Overall model pop up
const ModelPopup = ({ id,  algorithmList,  filteredDLNodes}) => {
    const { modelAlgOpened, } = useStore(
        (state) => ({
            modelAlgOpened: state.modelAlgOpened,
        }),
        shallow
    );
    return (
        <>
            <div className={modelAlgOpened === id ? ('mb-popup-bg open') : ('mb-popup-bg')}>
                <ModelType />
                <AlgorithmChooser id={id} algorithmsList={algorithmList}/>
                {/* <DeepLearningChooser  filteredDLNodes={ filteredDLNodes}/> */}
            </div>
        </>
    );
};

export default ModelPopup;
