import React from "react";
import { useState, useRef, useEffect } from "react";
import '../../Deprecated_CSS/CSS_Files/ModelSelectPopupStyle.css'
import { set, useForm } from 'react-hook-form'
import useStore from "../store.js";
import { shallow } from 'zustand/shallow'
import { useNavigate } from "react-router-dom";
import { FixedText, TextBox } from "./ParameterBar";
import axios from 'axios'
import { getAllDataSelectionEntries } from "../AxiosList";
import { DragAndDrop } from "../DragAndDrops/DragAndDrop";

const DataSelectInput = (props) => {
    
    const{setDataType, setEntryList, setEntryProportions, setModelName, setTotalData} = useStore(state => ({ 
        setDataType: state.setDataType,
        setEntryList: state.setEntryList,
        setEntryProportions: state.setEntryProportions,
        setModelName: state.setModelName,
        setTotalData: state.setTotalData
    }), shallow);

    const submitDataSelect = (data) => {
        let total = 0
        let indexList = []
        let chemProportions = {}
        let chemProportionsString = ''

        if (props.list[1].items.length === 0) {
            alert('No files Selected')
            return
        }
        props.list[1].items.map((set) => {
            indexList.push(set.entrySetId)
            if (set.drugName in chemProportions) {
                chemProportions[set.drugName] = chemProportions[set.drugName] + set.experimentEntryCount
            }else{
                chemProportions[set.drugName] = set.experimentEntryCount
            }
            total += set.experimentEntryCount
        })
        
        
        Object.keys(chemProportions).forEach((key) => {
            chemProportionsString += `${key} ${((chemProportions[key]/total) * 100 ).toFixed(2)}%,`
        })
        
        props.setDataSelectBar(
            <>
                <FixedText label='Data Entries' value={total}/>
                <FixedText label='Data Type' value={data.dtype}/>
                <TextBox label = 'Chemcial Proportions' value={chemProportionsString.split(',')}/>
                <button className='button parameter-bar-items' onClick={()=> props.setDataSelect(true)}>Reselect Data</button>
            </>
        )


        setDataType(data.dtype)
        setEntryList(indexList)
        setEntryProportions(chemProportionsString.slice(0,-1))
        setTotalData(total)

        props.setTrigger(false)
        props.setList( data = [
            {
                title: 'Drag and drop files that you wish to examine your model with.',
                items: props.resetItems
            },
            {
                title: 'Selected Files',
                items: []
            }
        
        ])
        props.setNodeBarButton(true)
        props.setHeaderOpened(true)

    }

    const { register, handleSubmit, formState: { errors }} = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
    });

    return (
            <div className={props.trigger? ('zipdetails-input-popup'):('zipdetails-input-popup inactive')}>
            <div className='zipdetails-input-popup-inner'>

                <button className='zipdetails-input-close-btn' onClick={() => {
                    props.setTrigger(false)
                    props.setNodeBarButton(true)
                    props.setHeaderOpened(true)
                }}><i className='bx bx-x'></i></button>

                <form onSubmit={handleSubmit(submitDataSelect)}>
                    <div className='row zipdetails-form'>

                        <div className="detail">
                        <label id="datatype-label" htmlFor="dtype">Datatype</label> <br/>

                            <select name="dtype" id="datatype-input" {...register( 'dtype', {required: true})}>
                                <option value="peaks">Peaks</option>
                                <option value="smooth">Smooth</option>
                                <option value="power_law">Power Law</option>
                                <option value="power_coef">Power Coefficient</option>
                            </select>
                        </div>
                        
                        <button id="create-btn" onClick={handleSubmit(submitDataSelect)}>Create</button>
                        {props.children}
                    </div>
                </form>
            </div>
        </div>
    )
}


const DataSelectPopup = (props) => {
    const [dataSelectDetailsPopup, setDataSelectDetailsPopup] = useState(false);
    const [resetItems, setResetItems] = useState([])
    const [list, setList] = useState([]);
    const userId = sessionStorage.getItem('userId');


    useEffect(() => {
        getAllDataSelectionEntries(setResetItems, setList, userId);
    }, []);
    
    return (
        <>
            <div className={props.trigger ? ('folder-input-popup') : ('folder-input-popup inactive')}>
                <div className='folder-input-popup-inner'>
                    <button className='folder-input-close-btn' onClick={() => {
                        props.setHeaderOpened(true)
                        props.setNodeBarButton(true)
                        props.setTrigger(false)
                    }}><i className='bx bx-x'></i></button>
                    <div className='row'>
                        <div className='column dnd-column'>
                            <div className="drag-title">Drag and drop files that you wish compile and use</div>
                            <input id="folder-input-search" type="text" placeholder="search" ></input>
                            <button id='export-dnd-filter-btn'><i className='bx bx-filter'></i></button>
                        </div>

                        <div className="verticalLine"></div>

                        <div className='column dnd-column'>
                            <div className="select-title">Selected Files</div>
                        </div>
                    </div>
                    <button id="details-done-btn" onClick={() => {
                        props.setTrigger(false)
                        setDataSelectDetailsPopup(true)

                    }}>Done</button>

                    <DragAndDrop  list={list} setList={setList} />
                </div>
            </div>
            <DataSelectInput 
                trigger={dataSelectDetailsPopup}  
                setTrigger={setDataSelectDetailsPopup} 
                resetItems={resetItems} list={list} 
                setList={setList} 
                setDataSelectBar={props.setDataSelectBar} 
                setDataSelect={props.setTrigger}
                setHeaderOpened = {props.setHeaderOpened}
                setNodeBarButton = {props.setNodeBarButton}
            />
        </>

    )
}

export default DataSelectPopup;