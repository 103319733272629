import React from "react";
import { useForm } from "react-hook-form"

import '../SCSS_Files/CriticalConfirmation.scss'

//Delete confirmation component 
/*
    Parameters -------
    navigate --> navigation function
    route --> route to navigate to 
    popupBox --> state to manage popup 
    setPopupBox --> set state handler to manage popup 
    title --> String or element title
    subtitle --> String of element subtitle 
    confirmButton --> Confirm button value (string)
    confirmationValue --> What value to user must key in to carryout confirmation event
*/
const CriticalConfirmation = (props) => {
    
    //  Use Form State for verification
    const { register, reset, handleSubmit, formState: { errors } } = useForm();
    
    // Event used to validate and confirm a particular reaction
    //Triggers a fuinction after validation
    const confirmationEvent = (data) => {
        if (data.deleteConfirmation === props.confirmationValue) {
            props.confirmationFunction()
            reset()
            props.setPopupBox(false)
        }
    }   

    return (
        <div className={props.popupBox?("critical-popup"):("critical-popup inactive")}>
            <div className="critical-box">
                <i className='bx bx-x' onClick={() => {
                    props.setPopupBox(false)
                    reset()
                }}></i>

                <div className="title">
                    {props.title}
                </div>
                <div className="subtitle">
                    {props.subtitle}
                </div>
                <form id="confirmation-form" onSubmit={handleSubmit(confirmationEvent)}>
                    <input type="text" placeholder={props.confirmationValue} {...register('deleteConfirmation')}/>
                </form>
                <div className="buttons">
                    <button type='submit' className="confirmation-button" form="confirmation-form" >
                        {props.confirmButton}
                    </button>
                    <button className="cancel-button" onClick={() => {
                        reset()
                        props.setPopupBox(false)
                    }}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export {CriticalConfirmation}