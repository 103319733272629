import React from 'react'
import useStore from "./Components/store.js";
import {shallow} from 'zustand/shallow';
import useDocumentTitle from './Components/UseDocumentTitle.js'

import {Routes, Route} from 'react-router-dom'
import Download from './Components/DownloadComponents/DownloadPage'
import Encyclopedia from './Components/EnclopediaComponents/EncyclopediaPage'
import AnalyticsArchive from './Components/DataArchiveComponents/AnalyticsArchivePage.js'
import ModelTest from './Components/ModelTestComponents/ModelTestPage'
import NavBar from './Components/NavBar'
import ModelSelect from './Components/ModelSelectComponents/ModelSelectPage'
import UniqueDrugEntry from './Components/UDEComponents/UniqueDrugEntry.js'
import ModelBuilder from './Components/ModelBuilderComponents/ModelBuilder'
import Login from './Components/Login'
import EUniqueDrugEntry from './Components/EUDEComponents/EUniqueDrugEntry'
import { EncyclopediaCreator } from './Components/EUDEComponents/EUniqueDrugEntryCreator.js';
import { Notification } from './Components/Notification'
import { ProfileViewer } from './Components/UserManagementComponents/ProfileViewer.js';
import { ResetPasswordPage } from './Components/UserManagementComponents/ResetPassword.js';
import NotFound from './Components/NotFound.js';
import { PartyManager } from './Components/UserManagementComponents/PartyManager.js';
import { LoadingPage } from './Components/Loading.js';

function LoginPage() {
  useDocumentTitle('Senstrio AI')
  return <Login/>
}

function DownloadPage() {
  useDocumentTitle('Data Exportsitory')
  return <Download/>
}
function ModelSelectPage() {
  useDocumentTitle('Model Selection')
  return <ModelSelect />
}
function EncyclopediaPage() {
  useDocumentTitle('Chemical Encyclopedia')
  return <Encyclopedia/>
}
function AnalyticsArchivePage() {
  useDocumentTitle('Spectro Archive')
  return <AnalyticsArchive/>
}
function ModelTestPage() {
  useDocumentTitle('Model Tester')
  return <ModelTest/>
}
function UniqueDrugEntryPage() {
  useDocumentTitle('Unique Chemical')
  return <UniqueDrugEntry/>
}

function ModelBuilderPage() {
  useDocumentTitle('Model Builder')
  return <ModelBuilder/>
}

function EUniqueDrugEntryPage() {
  useDocumentTitle('Unique Encyclopedia Entry')
  return <EUniqueDrugEntry/>
}

function NotFoundPage() {
  useDocumentTitle('Not Found')
  return <NotFound/>
}

function EncyclopediaCreatorPage() {
  useDocumentTitle('Encyclopedia Creator')
  return <EncyclopediaCreator/>
}

function ProfileViewerPage() {
  useDocumentTitle('Profile')
  return <ProfileViewer/>
}

function PartyManagerPage() {
  useDocumentTitle('Party Manager')
  return <PartyManager/>
}

function ResetPasswordTool() {
  useDocumentTitle('Password Reset')
  return <ResetPasswordPage title='Welcome to SenstrioAI' subtitle='We noticed that you have logged in with our provided password! Please reset the password to your own.' />
}



const App=()=>{

    const { notification } = useStore(state => ({ 
      notification: state.notification, 
    }), shallow);

    return(
        <>
          {/* Notification message (Errors, Success, Info */}
          {Object.values(notification).every(x => x === null || x === '')?(
            ''
          ):(
            <Notification title={notification.title} subtitle={notification.subtitle} notificationType={notification.notificationType}/>
          )}
          <NavBar />
          <Routes>   
            <Route exact path='/' element={ <LoginPage/>} />
            {/* <Route exact path='/modelselect' element={ <ModelSelect/>} /> */}
            <Route path='/models' element={ <ModelSelectPage/>} />
            <Route path='/download' element={ <DownloadPage />} />
            <Route path='/encyclopedia' element={ <EncyclopediaPage/>} />
            <Route path='/archivesummary' element={ <AnalyticsArchivePage />} />
            <Route path='/dataarchive' element={ <AnalyticsArchivePage />} />
            {/* <Route path='/modeltest' element={ <ModelTestPage />} /> */}
            <Route path='/ude' element={ <UniqueDrugEntryPage />} />
            <Route path='/modelbuilder' element={ <ModelBuilderPage />} />
            <Route path='/eude' element={ <EUniqueDrugEntryPage />} />
            <Route path='*' element={<NotFoundPage />}/>
            <Route path='/eudeCreate' element={<EncyclopediaCreatorPage />} />
            <Route path='/profileviewer' element={<ProfileViewerPage />} />
            <Route path='/groupmanager' element={<PartyManagerPage />} />
            <Route path='/resetpassword' element={<ResetPasswordTool/>}/>
          </Routes>
          <LoadingPage />
        </>
    )
}

export default App