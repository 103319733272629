import axios from "axios";
import { removeAttribute, undefinedClassifier } from "./GeneralFunctions";
import { saveAs } from "file-saver";

// Security and User APIs------------------------------------------------------------------------------

//Axios Call for Logins
//Link (POST) --> useraccountmanagement/login
//For Params check jsonData Object
//Also requires setStateOptions which is a setState event outside of function
const postCrendentials = (
  credentials,
  navigateLink,
  navigate,
  setNotification
) => {
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/useraccountmanagement/login`,
      credentials
    )
    .then((res) => {
      console.log("Response", res.data);

      if (res.data.userId != null) {
        sessionStorage.setItem("userId", res.data.userId);
        sessionStorage.setItem("companyId", res.data.companyId);
        sessionStorage.setItem("role", res.data.role);
        sessionStorage.setItem("userName", res.data.userName);
        sessionStorage.setItem("companyName", res.data.companyName);
        sessionStorage.setItem("email", res.data.email);
        sessionStorage.setItem(
          "userImage",
          `${process.env.REACT_APP_AWS_BUCKET_URL}${res.data.imageUrl}`
        );

        if (res.data.response === "Redirecting to password reset") {
          sessionStorage.setItem("userId", undefined);
          sessionStorage.setItem("resetUserName", credentials.userName);
          sessionStorage.setItem("anorPassword", credentials.password);

          navigate("/resetpassword");
          return;
        }
        navigate(navigateLink);
        return;
      }
      setNotification(
        "Please Try Again",
        "Incorrect username or password. Please try again",
        "error"
      );
    });
};

//Axios Call for user data retrieval
//Link (POST) --> useraccountmanagement/getUserProfile
//For Params check jsonData Object
//Also requires setUserDetails which is a setState event outside of function
const getUserDetails = (userId, setUserDetails, setNotification) => {
  const jsonData = {
    userId: userId,
  };

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/useraccountmanagement/getUserProfile`,
      jsonData
    )
    .then((res) => {
      setUserDetails(res.data);
      sessionStorage.setItem(
        "userImage",
        `${process.env.REACT_APP_AWS_BUCKET_URL}${res.data.profileUrl}`
      );
      // console.log(`${process.env.REACT_APP_AWS_BUCKET_URL}${res.data.profileUrl}`)
    })
    .catch((error) => {
      // console.log(error);
      setNotification(
        "User Resource Error",
        "User Resourses not loading, either contact us or wait for it to load",
        "error"
      );
    });
};

//Axios Call to check credentials within app
//Link (POST) --> useraccountmanagement/login
//For Params check jsonData Object
//Also requires setUserDetails which is a setState event outside of function
const checkCredentials = (username, password, setNotification, nextEvent) => {
  const jsonData = {
    userName: username,
    password: password,
  };
  // console.log(jsonData)
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/useraccountmanagement/login`,
      jsonData
    )
    .then((res) => {
      if (res.data.userId != null) {
        nextEvent && nextEvent(password);
        setNotification(
          "Verification Success",
          "The following password validation has been a success",
          "success"
        );
        return;
      }
      setNotification(
        "Please Try Again",
        "Incorrect username or password. Please try again",
        "error"
      );
    })
    .catch((error) => {
      // console.log(error);
      setNotification(
        "Password Error",
        "There seems to be an issue with your password, try again. Contact us if it still persists",
        "error"
      );
    });
};

//Axios Call for profile change
//Link (POST) --> useraccountmanagement/updateUserParticulars
//For Params check jsonData Object
//Also requires setUserDetails which is a setState event outside of function
const updateProfile = (
  userId,
  details,
  encodedProfileImage,
  setNotification,
  axiosAfterEffect
) => {
  const jsonData = {
    userId: userId,
    ...details,
    encodedProfileImage: encodedProfileImage,
  };

  console.log(jsonData);

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/useraccountmanagement/updateUserParticulars`,
      jsonData
    )
    .then((res) => {
      console.log("Response", res.data);

      if (
        res.data ===
        "Failed to update user particulars as provided email is currently in use"
      ) {
        setNotification("Update Error", res.data, "error");
        return;
      }

      if (res.data === "Successfully updated user particulars") {
        axiosAfterEffect();
        setNotification("Update Success", res.data, "success");
        // sessionStorage.setItem('email', details.email)
      }
    })
    .catch((error) => {
      // console.log(error);
      setNotification(
        "Update Error",
        "There seems to be an issue with updating your profile, try again. Contact us if it still persists",
        "error"
      );
    });
};

//Axios Call for password change
//Link (POST) --> useraccountmanagement/updatePassword
//For Params check jsonData Object
//Also requires setUserDetails which is a setState event outside of function
const updatePassword = (
  userId,
  currentPassword,
  newPassword,
  setNotification
) => {
  const jsonData = {
    userId: userId,
    currentPassword: currentPassword,
    newPassword: newPassword,
  };

  console.log(jsonData);

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/useraccountmanagement/updatePassword`,
      jsonData
    )
    .then((res) => {
      console.log("Response", res.data);

      if (res.data == "Invalid password entered, unable to update password") {
        setNotification("Password Error", res.data, "error");
      }
    })
    .catch((error) => {
      // console.log(error);
      setNotification(
        "Password Error",
        "There seems to be an issue with updating your password, try again. Contact us if it still persists",
        "error"
      );
    });
};

//Axios Call for password reset
//Link (POST) --> useraccountmanagement/updatePassworduseraccountmanagement/resetPasswordAndGetLoginDetails
//Also requires setUserDetails which is a setState event outside of function
const newUserPassword = (
  userName,
  currentPassword,
  newPassword,
  setNotification,
  navigate,
  navigateLink
) => {
  const jsonData = {
    username: userName,
    currentPassword: currentPassword,
    newPassword: newPassword,
  };

  console.log(jsonData);

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/useraccountmanagement/resetPasswordAndGetLoginDetails`,
      jsonData
    )
    .then((res) => {
      console.log("Response", res.data);

      if (res.data.userName === userName) {
        setNotification(
          "Change Successful",
          "Password change has been successful, logging in now",
          "success"
        );
        postCrendentials(res.data, navigateLink, navigate, setNotification);

        return;
      }

      setNotification(
        "Change Unsuccessful",
        "Password change was unsuccessful",
        "error"
      );
    })
    .catch((error) => {
      // console.log(error);
      setNotification(
        "Password Error",
        "There seems to be an issue with updating your password, try again. Contact us if it still persists",
        "error"
      );
    });
};

//Axios Call for retrieve group members
//Link (POST) --> useraccountmanagement/companyUsersByAdmin
//Also requires setUserDetails which is a setState event outside of function
const getParty = (adminId, setStateOptions, setNotification) => {
  const jsonData = {
    adminId: adminId,
  };

  console.log(jsonData);

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/useraccountmanagement/companyUsersByAdmin`,
      jsonData
    )
    .then((res) => {
      console.log("Response", res.data);
      setStateOptions(res.data);
    })
    .catch((error) => {
      console.log(error);
      setNotification(
        "Group Management Error",
        "There seems to be an issue with retrieving your party members, try again. Contact us if it still persists",
        "error"
      );
    });
};

//Axios Call to set member activeness
//Link (POST) --> useraccountmanagement/useraccountmanagement/companyUsersByAdmin
//Also requires setUserDetails which is a setState event outside of function
const setUserActiveness = (adminId, userId, setNotification) => {
  const jsonData = {
    adminId: adminId,
    userId: userId,
  };

  console.log(jsonData);

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/useraccountmanagement/adminToggleUserActivationStatus`,
      jsonData
    )
    .then((res) => {
      console.log("Response", res.data);

      setNotification(
        "Change Successful",
        "User activation has been edited",
        "success"
      );
    })
    .catch((error) => {
      // console.log(error);
      setNotification(
        "Change Unsuccessful",
        "An error occured in user activation change ",
        "error"
      );
    });
};

//Axios Call to add new user
//Link (POST) --> useraccountmanagement/useraccountmanagement/companyUsersByAdmin
//Also requires setUserDetails which is a setState event outside of function
const addUser = (
  creatorUserId,
  data,
  userImage,
  setNotification,
  axiosAfterEffect,
  setPasswordResponse,
  setLoadingActiveness
) => {
  const jsonData = {
    creatorUserId: creatorUserId,
    ...data,
    encodedProfileImage: userImage,
  };

  console.log(jsonData);

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/useraccountmanagement/adminControlledUserRegistration`,
      jsonData
    )
    .then((res) => {
      console.log("Response", res.data);

      if (
        res.data ===
        "Failed to create user account as provided email or username is currently in use"
      ) {
        setNotification("Add Unsuccessful", res.data, "error");
        setLoadingActiveness(false);
        return;
      }
      setNotification(
        "Add Successful",
        "User has been added successfully",
        "success"
      );
      setPasswordResponse(res.data);
      axiosAfterEffect();
    })
    .catch((error) => {
      // console.log(error);
      setNotification(
        "Add Unsuccessful",
        "An error occured when adding a user ",
        "error"
      );
      setLoadingActiveness(false);
    });
};

//Axios Call to make user admin
//Link (POST) --> useraccountmanagement/useraccountmanagement/companyUsersByAdmin
//Also requires setUserDetails which is a setState event outside of function
const makeAdmin = (adminId, userId, setNotification, axiosAfterEffect) => {
  const jsonData = {
    adminId: adminId,
    userId: userId,
  };

  console.log(jsonData);

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/useraccountmanagement/adminUpdateUserRoleToAdmin`,
      jsonData
    )
    .then((res) => {
      console.log("Response", res.data);

      setNotification(
        "Admin Change Successful",
        "User has been changed to admin",
        "success"
      );
      axiosAfterEffect();
    })
    .catch((error) => {
      console.log(error);
      setNotification(
        "Admin Change Unsuccessful",
        "An error occured during role conversion",
        "error"
      );
    });
};

export {
  newUserPassword,
  updatePassword,
  updateProfile,
  getUserDetails,
  postCrendentials,
  checkCredentials,
  getParty,
  setUserActiveness,
  addUser,
  makeAdmin,
};

//General Data APIs--------------------------------------------------------------------------------

//Axios Call for Chemical Names)
const getAllChemicalName = (setStateOptions, userId) => {
  const jsonData = {
    userId: userId,
  };
  axios
    .post(`${process.env.REACT_APP_BASE_URL}/encyclopedia/all/names`, jsonData)
    .then((res) => {
      setStateOptions(res.data);
    });
};

//Axios Call for Device Serials
const getAllDeviceSerial = (setStateOptions) => {
  axios.get(`${process.env.REACT_APP_BASE_URL}/device/all`).then((res) => {
    setStateOptions(res.data);
  });
};

export { getAllChemicalName, getAllDeviceSerial };

//Archive Information APIs-----------------------------------------------------------------

//Axios Call for Archive Retrieval
// States first
// Followed by pagination info
// setNotification
// User Id
const getAllDataArchiveEntries = (
  setStateOptions,
  setPageableResultsDTO,
  page,
  pageSize,
  sortField,
  direction,
  setNotification,
  userId
) => {
  const jsonData = {
    userId: userId,
    pageableDTO: {
      page: page,
      pageSize: pageSize,
      sortField: sortField,
      direction: direction,
    },
  };
  console.log(jsonData);
  axios
    .post(`${process.env.REACT_APP_BASE_URL}/dataarchive/getResult/`, jsonData)
    .then((res) => {
      setStateOptions(res.data.dataArchiveDetailsDTOS);
      setPageableResultsDTO(res.data.pageableResultsDTO);
    })
    .catch((error) => {
      console.log(error);
      setNotification(
        "Archive Resource Error",
        "Archive Resourses not loading, either contact us or wait for it to load",
        "error"
      );
    });
};

//Axios Call for Filtering Data Archive
//Link (POST) --> dataarchive/filterDataArchive`
//For Params check jsonData Object
const filterDataArchive = (
  setStateOptions,
  setPageableResultsDTO,
  page,
  size,
  sortField,
  direction,
  setNotification,
  userId,
  filters,
  showActive,
  setDataMetrics
) => {
  // console.log(filters)

  if (undefinedClassifier(filters)) {
    const jsonData = {
      userId: userId,
      userRole: sessionStorage.getItem("role").toUpperCase(),
      showInactive: showActive ? showActive : false,
      pageableDTO: {
        page: page,
        pageSize: size,
        sortField: sortField,
        direction: direction,
      },
    };

    console.log(jsonData);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/dataarchive/getResult/`,
        jsonData
      )
      .then((res) => {
        // console.log('normal retrieval')
        // console.log(res.data)
        setStateOptions(res.data.dataArchiveDetailsDTOS);
        setPageableResultsDTO(res.data.pageableResultsDTO);
        setDataMetrics(res.data.dataArchiveResultsMetricsDTO);
      })
      .catch((error) => {
        console.log(error);
        setNotification(
          "Archive Resource Error",
          "Archive Resourses not loading, either contact us or wait for it to load",
          "error"
        );
      });

    return;
  }

  const jsonData = {
    userId: userId,
    userRole: sessionStorage.getItem("role").toUpperCase(),
    showInactive: showActive ? showActive : false,
    ...filters,
    dataArchiveFilterSelectedOptionCollectionDateDTO: {
      startCollectionDate: null,
      endCollectionDate: null,
    },
    dataArchiveFilterSelectedOptionUploadedDateDTO: {
      startUploadedDate: null,
      endUploadedDate: null,
    },
    pageableDTO: {
      page: page,
      pageSize: size,
      sortField: sortField,
      direction: direction,
    },
  };

  // Fix this when wj includes search
  // console.log(removeAttribute(jsonData, 'fileName'))
  console.log("s", jsonData);

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/dataarchive/filterDataArchive`,
      jsonData
    )
    .then((res) => {
      console.log(res.data);
      // console.log('filtered')
      setStateOptions(res.data.dataArchiveDetailsDTOS);
      setPageableResultsDTO(res.data.pageableResultsDTO);
      setDataMetrics(res.data.dataArchiveResultsMetricsDTO);
    })
    .catch((error) => {
      console.log(error);
      setNotification(
        "Filter Resource Error",
        "Filtered Resourses not loading, either contact us or wait for it to load",
        "error"
      );
    });
};

//Axios Call for Filter Retrival and Mapping (Filters variable)
//Link (GET) --> /dataarchive/getAllFilter/userId
//Params --> UserId
//Also requires setStateOptions which is a setState event outside of function
const getArchiveFilters = (setFilterState, userId, setArchiveSorters) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/dataarchive/getAllFilter/${userId}`)
    .then((res) => {
      setArchiveSorters(res.data.sortableFields);
      setFilterState(removeAttribute(res.data, "sortableFields"));
    });
};

//Axios send the udeIndex(Id for the entry set and retrieve the UDE Information)
const getUniqueDataEntry = (setStateOptions, udeIndex) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/dataarchive/entrySet/${udeIndex}`)
    .then((res) => {
      setStateOptions(res.data);
    });
};

//Get Frequency For graph plotting
const getFrequency = (setFrequency, udeIndex) => {
  axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/dataarchive/entrysetfrequency/${udeIndex}`
    )
    .then((res) => {
      setFrequency(res.data);
    });
};

//Toggle Entry Set Activeness
const toggleEntrySetActiveness = (entrysetId, setNotification) => {
  const jsonData = {
    entrySetId: entrysetId,
  };
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/dataarchive/toggleEntrySetStatus`,
      jsonData
    )
    .then((res) => {
      setNotification(
        "Toggle Success",
        "Entry activeness has changed",
        "success"
      );
    })
    .catch((error) => {
      setNotification(
        "Toggle Unsuccessful",
        "There seems to be an issue toggling its activeness",
        "error"
      );
    });
};

//Reprocess Entry Set
const reprocess = (entrySetId, userId, setNotification, axiosAfterEffect) => {
  const jsonData = {
    entrySetId: entrySetId,
    userId: userId,
  };
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/dataupload/rerunProcessTimeDomainData`,
      jsonData
    )
    .then((res) => {
      if (res.data === "Successfully sent entry set for processing") {
        setNotification("Entry Reprocessing", res.data, "success");
      } else {
        setNotification("Entry Reprocessing Failed", res.data, "error");
      }
      axiosAfterEffect();
    })
    .catch((error) => {
      console.log(error);
      setNotification(
        "Entry Reprocessing Failed",
        "There seems to be an issue reprocessing the data",
        "error"
      );
    });
};

export {
  getAllDataArchiveEntries,
  getArchiveFilters,
  filterDataArchive,
  getUniqueDataEntry,
  getFrequency,
  toggleEntrySetActiveness,
  reprocess,
};

//Export Zips Information APIs------------------------------------------------------------
//Axios call to retrieve export sets
const getAllDataExportSets = (
  setStateOptions,
  setPageableResultsDTO,
  page,
  pageSize,
  sortField,
  direction,
  setNotification,
  userId
) => {
  const jsonData = {
    userId: userId,
    pageableDTO: {
      page: page,
      pageSize: pageSize,
      sortField: sortField,
      direction: direction,
    },
  };

  console.log(jsonData);
  axios
    .post(`${process.env.REACT_APP_BASE_URL}/dataexport/getExportSet`, jsonData)
    .then((res) => {
      setStateOptions(res.data.dataExportSetResultsDTOS);
      setPageableResultsDTO(res.data.pageableResultsDTO);
    });
};

//Axios call for retrieve Encyclopedia Filters
//Link (POST) --> dataarchive/addNewRefFile
//For Params check jsonData Object
//Also requires setStateOptions which is a setState event outside of function
const getExportFilters = (setExportFilters, setSortableFields, userId) => {
  axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/dataexport/getExportFilter/${userId}`
    )
    .then((res) => {
      console.log(res.data);
      setExportFilters({
        dataExportStatus: res.data.dataExportStatus,
        dataNames: res.data.dataNames,
        dataProviders: res.data.dataProviders,
      });
      setSortableFields(res.data.sortableFields);
      // console.log(res.data.sortableFields)
    })
    .catch((error) => {
      console.log(error);
    });
};

//Axios Call for Filtering Data Archive
//Link (POST) --> dataarchive/filterDataArchive`
//For Params check jsonData Object
const filterExportSets = (
  setStateOptions,
  setPageableResultsDTO,
  page,
  size,
  sortField,
  direction,
  setNotification,
  userId,
  filters
) => {
  console.log("f", filters);

  if (undefinedClassifier(filters)) {
    const jsonData = {
      userId: userId,
      pageableDTO: {
        page: page,
        pageSize: size,
        sortField: sortField,
        direction: direction,
      },
    };

    console.log(jsonData);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/dataexport/getExportSet`,
        jsonData
      )
      .then((res) => {
        // console.log('normal retrieval')
        setStateOptions(res.data.dataExportSetResultsDTOS);
        setPageableResultsDTO(res.data.pageableResultsDTO);
      })
      .catch((error) => {
        console.log(error);
        setNotification(
          "Export Resource Error",
          "Export Resourses not loading, either contact us or wait for it to load",
          "error"
        );
      });

    return;
  }

  const jsonData = {
    userId: userId,
    ...filters,
    pageableDTO: {
      page: page,
      pageSize: size,
      sortField: sortField,
      direction: direction,
    },
  };

  // Fix this when wj includes search
  console.log(jsonData);

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/dataexport/getFilteredExportSet`,
      jsonData
    )
    .then((res) => {
      setStateOptions(res.data.dataExportSetResultsDTOS);
      setPageableResultsDTO(res.data.pageableResultsDTO);
    })
    .catch((error) => {
      console.log(error);
      setNotification(
        "Filter Resource Error",
        "Filtered Resourses not loading, either contact us or wait for it to load",
        "error"
      );
    });
};

const createExportSet = (
  fileName,
  exportDataType,
  chemicalProportion,
  exportDataEntryIds,
  setNotification,
  closeSlide,
  setLoadingActiveness
) => {
  const jsonData = {
    fileName: fileName,
    bucketFilePath: "/testpath",
    exportBy: sessionStorage.getItem("userId"),
    exportDataType: exportDataType,
    chemicalProportion: chemicalProportion,
    exportDataEntryId: exportDataEntryIds,
  };

  console.log(jsonData);

  axios
    .post(`${process.env.REACT_APP_BASE_URL}/dataexport/newExportSet`, jsonData)
    .then((res) => {
      setNotification(
        "Creating Folder",
        "Folder is being created for download",
        "success"
      );
      closeSlide();
    })
    .catch((error) => {
      setNotification(
        "Creation",
        "File Creation Error either contact us or try again",
        "error"
      );
      setLoadingActiveness(false);
    });
};

const downloadExportSet = (exportId, exportName, setNotification) => {
  const axiosOptions = {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const jsonData = {
    exportId: exportId,
    exportName: exportName,
  };

  console.log(jsonData);

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/dataexport/downloadExportSet`,
      jsonData,
      axiosOptions
    )
    .then((res) => {
      console.log("Response", res.data);

      const blob = new Blob([res.data], {
        type: "application/octet-stream",
      });
      const filename = `${exportName}.zip`;
      saveAs(blob, filename);

      setNotification(
        "Fetching Folder",
        "Data is being Fetched for Downloading",
        "success"
      );
    })
    .catch((error) => {
      setNotification(
        "Fetch Error",
        "Resourses not loading, either contact us or wait for it to load",
        "error"
      );
    });
};

const deleteExportSet = (
  exportId,
  userId,
  setNotification,
  axiosAfterEffect,
  errorEffect
) => {
  const jsonData = {
    exportSetId: exportId,
    userId: userId,
  };

  console.log(jsonData);

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/dataexport/deleteExportSet`,
      jsonData
    )
    .then((res) => {
      if (res.data === "Deleted export set Successfully") {
        setNotification("Deletion Success", res.data, "success");
      } else {
        setNotification("Deletion Error", res.data, "error");
      }

      axiosAfterEffect();
    })
    .catch((error) => {
      setNotification(
        "Deletion Error",
        "An error has occured when deletinf your folder",
        "error"
      );
      errorEffect();
    });
};

export {
  getAllDataExportSets,
  filterExportSets,
  getExportFilters,
  downloadExportSet,
  createExportSet,
  deleteExportSet,
};

// Encyclopedia APIs

//Axios send the udeIndex(Id for the entry set and retrieve the UDE Information)
const getEncyclopediaDataEntry = (setStateOptions, eudeIndex) => {
  const jsonData = {
    userId: sessionStorage.getItem("userId"),
    drugId: eudeIndex,
  };
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/encyclopedia/drugDetails`,
      jsonData
    )
    .then((res) => {
      setStateOptions(res.data);
    });
};

// Axios call to retrieve all encylopedia details (Deprecated)
//Link (POST) --> encyclopedia/allDetails
//For Params check jsonData Object
//Also requires setStateOptions which is a setState event outside of function
//Axios Call for Archive Retrieval
// States first
// Followed ny pagination info
// setNotification
// User Id
// const getAllEncyclopediaDetails = (
//     setStateOptions,
//     setPageableResults,
//     page,
//     size,
//     sortField,
//     direction,
//     setNotification
// ) => {
//     const jsonData = {
//         page: page,
//         pageSize: size,
//         sortField: sortField,
//         direction: direction
//     }

//     console.log(jsonData)

//     axios.post(`${process.env.REACT_APP_BASE_URL}/encyclopedia/allDetails`, jsonData)
//     .then(res => {
//         setStateOptions(res.data.encyclopediaDetailsDTOs);
//         setPageableResults(res.data.pageableResultsDTO)
//         console.log(res.data)
//     }).catch((error) => {
//         console.log(error);
//         setNotification('Encyclopedia Resource Error', 'Encyclopedia Resourses not loading, either contact us or wait for it to load', 'error')

//     });
// };

//Axios call for retrieve Encyclopedia Filters
//Link (POST) --> dataarchive/addNewRefFile
//For Params check jsonData Object
//Also requires setStateOptions which is a setState event outside of function
const getEncyclopediaFilters = (setEncyclopediaFilters, setSortableFields) => {
  const jsonData = {
    userId: sessionStorage.getItem("userId"),
  };
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/encyclopedia/getEncyclopediaFilter`,
      jsonData
    )
    .then((res) => {
      // console.log(res.data)

      setEncyclopediaFilters({
        listOfCategoryDetails: res.data.listOfCategoryDetails,
        listOfAttributeDetails: res.data.listOfAttributeDetails,
        controlTypes: res.data.controlTypes,
      });
      setSortableFields(res.data.sortableFields);
      // console.log(res.data.sortableFields)
    })
    .catch((error) => {
      console.log(error);
    });
};

//Axios Call for filtering encyclopedia
//Link (POST) --> encyclopedia/filterEncyclopediaEntry
//For Params check jsonData Object
const filterEncyclopedia = (
  setStateOptions,
  setPageableResults,
  page,
  size,
  sortField,
  direction,
  setNotification,
  userId,
  filters,
  showActive,
  setDataMetrics
) => {
  if (undefinedClassifier(filters)) {
    const jsonData = {
      userId: userId,
      userRole: sessionStorage.getItem("role").toUpperCase(),
      showInactive: showActive ? showActive : false,
      pageableDTO: {
        page: page,
        pageSize: size,
        sortField: sortField,
        direction: direction,
      },
    };

    console.log(jsonData);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/encyclopedia/allDetails`,
        jsonData
      )
      .then((res) => {
        setStateOptions(res.data.encyclopediaDetailsDTOs);
        setPageableResults(res.data.pageableResultsDTO);
        setDataMetrics(res.data.encyclopediaMetricsDTO);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
        setNotification(
          "Encyclopedia Resource Error",
          "Encyclopedia Resourses not loading, either contact us or wait for it to load",
          "error"
        );
      });

    return;
  }

  const jsonData = {
    userId: userId,
    userRole: sessionStorage.getItem("role").toUpperCase(),
    showInactive: showActive ? showActive : false,
    ...filters,
    pageableDTO: {
      page: page,
      pageSize: size,
      sortField: sortField,
      direction: direction,
    },
  };

  // console.log(jsonData)

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/encyclopedia/filterEncyclopediaEntry`,
      jsonData
    )
    .then((res) => {
      setStateOptions(res.data.encyclopediaDetailsDTOs);
      setPageableResults(res.data.pageableResultsDTO);
      setDataMetrics(res.data.encyclopediaMetricsDTO);
    })
    .catch((error) => {
      setNotification(
        "Filter Resource Error",
        "Filtered Resourses not loading, either contact us or wait for it to load",
        "error"
      );
    });
};

//Axios Call for toggling activeness of chemical
//Link (POST) --> encyclopedia/updateEncyclopediaEntryStatus
//For Params check jsonData Object
const updateEncyclopediaActivation = (userId, chemicalId, setNotification) => {
  const jsonData = {
    userId: userId,
    drugId: chemicalId,
  };
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/encyclopedia/updateEncyclopediaEntryStatus`,
      jsonData
    )
    .then((res) => {
      console.log("Response", res.data);

      if (res.data.statusCode !== 200) {
        setNotification("Change Unsuccessful", res.data, "error");
        return;
      }

      setNotification(
        "Status Changed",
        "You have successfully updated the chemicals status",
        "success"
      );
    })
    .catch((error) => {
      console.log(error);
    });
};

//Axios Call for deleteing encyclopedia entry
//Link (POST) --> encyclopedia/deleteEncyclopediaEntry
//For Params check jsonData Object
const deleteEncyclopediaEntry = (
  userId,
  chemicalId,
  setNotification,
  navigateFunction
) => {
  const jsonData = {
    userId: userId,
    encyclopediaDetailsId: chemicalId,
  };
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/encyclopedia/deleteEncyclopediaEntry`,
      jsonData
    )
    .then((res) => {
      console.log("Response", res.data);

      if (
        res.data ===
        "Encyclopedia entry deletion unsuccessful: only encyclopedia owner are able to delete the entry"
      ) {
        setNotification("Delete Unsuccessful", res.data, "error");
        return;
      }

      setNotification(
        "Delete Successful",
        "You have successfully deleted a chemical entry",
        "success"
      );
      navigateFunction();
    })
    .catch((error) => {
      console.log(error);
    });
};

//Axios Call for creating encyclopedia entry
//Link (POST) --> encyclopedia/addEncyclopediaEntry
//For Params check jsonData Object
const createEncyclopediaEntry = (
  userId,
  drugName,
  categoryId,
  casNumber,
  controlType,
  molecularFormula,
  description,
  activationStatus,
  encyclopediaEntryAttributeIds,
  stackedChartImageEncodedFiles,
  drugMolecularStructureImageEncodedFile,
  drugImageEncodedFile,
  setNotification,
  synonyms,
  navigate
) => {
  const jsonData = {
    entryCreatedBy: userId,
    drugName: drugName,
    categoryId: categoryId,
    casNumber: casNumber,
    synonyms: synonyms,
    controlType: controlType,
    molecularFormula: molecularFormula,
    description: description,
    activationStatus: activationStatus,
    encyclopediaEntryAttributeIds: encyclopediaEntryAttributeIds,
    stackedChartImageEncodedFiles: stackedChartImageEncodedFiles,
    drugMolecularStructureImageEncodedFile:
      drugMolecularStructureImageEncodedFile,
    drugImageEncodedFile: drugImageEncodedFile,
  };

  console.log(jsonData);
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/encyclopedia/addEncyclopediaEntry`,
      jsonData
    )
    .then((res) => {
      console.log("Response", res.data);

      setNotification(
        "Encyclopedia Entry Created",
        `${drugName} has been created successfully, check it out in the encyclopedia`,
        "success"
      );
      navigate("/encyclopedia");
    })
    .catch((error) => {
      console.log(error);
    });
};

//Axios Call for updating encyclopedia entry
//Link (POST) --> encyclopedia/updateEncyclopediaEntry
//For Params check jsonData Object
const updateEncyclopediaEntry = (
  userId,
  drugId,
  drugName,
  categoryId,
  casNumber,
  controlType,
  molecularFormula,
  description,
  activationStatus,
  encyclopediaEntryAttributeIds,
  stackedChartImageEncodedFiles,
  drugMolecularStructureImageEncodedFile,
  drugImageEncodedFile,
  navigate,
  setNotification,
  synonyms
) => {
  const jsonData = {
    userId: userId,
    drugId: drugId,
    drugName: drugName,
    categoryId: categoryId,
    casNumber: casNumber,
    synonyms: synonyms,
    controlType: controlType,
    molecularFormula: molecularFormula,
    description: description,
    activationStatus: activationStatus,
    encyclopediaEntryAttributeIds: encyclopediaEntryAttributeIds,
    stackedChartImageEncodedFiles: stackedChartImageEncodedFiles,
    drugMolecularStructureImageEncodedFile:
      drugMolecularStructureImageEncodedFile,
    drugImageEncodedFile: drugImageEncodedFile,
  };

  console.log(jsonData);
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/encyclopedia/updateEncyclopediaEntry`,
      jsonData
    )
    .then((res) => {
      if (res.data.statusCode !== 200) {
        setNotification("Change Unsuccessful", res.data, "error");
        return;
      }
      setNotification(
        "Encyclopedia Entry Updated",
        `${drugName} has been updated successfully`,
        "success"
      );
      navigate("/encyclopedia");
    })
    .catch((error) => {
      console.log(error);
    });
};

export {
  // getAllEncyclopediaDetails,
  getEncyclopediaDataEntry,
  getEncyclopediaFilters,
  updateEncyclopediaActivation,
  deleteEncyclopediaEntry,
  filterEncyclopedia,
  updateEncyclopediaEntry,
  createEncyclopediaEntry,
};

//Model Test APIs

const getAllTests = (setStateOptions, userId) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/modelTesting/results/${userId}`)
    .then((res) => {
      setStateOptions(res.data);
    });
};

export { getAllTests };

// Upload APIs --------------------------------------------------------------------------
//Axios Call for retrieving filtered reference files based on a few some information
//Link (POST) --> dataarchive/referenceFileDetailsFilter
//For Params check jsonData Object
//Also requires setStateOptions which is a setState event outside of function
const getFilteredReferenceFiles = (
  setStateOptions,
  companyId,
  systemType = null,
  fileName = null,
  dateOfCollection = null,
  uploaderId = null
) => {
  const jsonData = {
    companyId: companyId,
    systemType: systemType,
    fileName: fileName,
    dateOfCollection: dateOfCollection,
    uploaderId: uploaderId,
  };
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/dataupload/referenceFileDetailsFilter`,
      jsonData
    )
    .then((res) => {
      setStateOptions(res.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

// Axios Retrive(Name RefData/ Remove import)
const getAllRefFile = (setStateOptions) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/dataupload/referenceFileDetails`)
    .then((res) => {
      setStateOptions(res.data);
    });
};

//Axios call for retrieving device by company and user id
//Link (POST) --> device/devicesBySystemType
//For Params check jsonData Object
//Also requires setStateOptions which is a setState event outside of function
const getDevicesBySystem = (setDeviceSerialOptions, companyId, systemId) => {
  const jsonData = {
    companyId: companyId,
    systemId: systemId,
  };

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/device/devicesBySystemType`,
      jsonData
    )
    .then((res) => {
      setDeviceSerialOptions(res.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

//Axios call for retrieveing users based on the company Id
//Link (POST) --> useraccountmanagement/companyUsers
//For Params check jsonData Object
//Also requires setStateOptions which is a setState event outside of function
const getFilteredUsersByCompany = (setStateOptions, companyId) => {
  const jsonData = {
    companyId: companyId,
  };
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/useraccountmanagement/companyUsers`,
      jsonData
    )
    .then((res) => {
      setStateOptions(res.data);
    })
    .catch((error) => {
      if (error != "TypeError: setStateOptions is not a function") {
        console.log(error);
      }
    });
};

//Axios call for retrieveing users based on the company Id
//Link (POST) --> dataarchive/processingSetBySystem
//For Params check jsonData
const getProcessingSetBySystemId = (setStateOptions, systemId, reset) => {
  const jsonData = {
    systemId: systemId,
  };
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/dataProcessingBuilder/processingSetBySystem`,
      jsonData
    )
    .then((res) => {
      setStateOptions(res.data);
    })
    .catch((error) => {
      if (error != "TypeError: setStateOptions is not a function") {
        console.log(error);
      }
    });
};

//Axios call for uploading new reference file
//Link (POST) --> dataarchive/addNewRefFile
//For Params check jsonData Object
//Also requires setStateOptions which is a setState event outside of function
const uploadNewReferenceFile = (
  refFileName,
  refFileCollectedDate,
  refFileUploadedBy,
  refFileDeviceSerial,
  refFileSystemId,
  refFileWindowMethod,
  refFileHumidity,
  refFileTemperature,
  refFileMask,
  refFilePackageType,
  refFile,
  refFileSize,
  companyName
) => {
  const jsonData = {
    refFileName: refFileName, //String
    refBucketFilePath: "", //String
    refFileCollectedDate: refFileCollectedDate, //Date
    refFileUploadedBy: refFileUploadedBy, //Integer
    refFileDeviceSerial: refFileDeviceSerial, //String
    refFileSystemId: refFileSystemId, //Integer
    refFileWindowMethod: refFileWindowMethod, //String
    refFileHumidity: refFileHumidity, //Float
    refFileTemperature: refFileTemperature, //Float
    refFileMask: refFileMask, //Integer
    refFilePackageType: refFilePackageType, //String
    refFile: refFile, //File
    refFileSize: refFileSize, //Float
    companyName: companyName, //String
  };
  console.log(jsonData);
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/dataupload/addNewRefFile`,
      jsonData
    )
    .then((res) => {
      console.log("Response", res.data);

      console.log(res);
    })
    .catch((error) => {
      if (error != "TypeError: setStateOptions is not a function") {
        console.log(error);
      }
    });
};

//Axios call for uploading data file submission
//Link (POST) --> dataarchive/addNewRefFile
//For Params check jsonData Object
//Also requires setStateOptions which is a setState event outside of function
const uploadSubmission = (dataFileDetails, others, axiosAfterEffect) => {
  const jsonData = {
    dataFileDetailsDTO: dataFileDetails,
    others: others,
    companyName: sessionStorage.getItem("companyName"),
  };

  console.log("Submitting Launched.....");
  console.log("json", jsonData);

  axios
    .post(`${process.env.REACT_APP_BASE_URL}/dataupload/addNewData`, jsonData)
    .then((res) => {
      console.log("Response", res.data);

      console.log(res);
      axiosAfterEffect();
    })
    .catch((error) => {
      console.log("My Error", error);
    });
};

export {
  getFilteredReferenceFiles,
  getAllRefFile,
  getDevicesBySystem,
  getFilteredUsersByCompany,
  uploadNewReferenceFile,
  getProcessingSetBySystemId,
  uploadSubmission,
};

//Deprecated/ Unused APIs-----------------------------------------------------

// axios call to retrieve data entries
const getAllDataSelectionEntries = (setResetItems, setList, userId) => {
  axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/dataarchive/selectionEntries/${userId}`
    )
    .then((res) => {
      setResetItems(res.data);
      const data = [
        {
          title:
            "Drag and drop files that you wish to examine your model with.",
          items: res.data,
        },
        {
          title: "Selected Files",
          items: [],
        },
      ];
      setList(data);
    });
};

//axios call to retrieve data archive entries
const getAllDeviceEntries = (setResetItems, setList, userId) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/device/devices/${userId}`)
    .then((res) => {
      setResetItems(res.data);
      const data = [
        {
          items: res.data,
        },
        {
          items: [],
        },
      ];
      setList(data);
    });
};

//Axios Get Project Details
const getAllProjects = (setStateOptions, userId) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/ModelProject/${userId}`)
    .then((res) => {
      setStateOptions(res.data);
    });
};

//Axios Call For Models
const getAllModels = (setStateOptions, userId) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/ModelBuilding/${userId}`)
    .then((res) => {
      setStateOptions(res.data);
    });
};

//POST Data ---------------------------------------------------------------------------------------------------------------

//Axios Call for Deprloyment
const deployDevice = (deviceSubmit) => {
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/deployment/modelDeployment`,
      deviceSubmit
    )
    .then((res) => {
      console.log(res.data);
    });
};

export {
  getAllDataSelectionEntries,
  getAllDeviceEntries,
  deployDevice,
  getAllProjects,
  getAllModels,
};
