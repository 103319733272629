//Library Imports
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import useStore from "../store";
import { shallow } from "zustand/shallow";


//Component Imports
import { getEncyclopediaDataEntry, updateEncyclopediaActivation, deleteEncyclopediaEntry } from '../AxiosList';
import { EncyclopediaEditor } from './EUniqueChemicalEntryEditor';
import { getImageDimensions } from '../GeneralFunctions';
import chemicalIcon from '../../Icons/EncyclopediaIcons/chemicalIcon.png'
import { SwitchButton } from '../SwitchButton';
import { CriticalConfirmation } from '../CriticalConfirmation';


//Image and SCSS imports 
import '../../SCSS_Files/EUniqueChemicalEntryStyle.scss';


//Image Previewer. Component used to preview image  (Not in Use)
const ImagePreviewer = (props) => {
    return (
        <div className="image-previewer-popup">
            <i className='bx bx-x' onClick={() => props.setImagePreviewerState(false)}></i>
            <div className="image-container">
                <div className="image">
                    <div className='label'>Old</div>

                    {props.oldURL && <img src={props.oldURL} alt="" />}
                    
                </div>
                <div className="image">
                    <div className='label'>New</div>

                    {props.newURL && <img src={props.newURL} alt="" />}
                    <div className="dimensions"></div>
                </div>
            </div>
        </div>
    )
}



//Main Unique Encyclopedia Entry Page
const EUniqueDrugEntry = () => {
    const [EUDEdata, setEUDEdata] = useState([]);

    // Admin Editing Page management
    const adminRole = sessionStorage.getItem('role') == 'admin'
    const [adminEditing, setAdminEditing] = useState(false)
    const [adminHotbar, setAdminHotbar] = useState(false)
    
    const [deleteBox, setDeleteBox] = useState(false)

    // Image Previewer state management
    // const [imagePreviewerState, setImagePreviewerState] = useState(false)
    const [oldURL, setOldURL] = useState('')
    const [newURL, setNewURL] = useState('')

    
    const navigate = useNavigate();

    const handleDragStart = (e) => e.preventDefault();

    const responsive = {
        0: { items: 1 }
    };

    //Access Store for notification
    const { setNotification, setLoadingActiveness } = useStore(
        (state) => ({
            setNotification: state.setNotification,
            setLoadingActiveness: state.setLoadingActiveness

        }),
        shallow
    );


    //Extract EUDE From session storage and ask for data from backend for that entry
    useEffect(() => {

        if (sessionStorage.getItem('userId') === null) {
            navigate('/');
        }

        setLoadingActiveness(true)

        getEncyclopediaDataEntry(setEUDEdata, sessionStorage.getItem("EUDEIndex"));
    }, []);

    
    useEffect(() => {
        setTimeout(() => {
            setLoadingActiveness(false)
        }, 1000)
        
    }, [EUDEdata])


    // Toggle Activeness of chemcial
    const changeActiveness = () => {
        // Integrate Status Update Function
        updateEncyclopediaActivation(sessionStorage.getItem('userId'), EUDEdata.drugId, setNotification)
    }

    //Stacked Image Allocation
    let stackedImages = {}
    if(EUDEdata.stackedChartImageFileName ) {
        const imageList = EUDEdata.stackedChartImageFileName.split(',');
        imageList.map((link)=> {
            const chemical = link.split('/').splice(1,1)
            stackedImages[String(link.split('/').splice(2,2)).replace(String(chemical)+'_', '').slice(0,-4) ]= process.env.REACT_APP_ENCYCLOPEDIA_BUCKET_URL + link
        })
    };
    

    return (
        <>
            <main className='main-content e-uniquedrugentry'>
                {
                    adminRole &&
                    <div className={adminHotbar?("removal-hotbar"):("removal-hotbar inactive")}>     
                    {!adminEditing && <div className="edit" onClick={() => setAdminEditing(true)}>Edit Chemical <i className='bx bx-cog'></i></div>} 
                    {adminEditing && <div className="edit" onClick={() => setAdminEditing(false)}>Return to viewer mode <i className='bx bx-mouse' ></i></div> }
                    <button id="delete" onClick={() => setDeleteBox(true)}>Delete</button>              
                    {
                        EUDEdata.activationStatus &&
                        <SwitchButton label='Active' defaultValue={EUDEdata.activationStatus} onChangeEvent={changeActiveness}/> 
                    }

                    <div className="hotbar-opener" onClick={() => setAdminHotbar(!adminHotbar)}>
                        <i className='bx bx-chevron-down'></i>
                    </div>

                    <div className={adminHotbar?("hotbar-label inactive"):("hotbar-label")}>Admin Tools</div>
                </div>
                }
                <div className={adminEditing?('e-uniquedrugentry-display inactive'):('e-uniquedrugentry-display')}> 
                    <div className='camel'>
                        <NavLink to='/encyclopedia'>Encyclopedia</NavLink>
                        <i className='bx bx-chevron-right'></i> {EUDEdata.drugName}
                    </div>

                    <div className="main-chemical-details-container">
                        <div className="chemical-header">
                            <div className='chemical-title'>{EUDEdata.drugName}</div>
                            <div className='chemical-subtitle'>CAS Number: {EUDEdata.casNumber}</div>
                        </div>
                        <div className="chemical-details">
                            <div className="section" id="section1">
                                <div className="title">Description</div>
                                <p>{EUDEdata.description}</p>
                                <div className="properties">
                                    <div className="title">Chemical Properties</div> 
                                    <ul>
                                          {EUDEdata.encyclopediaEntryPropertiesModel &&
                                            EUDEdata.encyclopediaEntryPropertiesModel.map((property, index) => {
                                                return(
                                                    <li key={index}>{property.encyclopediaEntryAttributeModel.encyclopediaEntryAttributeName}</li>
                                                )
                                            })
                                          }               
                                    </ul>
                                </div>
                                <div className="chemical-subcategory">Chemical Subcategory: <span>ILLICIT</span></div>
                                <div className="synonyms">
                                    <div className="title">Synonyms</div>
                                    <div className="synonym-container">
                                        {EUDEdata.synonyms &&
                                            EUDEdata.synonyms.split(',').map((synonym) => {
                                                return(
                                                    <div className="synonym">{synonym}</div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className="section" id="section2">
                                <img src={(EUDEdata.drugMolecularStructureImageFileName) ? (process.env.REACT_APP_ENCYCLOPEDIA_BUCKET_URL + EUDEdata.drugMolecularStructureImageFileName).replaceAll(' ', '+'):chemicalIcon} className='molecular-image'/>
                                <div className='captions'>Chemical Code: <span>{EUDEdata.molecularFormula}</span></div>
                            </div>
                        </div>

                        <div className="stacked-chart-gallery">
                            <div className="title">Stacked Analysis Charts</div>
                            <AliceCarousel 
                                mouseTracking  
                                items={
                                    
                                    Object.keys(stackedImages).map((stackedImageKey, index) =>{
                                        console.log(stackedImages[stackedImageKey].replaceAll(' ', '+'))
                                        return(
                                            <div className="slide" key={index}>
                                                {/* <div className="overlay"><i className='bx bx-zoom-in' ></i></div> */}
                                                <div className="image-title">{(stackedImageKey.charAt(0).toUpperCase() + stackedImageKey.slice(1)).replaceAll('_', ' ')}</div>
                                                <img  src={stackedImages[stackedImageKey].replaceAll(' ', '+')} onDragStart={handleDragStart} role="presentation" alt="Stacked Image"/>
                                            </div>
                                        )
                                    })
                                }  
                                responsive={responsive}  
                                dotsDisabled={true}
                            />
                        </div>
                        {console.log(EUDEdata)}
                    </div>
                </div>
                    

                
                {adminRole && 
                    EUDEdata.encyclopediaEntryPropertiesModel &&
                    Object.values(stackedImages) &&
                    <EncyclopediaEditor 
                        drugId={EUDEdata.drugId}
                        drugName={EUDEdata.drugName} 
                        casNumber={EUDEdata.casNumber}
                        description={EUDEdata.description}
                        chemicalCode={EUDEdata.molecularFormula}
                        controlType={EUDEdata.controlType}
                        moleculeImg={process.env.REACT_APP_ENCYCLOPEDIA_BUCKET_URL + EUDEdata.drugMolecularStructureImageFileName}
                        thumbnailImg={process.env.REACT_APP_ENCYCLOPEDIA_BUCKET_URL + EUDEdata.drugImageFilePath}
                        chemicalCategory={EUDEdata.materialCategory}
                        stackedImages = {stackedImages}
                        createdDate = {EUDEdata.entryCreatedDate}
                        updatedDate = {EUDEdata.entryUpdatedDate}
                        adminEditing = {adminEditing}
                        setAdminEditing={setAdminEditing}
                        // imagePreviewerState={imagePreviewerState}
                        // setImagePreviewerState={setImagePreviewerState}
                        setNewURL={setNewURL}
                        setOldURL={setOldURL}
                        synonyms={EUDEdata.synonyms}
                        setNotification={setNotification}
                        navigate={navigate}
                        navigateUrl = {'/encyclopedia'}
                        properties = {EUDEdata.encyclopediaEntryPropertiesModel}
                    />
                }   
            </main>
            <CriticalConfirmation 
                popupBox={deleteBox} 
                setPopupBox={setDeleteBox} 
                title={"Delete this entry?"}
                subtitle={
                    <>
                        <span>Just a heads up.</span> Deleting this entry will remove all information such as text based information, descriptions as well as images regarding this entry. 
                        <br /><br />
                        Once removed, the data can <span>no longer be recovered</span>, please consider carefully 
                        <br /><br />
                        If you're sure, please type <span>DELETE</span> in this box: 
                    </>
                }
                confirmButton={
                    'Delete'
                }
                confirmationValue= {
                    'DELETE'
                }
                confirmationFunction = {() => deleteEncyclopediaEntry(sessionStorage.getItem('userId'), EUDEdata.drugId, setNotification, ()=>navigate('/encyclopedia'))}
            />
            {/* {imagePreviewerState && <ImagePreviewer  setImagePreviewerState={setImagePreviewerState} oldURL={oldURL} newURL={newURL}/>} */}
        </>
    );
};



export default EUniqueDrugEntry;
export {CriticalConfirmation}
